import React, { useState, useEffect, useRef } from "react";
import styles from "./style.scss";
import { connect, useDispatch } from "react-redux";

import { loadResources } from "../../util";

import HeaderComponent from "../CommonComponents/HeaderComponent/HeaderComponent";
import FooterComponent from "../CommonComponents/FooterComponent/FooterComponent";
import OurgamesComponent from "./OurGamesComponent/OurGamesComponent";
import MainSliderComponent from "./MainSliderComponent/MainSliderComponent";
import StudioComponent from "./StudioComponent/StudioComponent";
import NewsComponent from "./NewsComponent/NewsComponent";
import FeaturesComponent from "./FeaturesComponent/FeaturesComponent";
import "fullpage.js/vendors/scrolloverflow.min";
import ReactFullpage from "@fullpage/react-fullpage";

import video1 from "../../video/video1.mp4";
import LoaderLogo from "../CommonComponents/LoaderLogoComponent";
import useData from "../../hooks/useData";
import videoPoster1 from './MainSliderComponent/video-posters/1.jpeg';
import videoPoster2 from './MainSliderComponent/video-posters/2.jpeg';
import videoPoster3 from './MainSliderComponent/video-posters/3.jpeg';
import videoPoster4 from './MainSliderComponent/video-posters/4.jpeg';
import videoPoster5 from './MainSliderComponent/video-posters/5.jpg';

const homePage = ({isMobile, loadedResources}) => {
  // const [scrollIndex, setScrollIndex] =useState(0)
  const [destinationIndex, setDestinationIndex] = useState(0);
  const [videoPaused, setVideoPaused] = useState(false);

  let fullpage;
  const slideDown = () => {
    fullpage.moveSectionDown();
  };

  const dispatch = useDispatch();
  useEffect(() => {
    loadResources([
      ["image", videoPoster1],
      ["image", videoPoster2],
      ["image", videoPoster3],
      ["image", videoPoster4],
      ["image", videoPoster5],
    ]).then(() => {
      dispatch({ type: "set-resources-loaded", payload: { loaded: true } });
    });
  }, []);



  const newsQuery = 'mainpage-news';
  const { data: newsPages, isLoading: newsIsLoading, isFalse: newsIsFalse } = useData(newsQuery, []);

  const fullPageRef = useRef(null);
  useEffect(() => {
      !isMobile && fullPageRef.current.fullpageApi.reBuild();
  }, [newsPages]);


  const pageContent = isMobile ? (
    <>
      <HeaderComponent />
      <MainSliderComponent slideDown={slideDown} />
      <OurgamesComponent />
      <FeaturesComponent />
      <StudioComponent />
      {newsPages && <NewsComponent newsPages={newsPages}/>}
      <FooterComponent />
    </>
  ) : (
    <div className="page__container">
      <div className="section">
        <HeaderComponent destinationIndex={destinationIndex} />
      </div>
      <ReactFullpage
        ref={fullPageRef}
        scrollOverflow={true}
        licenseKey={"YOUR_KEY_HERE"}
        scrollingSpeed={600} /* Options here */
        onLeave={({ index }, destination) => {
          // setScrollIndex(index+1);
          setDestinationIndex(destination.index);
          setVideoPaused(destination.index > 0);
        }}
        render={({ state, fullpageApi }) => {
          fullpage = fullpageApi;
          return (
            <ReactFullpage.Wrapper>
              <div className="section">
                <MainSliderComponent
                  slideDown={slideDown}
                  videoPaused={videoPaused}
                />
              </div>
              <div className="section fp-auto-height">
                <div className="page__section">
                  <OurgamesComponent />
                </div>
                <div className="page__section">
                  <FeaturesComponent />
                </div>
                <div className="page__section">
                  <StudioComponent />
                </div>
                <div className="page__section">
                  {newsPages && <NewsComponent newsPages={newsPages}/>}
                </div>
                <FooterComponent />
              </div>
            </ReactFullpage.Wrapper>
          );
        }}
      />
    </div>
  );

  return (
    <div className={"page"}>
      <LoaderLogo isLoaded={loadedResources.loaded} />
      {pageContent}
    </div>
  );
};

const mapStateToProps = (state) => ({
  language: state.language,
  isMobile: state.isMobile,
  loadedResources: state.loadedResources,
});

export default connect(mapStateToProps)(homePage);
