import React from 'react'
import "./style.scss"
import {connect} from "react-redux"

function ArrowDown(props){

        const  {arrowHandler} = props;

        return(
            <div onClick={arrowHandler} className="arrow-down">
                <div className="arrow-down__part">
                    <svg xmlns="http://www.w3.org/2000/svg"viewBox="0 0 14 8">
                        <g fillRule="evenodd">
                            <g>
                                <g>
                                    <path d="M10.61 3.01l-5.334-5.6c-.52-.547-1.364-.547-1.885 0-.521.547-.521 1.433 0 1.98L7.78 4l-4.39 4.61c-.521.547-.521 1.433 0 1.98.26.273.601.41.942.41.341 0 .683-.137.943-.41l5.333-5.6c.521-.547.521-1.433 0-1.98" transform="translate(-47 -648) translate(47 648) rotate(90 7 4)"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>

                <div className="arrow-down__part">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 8">
                        <g fillRule="evenodd">
                            <g>
                                <g>
                                    <path d="M10.61 3.01l-5.334-5.6c-.52-.547-1.364-.547-1.885 0-.521.547-.521 1.433 0 1.98L7.78 4l-4.39 4.61c-.521.547-.521 1.433 0 1.98.26.273.601.41.942.41.341 0 .683-.137.943-.41l5.333-5.6c.521-.547.521-1.433 0-1.98" transform="translate(-47 -648) translate(47 648) rotate(90 7 4)"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>

                <div className="arrow-down__part">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 8">
                        <g fillRule="evenodd">
                            <g>
                                <g>
                                    <path d="M10.61 3.01l-5.334-5.6c-.52-.547-1.364-.547-1.885 0-.521.547-.521 1.433 0 1.98L7.78 4l-4.39 4.61c-.521.547-.521 1.433 0 1.98.26.273.601.41.942.41.341 0 .683-.137.943-.41l5.333-5.6c.521-.547.521-1.433 0-1.98" transform="translate(-47 -648) translate(47 648) rotate(90 7 4)"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>

            </div>
        )
}

const mapStateToProps = state =>({
    language: state.language
})

export default connect(mapStateToProps)(ArrowDown)