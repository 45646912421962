import React, { useState, useRef } from 'react'
import { connect } from 'react-redux';

import useOutsideAlerter from '../../../hooks/useOutsideAlerter'
import { ReactComponent as SkypeIcon} from '../../../img/social_links/skype.svg';
import { ReactComponent as TelegramIcon} from '../../../img/social_links/telegram.svg';
import { ReactComponent as WhatsappIcon} from '../../../img/social_links/whatsapp.svg';
import style from "./style.scss"

function dropDown(props){
    const {selectedLink} = props;
    // const [selectedLink, setLink] = useState(props.selectedLink);
    const wrapperRef = useRef(null);
    
    const [isOpen, setIsOpen] = useState(false);
    useOutsideAlerter(wrapperRef, () => setIsOpen(false));

    const onSelectHandler = (selectedItem) => {
        props.changeHandler(selectedItem);
        setIsOpen(false);
    }


    let icon;
    switch (selectedLink) {
        case 'whatsapp': 
            icon = <WhatsappIcon />;
            break;
        case 'telegram':
            icon = <TelegramIcon />;
            break;  
        case 'skype':
            icon = <SkypeIcon />;
            break;  
        default:
            icon = <WhatsappIcon />;
    }

    return(

        <div ref={wrapperRef} className="dropdown">
            <div onClick={() => setIsOpen(!isOpen)} className="dropdown__btn">
                <div className={"dropdown__icon"}>
                    {icon}
                </div>
                <div className={"dropdown__arrow" + (isOpen ? " is__open" : "")}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                        <g fillRule="evenodd">
                            <g  fillRule="nonzero">
                                <g>
                                    <path d="M5.793 4.793c.36-.36.928-.388 1.32-.083l.094.083 3 3c.36.36.388.928.083 1.32l-.083.094-3 3c-.39.39-1.024.39-1.414 0-.36-.36-.388-.928-.083-1.32l.083-.094L8.085 8.5 5.793 6.207c-.36-.36-.388-.928-.083-1.32l.083-.094z" transform="translate(-2373 -2669) translate(2373 2669) rotate(90 8 8.5)"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
                
            </div>
            <div className={"dropdown__select" + (isOpen ? " is__open" : "")}>
                <div onClick={() => onSelectHandler('skype')} className={"dropdown__select__item"  + (selectedLink === 'skype' ? " is__selected" : "")}>
                    <div className="dropdown__icon">
                        <SkypeIcon />
                    </div>
                </div>
                <div onClick={() => onSelectHandler('whatsapp')} className={"dropdown__select__item"  + (selectedLink === 'whatsapp' ? " is__selected" : "")}>
                    <div className="dropdown__icon">
                        <WhatsappIcon />
                    </div>
                </div>
                <div onClick={() => onSelectHandler('telegram')} className={"dropdown__select__item"  + (selectedLink === 'telegram' ? "  is__selected" : "")}>
                    <div className="dropdown__icon">
                        <TelegramIcon />
                    </div>
                </div>

            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isMobile: state.isMobile
  });
  
  export default connect(mapStateToProps)(dropDown);