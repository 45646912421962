import {  SwiperSlide } from 'swiper/react';
import Swiper from 'react-id-swiper';
import React, { useCallback, useContext, useEffect, useRef, useState} from 'react'
import {connect, useStore} from "react-redux"

import ArrowDown from '../../CommonComponents/ArrowDownComponent'
import Arrow from '../../CommonComponents/ArrowComponent'

import "./style.scss"

import emotion from '../../../img/emotion.jpg';
import video1 from '../../../video/video1.mp4';
import video2 from '../../../video/video2.mp4';

import video3 from '../../../video/video3.mp4';
import video4 from '../../../video/video4.mp4';

import video5 from '../../../video/video5.mp4';

import videoPoster1 from '../MainSliderComponent/video-posters/1.jpeg';
import videoPoster2 from '../MainSliderComponent/video-posters/2.jpeg';
import videoPoster3 from '../MainSliderComponent/video-posters/3.jpeg';
import videoPoster4 from '../MainSliderComponent/video-posters/4.jpeg';
import videoPoster5 from '../MainSliderComponent/video-posters/5.jpg';

import 'swiper/swiper.scss';



function MainSliderComponent(props){

        const {slideDown} = props;

        const refSlogan = useRef(null);
        const refFull = useRef(null);

        const scrollNext = () =>{
            refSlogan.current.swiper.slideNext();
            refFull.current.swiper.slideNext();
        };

        const [isTouchedSlider, setTouchedSlider] = useState(false);

        useEffect(() => {
            const activeIndex = refFull.current.swiper.activeIndex;
            if (props.videoPaused) {
                refFull.current.swiper.slides[activeIndex]
                    .querySelector('video').pause();
            } else {
                refFull.current.swiper.slides[activeIndex]
                    .querySelector('video').play();
            }
        }, [props.videoPaused]);

        return(
            <div className={"main-slider"}>
                <div className="main-slider__arrow-container">
                    <div className="main-slider__arrow-down">
                        <ArrowDown
                            arrowHandler={slideDown}
                        />
                    </div>
                </div>

                <div className="main-slider__slogan-container">
                    <div className="main-slider__slogan-wrapper">
                        <div className="main-slider__slogan">
                            <div className="main-slider__slogan-main"> ATMOSFERA IS&nbsp;</div>
                            <div className="main-slider__slogan-slider">
                                <div className="slogan-slider">

                                    <Swiper
                                        ref={refSlogan}
                                        loop={true}
                                        slidesPerView={'2'}
                                        loopedSlides={4}
                                    >
                                        <SwiperSlide>
                                            <div className={"slogan-slider__slide"}>
                                                live games
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className={"slogan-slider__slide"}>
                                                emotion
                                            </div>
                                        </SwiperSlide>


                                        <SwiperSlide>
                                            <div className={"slogan-slider__slide"}>
                                                A GOOD TEAM
                                            </div>
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <div className={"slogan-slider__slide"}>
                                                A TECHNOLOGY
                                            </div>
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <div className={"slogan-slider__slide"}>
                                                YOUR PARTNER
                                            </div>
                                        </SwiperSlide>


                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="main-slider__full">
                    <div className="full-slider">
                        <Swiper
                            ref={refFull}
                            loop={true}
                            slidesPerView={'auto'}
                            loopedSlides={4}
                            allowSlidePrev={false}
                            allowTouchMove={props.isMobile ? true : false}
                            on={{
                                slideChange: state => {
                                    if (state.activeIndex === 4) {
                                        state.allowSlidePrev = false;
                                    } else {
                                        state.allowSlidePrev = true;
                                    }
                                    // refsVideo.current.forEach((elem, index) => {
                                    //     index !== state.activeIndex ? refsVideo.current[index].pause() : refsVideo.current[index].play();
                                    // })     

                                    state.slides[state.previousIndex].querySelector('video').pause();    
                                    state.slides[state.activeIndex].querySelector('video').play();

                                    refSlogan.current.swiper.slideTo(state.activeIndex);
                                }
                            }}
                            
                        >
                            <SwiperSlide>
                                <video muted="muted" loop="loop" playsInline="playsinline" className="full-slider__img" src={video5} poster={videoPoster5} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <video muted="muted" loop="loop" playsInline="playsinline" className="full-slider__img" src={video1} poster={videoPoster1} />
                            </SwiperSlide>

                            <SwiperSlide>
                                <video muted="muted" loop="loop" playsInline="playsinline" className="full-slider__img" src={video2} poster={videoPoster2} />
                            </SwiperSlide>

                            <SwiperSlide>
                                <video muted="muted" loop="loop" playsInline="playsinline" className="full-slider__img" src={video3} poster={videoPoster3} />
                            </SwiperSlide>

                            <SwiperSlide>
                                <video muted="muted" loop="loop" playsInline="playsinline" className="full-slider__img " src={video4} poster={videoPoster4} />
                                <div className="full-slider__img__gradient"></div>
                            </SwiperSlide>


                        </Swiper>
                        <div className="full-slider__separator-contain">
                            <div className="full-slider__separator"></div>
                        </div>
                        <div className="full-slider__arrow-contain">
                            <div className="full-slider__arrow-decor">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 214">
                                    <g fill="none" fillRule="evenodd">
                                        <g fill="#04053C">
                                            <path d="M1042 202c0-11.433-5.331-21.617-13.643-28.21-5.796-4.599-9.617-11.232-10.825-18.531C1012.684 125.953 1014 95 1014 95h-16s1.316 30.953-3.532 60.259c-1.208 7.299-5.029 13.932-10.825 18.531C975.331 180.383 970 190.567 970 202s5.331 21.617 13.643 28.21c5.796 4.599 9.617 11.232 10.825 18.531C999.316 278.047 998 309 998 309h16s-1.316-30.953 3.532-60.259c1.208-7.299 5.029-13.932 10.825-18.531 8.312-6.593 13.643-16.777 13.643-28.21" transform="translate(-970 -95)"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div className="full-slider__arrow">
                                <Arrow sliderFunction={scrollNext} direction="next" size={props.isMobile && 'small'}/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
}

const mapStateToProps = state =>({
    language: state.language,
    isMobile: state.isMobile
})

export default connect(mapStateToProps)(MainSliderComponent);