import React, { useEffect, useState, useRef } from 'react'
import {connect} from "react-redux"
import 'fullpage.js/vendors/scrolloverflow.min'

import HeaderComponent from "../CommonComponents/HeaderComponent/HeaderComponent"
import FooterComponent from "../CommonComponents/FooterComponent/FooterComponent"
import NewsTop from "./NewsTopComponent/NewsTopComponent"
import NewsArticle from "./NewsArticleComponent/NewsArticleComponent"

const envURL = process.env.NODE_ENV === 'development' ?  process.env.React_App_API_DOMAIN : process.env.API_DOMAIN;

const newsPage = ({isMobile, newsData }) => {

    const { title, type, date, url, id} = newsData;
    const [mobileScrollStart, mobTopScrollSelector] = useState(0);
    const [articleData, articleDataSet] = useState({});
    const scrollRef = useRef(null);

    let touchMoveHandler;
    let touchEndHandler;

    useEffect(() =>{
        (async () => {
            articleDataSet(await fetch(`${url}/${id}`).then(response => response.json()) || {});
            window.scrollTo(0, 0)
        })()
    },[]);

    useEffect(()=>{
        const animateArray = ['.news-top__img-wrapper', '.news-top__container', '.news-article'];
        const animatedPage = document.querySelector('.animated_page');
        animatedPage && animatedPage.addEventListener("touchmove", touchMoveHandler = event => {

            if(document.querySelector('.news-top').getBoundingClientRect().y === 0 && !mobileScrollStart){
                animatedPage && animatedPage.removeEventListener("touchmove", touchMoveHandler);
                mobTopScrollSelector(event.changedTouches[0].clientY)
            }
            else if(document.querySelector('.news-top').getBoundingClientRect().y !== 0 && mobileScrollStart){
                mobTopScrollSelector(0);
            }
            
            if(mobileScrollStart > 0 && document.querySelector('.news-top').getBoundingClientRect().y === 0){
                const scaleCoeff = Math.max(0,  ((event.changedTouches[0].clientY - mobileScrollStart)/ document.documentElement.clientHeight ));
                animateArray.map(elm =>{
                    document.querySelector(elm).style.transition = '';
                    document.querySelector(elm).style.marginTop = (scaleCoeff * 150) + 'px'
                })
            }
            else {
                animatedPage && animatedPage.querySelector('.page').style.setProperty('--marginTop', 0);
                animateArray.map(elm =>{
                    document.querySelector(elm).style.transition = '0.25s margin-top ease-in-out';
                    document.querySelector(elm).style.marginTop = 0
                })
            }
        })
    },[mobileScrollStart]);

    useEffect(()=>{
        const animateArray = ['.news-top__img-wrapper', '.news-top__container', '.news-article'];
        const animatedPage = document.querySelector('.animated_page');
        animatedPage && animatedPage.addEventListener("touchend", touchEndHandler = event => {
            animatedPage && animatedPage.removeEventListener("touchmove", touchMoveHandler);
            mobTopScrollSelector(0);
            animateArray.map(elm =>{
                document.querySelector(elm).style.transition = '0.25s margin-top ease-in-out';
                document.querySelector(elm).style.marginTop = 0
            })
        });
        return function(){
            animatedPage && animatedPage.removeEventListener("touchend", touchEndHandler)
        }
    },[]);

    // on mobile news page is animated like popup and need to hide background components
    // set dependence on isMobile cause default it is false
    // useEffect(() => {
    //     if (isMobile) document.body.style.overflow = 'hidden';
    //     return (()=> {
    //         document.body.style.overflow = 'unset';
    //     })
    //  }, [isMobile]);


    const mobileBanner =  articleData && articleData['banner_mob'] && articleData['banner_mob'] && articleData['banner_mob'].url;
    const desktopBanner =  articleData && articleData['banner'] && articleData['banner'] && articleData['banner'].url;

    const pageContent = isMobile ? (
        <div>
            <NewsTop
                title = {title}
                banner = {mobileBanner}
                author = {articleData.author}
                type={type}
                date={date}
            />
            <NewsArticle article = {articleData.article}/>
        </div>
    ):(
        <div ref={scrollRef} className={"page__container"}>
            <div className="section">
                <HeaderComponent/>
            </div>
            <div className="section fp-auto-height">
                <NewsTop
                    title = {title}
                    banner = {desktopBanner}
                    author = {articleData.author}
                    type={type}
                    date={date}
                />
                <div className="page__section">
                    <NewsArticle
                        article = {articleData.article}
                    />
                </div>

                <FooterComponent/>
            </div>
        </div>
    )
    return(
        <div className={"page news-page"}>
            {pageContent}
        </div>
    )

}

const mapStateToProps = state =>({
    language: state.language,
    isMobile: state.isMobile
})

export default connect(mapStateToProps)(newsPage)
