import React, { useRef, useState, useEffect } from 'react';
import styles from './style.scss';
import { connect, useDispatch } from 'react-redux';
import { Formik, Form} from 'formik';
import * as Yup from 'yup';

import arrayEquals from '../../../functions/arrayEquals'

import Button from '../../CommonComponents/ButtonComponent/index';
import CheckBox from '../../CommonComponents/CheckBoxComponent/index';
import InputField from '../../CommonComponents/InputFieldComponent/index';
import iconMail from '../../../img/input-mail.svg';
import iconCompany from '../../../img/input-company.svg';
import iconMessage from '../../../img/input-message.svg';
import iconName from '../../../img/input-user.svg';
import DropDown from '../../CommonComponents/DropDownComponent';
import ErrorMessage from '../../CommonComponents/ErrorMessage/index';
import PopupModalComponent from '../../CommonComponents/PopupModalComponent';
import ProgressButton from '../../CommonComponents/ProgressButton';
import {atLeastOneNonSpaceChar} from '../../../util';

const partnerSchema = Yup.object().shape({
  name: Yup.string().test(
    'at-least-one-non-space-char',
    'Please introduce yourself',
    atLeastOneNonSpaceChar,
  ),
  company: Yup.string().test(
    'at-least-one-non-space-char',
    'Enter your company name',
    atLeastOneNonSpaceChar,
  ),
  email: Yup.string()
    .email('Invalid email address')
    .required('This field is required'),
  message: Yup.string().max(250, 'maximum 250 characters'),
  privacy: Yup.boolean().oneOf(
    [true],
    'You must accept the terms and conditions'
  ),
  skype: Yup.string(),
  telegram: Yup.string(),
  whatsapp: Yup.string(),
});



// class PartnershipFormComponent extends Component {
const partnershipFormComponent= ({submitForm}) => {
  const nameRef = useRef(null);
  const companyRef = useRef(null);
  const emailRef = useRef(null);
  const messangerAccRef = useRef(null);
  const messageRef = useRef(null);

  const [selectedSocialLink, setSelectedSocialLink] = useState('skype');

  const links = {
    whatsapp: 'WhatsApp',
    skype: 'Skype',
    telegram: 'Telegram'
  }

  const dispatch = useDispatch();

  const selectHandler = (value) => {
    setSelectedSocialLink(value);
  }

  // const [showPopup, setShowPopup] = useState(false);
  
  const onSubmitHandler = (values, resetFormCallback) =>  {
    dispatch({ type: 'set-partnership-submit-sending', payload: true })

    fetch('/api/feedback', {
      method: 'POST',
      body: JSON.stringify({...values}),
      headers: {
        'Content-Type': 'application/json'
      },
    }).then((response) => {
      if(response.status!==200)
       {
          throw new Error(response.status)
       }

      dispatch({ type: 'set-partnership-submit-result', payload: {status: true} })
      // waiting for animation
      setTimeout(()=> {
        resetFormCallback();
      }, 2000)
      
    }).catch((error) => {
      console.error('Error:', error);
      dispatch({ type: 'set-partnership-submit-result', payload: {status: false, error }})
    })
    .finally(()=> {
      dispatch({ type: 'set-partnership-submit-sending', payload: false });
      setTimeout(()=> {
        dispatch({ type: 'set-partnership-submit-result', payload: null })
      }, 3000)
    });


    // setShowPopup(true);
  }

  return (
    <div className="partnership-form" >
      <div className="partnership-form__container">
        <Formik
          initialValues={{
            name: '',
            company: '',
            email: '',
            messanger: '',
            whatsapp: '',
            skype: '',
            telegram: '',
            message: '',
            privacy: false,
          }}
          validationSchema={partnerSchema}
          onSubmit={(values, { resetForm, setSubmitting }) => {
            setSubmitting(false);
            // need to reset form only after success, now i put callback inside, but maybe use then if i catch error
            onSubmitHandler(values, resetForm);            
          }}
        >
          {({ isSubmitting, isValid, dirty, values, errors, touched, ...props }) => {
            const [formErrors, setFormErrors] = useState([]);
            useEffect(() => {
              let updatedErrors = [];
              for (const key in errors  ) {
                if (touched[key] === true) {
                  updatedErrors = [...updatedErrors, key]
                }
              }
              if (!arrayEquals(updatedErrors, formErrors)) {
                setFormErrors(updatedErrors);
              }
            }, [errors, touched]);

            let ErrorMessages = (submitForm.submitResult && !submitForm.submitResult.status) ? [(<ErrorMessage key={'submitError'}
            errorIndex={1}
            msg={'Problem has occurred (please check your internet connection)'}
          />)] :
            formErrors.map(
              (name, index) => 
                (<ErrorMessage
                  key={name}
                  errorIndex={index+1}
                  msg={errors[name]}
                />)
            )

            return (
              <Form className="partnership-form__form">
                <div className="partnership-form__title">
                  Let's talk business
                </div>
                <div className="partnership-form__fields">
                  <div className="partnership-form__field">
                    <InputField
                      inputRef={nameRef}
                      placeholder="Your name"
                      page="partnership"
                      icon={iconName}
                      type="text"
                      name="name"
                      errorIndex={formErrors.indexOf('name') + 1}
                    />
                  </div>
                  <div className="partnership-form__field">
                    <InputField
                      inputRef={companyRef}
                      placeholder="Company name"
                      page="partnership"
                      icon={iconCompany}
                      type="text"
                      name="company"
                      errorIndex={formErrors.indexOf('company') + 1}
                    />
                  </div>
                  <div className="partnership-form__field">
                    <InputField
                      inputRef={emailRef}
                      placeholder="Your email"
                      page="partnership"
                      icon={iconMail}
                      type="email"
                      name="email"
                      errorIndex={formErrors.indexOf('email') + 1}
                    />
                  </div>

                  <div className="partnership-form__field partnership-form__field__combo">
                    <DropDown selectedLink={selectedSocialLink} changeHandler={selectHandler}/>
                    <InputField
                        inputRef={messangerAccRef}
                        page="partnership"
                        name={selectedSocialLink}
                        id="messanger"
                        placeholder={links[selectedSocialLink]}
                    />
                  </div>
                  <div className="partnership-form__field is__textarea">
                    <InputField
                      inputRef={messageRef}
                      placeholder="Message or Question"
                      page="partnership"
                      type="textarea"
                      name="message"
                      icon={iconMessage}
                      // errorIndex={formErrors.indexOf('message') + 1}
                    />
                  </div>
                </div>

                <div className="partnership-form__privacy">
                  <CheckBox text="Privacy Policy" name="privacy" checked={values.privacy} />
                </div>
                <div className="partnership-form__btn">

                  <ProgressButton 
                    isDisabled={!(isValid && dirty)}
                    // clickFunction={()=> console.log(isValid, dirty)}
                    type="submit"/>
                  
                </div>
                <div className="partnership-form_errors">
                  {ErrorMessages}
                </div>
              </Form>
            )}}
        </Formik>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  language: state.language,
  submitForm: state.submitForm.partnershipForm
});

export default connect(mapStateToProps)(partnershipFormComponent);
