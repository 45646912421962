import React from 'react'
import "./style.scss"
import {connect} from "react-redux"
import { ReactComponent as ArrowSVG} from '../../../img/arrow.svg';
import { ReactComponent as ArrowSmallSVG} from '../../../img/arrow_small.svg';
function Arrow(props){
        const {sliderFunction, direction, size, isDisabled, theme} = props;

        const classes = "arrow" + 
            (direction === 'prev' ? ' arrow_prev' : ' arrow_next') + 
            (size === 'small' ? ' arrow__small' : '') + 
            (isDisabled ? ' arrow_disable' : '') +
            (theme ? ' arrow_' + theme  : '');

        return(
            <div onClick={sliderFunction} 
                className={classes}>
                <div className={"arrow__icon" +  (theme ? ' arrow__icon_' + theme  : '')}>
                    {size === 'small' ? <ArrowSmallSVG/> : <ArrowSVG/>}
                </div>
            </div>
        )
}

const mapStateToProps = state =>({
    language: state.language
})

export default connect(mapStateToProps)(Arrow)