import React from 'react';

import{ ReactComponent as CloseSVG}from '../../../img/close.svg'
import style from "./style.scss"

const closeButton = (props) => {

    const {actionFunction, theme} = props;

    return (
        <div className={"close-button" + (theme? " close-button_" + theme: "")} onClick={actionFunction}>
            <CloseSVG />
        </div>
    );
};

export default closeButton;