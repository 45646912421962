import React, { useState, useEffect } from 'react';
import { useField } from 'formik';
import InputStatus from '../InputStatus'
import style from './style.scss';

const inputField = ({
  placeholder,
  icon,
  errorIndex,
  inputRef,
  page,
  className,
  ...props
}) => {
  const [isValid, setValid] = useState(false);
  const [focused, setFocused] = useState(false);

  const [field, meta] = useField(props);
  
  useEffect(()=>{
    if (!meta.touched) setValid(false);
  }, [meta.touched])

  const focusHandler = () => {
    setFocused(true);
    setValid(false);
  };

  const [textLength, setTextLength] = useState(0)
  const onTextAreaChange = (e) => {
    setTextLength(e.target.value.length)
    field.onChange(e);
  }

  const blurHandler = (e) => {
    setFocused(false);
    field.onBlur(e);
    if (!meta.error && e.target.value) {
      setValid(true);
    } else {
      setValid(false)
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        blurHandler(event);
        inputRef.current.blur();
      }
    }

    if (page === "partnership" && focused) {
      document.addEventListener('click', handleClickOutside)
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }
    

  }, [focused]);

  const iconNode = icon ? (
    <div
      className={
        'input-field__icon' + (focused || field.value  ? ' input-field__icon__focused' : '')
      }
    >
      <img src={icon} />
    </div>
  ) : null;

  let wrapperClasses =
    (props.type === 'textarea' ? 'input-field input-field_textarea' : 'input-field') 
    + (props.id === 'messanger' ?  ' input-field input-field_alt' : '')
    + (focused ? ' input-field__focused' : '')
    + (field.value ? ' input-field__filled' : '');
  
  let input = props.type === 'textarea' ? (
    <>
      <textarea
        ref={inputRef && inputRef}
        name={props.name}
        className={'input-field__input'}
        onFocus={focusHandler}
        {...field} {...props}
        onBlur={blurHandler}
        onChange={onTextAreaChange}
      />
      <span className='input-field_textarea_counter'>{textLength}/250</span>
    </>
  ) : (
    <>
      <input
        ref={inputRef && inputRef}
        name={props.name}
        className={'input-field__input'}
        onFocus={focusHandler}
        {...field} {...props}
        onBlur={blurHandler}
      />
    </>
  )

  let validStatus;
  if (errorIndex) {
    validStatus = 'error';
  } else if (isValid) {
    validStatus = 'valid';
  }

  return (
    <label className={wrapperClasses}>
      {input}
      <div className={'input-field__status ' + className}>
        <InputStatus type={validStatus} value={errorIndex ? errorIndex : null}/>
      </div>
      {iconNode}
      <div className={'input-field__label'}>
        <span className="input-field__label-text">{placeholder}</span>
      </div>
    </label>
  );
};

export default inputField;
