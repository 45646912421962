import React from 'react'
import style from "./style.scss"
import {connect} from "react-redux"
import Arrow from "../ArrowComponent"

function NavButton(props){
    const {buttonText, actionFunction, textmod} = props;
        return(
            <div className="nav__button" onClick={actionFunction}>
                <div className={`nav__button-text ${textmod ? `"nav__button-text_"+ textmod`: ""}`}>{buttonText}</div>
                <div className="nav__button-arrow">
                    <Arrow
                        direction="next"
                        size="small"
                    />
                </div>
            </div>
        )
}

const mapStateToProps = state =>({
    language: state.language
})

export default connect(mapStateToProps)(NavButton)