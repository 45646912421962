import React from 'react';
import style from './style.scss';
import { connect } from 'react-redux';

function Button(props) {
  const { title, language, clickFunction, mod, isDisabled, type, icon, className } = props;

  return (
    <button
      className={className !== undefined ? 'btn btn_' + mod + ' ' + className: 'btn btn_' + mod}
      onClick={clickFunction}
      disabled={isDisabled}
      type={type === 'submit' ? 'submit' : 'button'}
    >
      {icon ? icon : null}
      <span className="btn__text">{title}</span>
    </button>
  );
}

const mapStateToProps = (state) => ({
  language: state.language,
});

export default connect(mapStateToProps)(Button);
