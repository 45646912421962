const initialState = {
    contactsForm: {
        submitResult: null,
        isSendingForm: false
    },
    partnershipForm: {
        submitResult: null,
        isSendingForm: false
    }
}


export default (state = initialState, action) =>{
    switch (action.type) {
        case 'set-partnership-submit-sending':
            return {
                ...state,
                partnershipForm: {
                    ...state.partnershipForm,
                    isSendingForm: action.payload
                }
                
            }
        case 'set-partnership-submit-result':
            return {
                ...state,
                partnershipForm: {
                    ...state.partnershipForm,
                    submitResult: action.payload
                }
                
            }
        
        default:
            return state
    }
}