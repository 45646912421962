import React, { Component, useState, useEffect, useRef, useCallback } from 'react'
import styles from "./style.scss"
import { connect } from "react-redux"
import HeaderComponent from "../CommonComponents/HeaderComponent/HeaderComponent"
import FooterComponent from "../CommonComponents/FooterComponent/FooterComponent"
import GameTop from "./GameTopComponent/GameTopComponent"
import GamePartners from "./GamePartnersComponent/GamePartnersComponent"
import GameDevicesComponent from "./GameDevicesComponent/GameDevicesComponent"
import GameBottomComponent from "./GameBottomComponent/GameBottomComponent"


// function useHookWithRefCallback() {
//     const ref = useRef(null)
//
//     const setRef = useCallback(node => {
//
//         const VS = new VanillaSwipe({
//             delta: 5,
//             directionDelta: 0,
//             preventDefaultTouchmoveEvent: false,
//             preventTrackingOnMouseleave: true,
//             mouseTrackingEnabled: true,
//         });
//
//       if (ref.current) {
//         // Make sure to cleanup any events/references added to the last instance
//         VS.destroy();
//       }
//
//       if (node) {
//         // Check if a node is actually passed. Otherwise node would be null.
//         // You can now do what you need to, addEventListeners, measure, etc.
//         let initialHeight = null;
//
//         // let image = node.querySelector('.game-top');
//         // const maxHeight = Math.max(
//         //     window.innerHeight, document.documentElement.clientHeight) * 0.2 + 500;
//         // function swipingStart(event, {directionY, deltaY}) {
//         //     if (directionY === "BOTTOM") {
//         //         let heightNumber = initialHeight + deltaY * (-2);
//         //         let height = heightNumber + 'px';
//         //
//         //         if (heightNumber >= initialHeight && heightNumber >= image.offsetHeight && heightNumber <= maxHeight) {
//         //             image.style.height = height;
//         //         }
//         //     }
//         //     if (directionY === "TOP") {
//         //         let heightNumber = initialHeight + deltaY * (-2);
//         //         let height = heightNumber + 'px';
//         //         if (heightNumber >= initialHeight && heightNumber <= image.offsetHeight && heightNumber <= maxHeight) {
//         //             node.style.height = height;
//         //         }
//         //     }
//         // }
//
//         // function swipingEnd() {
//         //     image.style.height = '';
//         // }
//         //
//         // function swipeStart() {
//         //     initialHeight = image.offsetHeight;
//         // }
//
//         VS.update({
//             element:node,
//             // onSwipeStart: swipeBottom,
//             // onSwiping: swipingStart,
//             // onSwiped: swipingEnd
//         }
//         );
//
//         VS.init();
//       }
//
//       // Save a reference to the node
//       ref.current = node;
//     }, [])
//
//     return setRef
//   }

const gamePage = (props) => {
    const container = useRef(null)

    function scrollWatcher(tabs) {
        tabs && container.current && scroller(tabs.getBoundingClientRect().y < 90 && container.current.style.getPropertyValue('--selectedTab') === 'rules');
        const bottom = document.querySelector('.game-bottom__content');
        const coordY = tabs.getBoundingClientRect().y;
        tabs && bottom && container.current && bottom.style.setProperty('--scrollIndex', (coordY < 0 && container.current.style.getPropertyValue('--selectedTab') === 'rules' ? ((coordY * -1) + 'px') : false))
    }

    const [selectedTab, tabToggler] = useState('features')
    const [isScrolledToTabs, scroller] = useState('false')
    const [mobileScrollStart, mobTopScrollSelector] = useState(0)

    // let tabs;
    // useEffect(()=>{
    //     scroller(false);
    //     let observer = new MutationObserver(() =>{
    //         const tabsElement = document.querySelector('.game-bottom__tabs');
    //         if(tabsElement && !tabs){
    //             tabs = tabsElement;
    //             observer.disconnect()
    //         }
    //     });
    //     observer.observe(document, {
    //         childList: true,
    //         subtree: true,
    //         characterDataOldValue: true
    //     })
    //     document.addEventListener('wheel', event =>{
    //         scrollWatcher(tabs);
    //     })
    //     window.addEventListener(`resize`, event => {
    //         scrollWatcher(tabs)
    //     }, false);
    //
    // }, [])


    // useEffect(()=>{
    //     tabs = document.querySelector('.game-bottom__tabs');
    // },[props.page])

    let touchMoveHandler
    let touchEndHandler

    useEffect(() => {
        const gameArray = ['.game-top__img', '.game-top__container'];
        const gamePage = document.querySelector('.animated_page');
        gamePage && gamePage.addEventListener("touchmove", touchMoveHandler = event => {

            if (document.querySelector('.game-top').getBoundingClientRect().y === 0 && !mobileScrollStart) {
                gamePage && gamePage.removeEventListener("touchmove", touchMoveHandler)
                mobTopScrollSelector(event.changedTouches[0].clientY)
            }
            else if (document.querySelector('.game-top').getBoundingClientRect().y !== 0 && mobileScrollStart) {
                mobTopScrollSelector(0);
            }

            if (mobileScrollStart > 0 && document.querySelector('.game-top').getBoundingClientRect().y === 0) {
                const scaleCoeff = Math.max(0, ((event.changedTouches[0].clientY - mobileScrollStart) / document.documentElement.clientHeight));
                gameArray.map(elm => {
                    document.querySelector(elm).style.transition = '';
                    document.querySelector(elm).style.marginTop = (scaleCoeff * 150) + 'px'
                })
            }
            else {
                gamePage && gamePage.querySelector('.page').style.setProperty('--marginTop', 0)
                gameArray.map(elm => {
                    document.querySelector(elm).style.transition = '0.25s margin-top ease-in-out';
                    document.querySelector(elm).style.marginTop = 0
                })
            }
        })
    }, [mobileScrollStart])

    useEffect(() => {
        const animateArray = ['.game-top__img', '.game-partners', '.game-top__container'];
        const animatedPage = document.querySelector('.animated_page');
        animatedPage && animatedPage.addEventListener("touchend", touchEndHandler = event => {
            animatedPage && animatedPage.removeEventListener("touchmove", touchMoveHandler)
            mobTopScrollSelector(0);
            animateArray.map(elm => {
                document.querySelector(elm).style.transition = '0.25s margin-top ease-in-out';
                document.querySelector(elm).style.marginTop = 0
            })
        })
        return function () {
            animatedPage && animatedPage.removeEventListener("touchend", touchEndHandler)
        }
    }, [])

    // useEffect(()=>{
    //     const gamePage = document.querySelector('.game-page');
    //     gamePage && gamePage.addEventListener("touchend", touchEndHandler = event => {
    //         document.querySelector('.game-page').removeEventListener("touchmove", touchMoveHandler)
    //         mobTopScrollSelector(0);
    //         document.querySelector('.game-top__img').style.transition = '0.25s height ease-in-out'
    //         document.querySelector('.game-top__text').style.transition = '0.25s margin-bottom ease-in-out'
    //         document.querySelector('.game-top__img').style.height = '45.2rem';
    //         document.querySelector('.game-top__text').style.marginBottom = 0;
    //     })
    //     return function(){
    //         gamePage && gamePage.removeEventListener("touchend", touchEndHandler)
    //     }
    // },[])

    useEffect(() => {
        container.current && container.current.style.setProperty('--selectedTab', selectedTab)
        if (selectedTab === 'features') {
            scroller(false);
            const scrollValue = document.querySelector('.game-bottom__content').style.getPropertyValue('--scrollIndex');
            if (scrollValue !== "false" && scrollValue !== "") {
                const contentHeight = [...document.querySelector('.fp-scroller').children].reduce((prev, next, index) => {
                    return prev + (index < 2 ? next.clientHeight : 0)
                }, 0) + 230
                const iscrollInstance =
                    document.querySelector('.fp-scrollable').fp_iscrollInstance;
                iscrollInstance.scrollTo(0, -1 * contentHeight, 1000);
            }
            document.querySelector('.game-bottom__content').style.setProperty('--scrollIndex', false);
        }
    }, [selectedTab])

    // banner - картинка в баннере,
    // name - название игры
    // text - текст в баннере
    // theme - тема оформления
    // desktopIcon - картинка для иконки десктопа в табах
    // mobileIcon - картинка для иконки мобильного в табах
    // tabletIcon - картинка для иконки планшета в табах
    // demo - ссылка на демо
    const { banner, bannerMobile ,name, text, theme, desktopIcon, mobileIcon, tabletIcon, decor, deviceDesktop, deviceTablet, deviceMobile, slidesDesktop, slidesTablet, slidesMobile, features, rules, partners, isMobile, page, demo } = props;

    // on mobile news page is animated like popup and need to hide background components
    // set dependence on isMobile cause default it is false
    // useEffect(() => {
    //     if (isMobile) document.body.style.overflow = 'hidden';
    //     return (() => {
    //         document.body.style.overflow = 'unset';
    //     })
    // }, [isMobile]);


    const pageContent = isMobile ? (
        <div>
            <GameTop
                banner={bannerMobile ? bannerMobile : banner}
                name={name}
                theme={theme}
                text={text}
                demo={demo}
            />
            <GamePartners
                theme={theme}
                partners={partners}
            />

            <GameDevicesComponent
                theme={theme}
                desktopIcon={desktopIcon}
                mobileIcon={mobileIcon}
                tabletIcon={tabletIcon}
                deviceDesktop={deviceDesktop}
                deviceTablet={deviceTablet}
                deviceMobile={deviceMobile}
                slidesDesktop={slidesDesktop}
                slidesTablet={slidesTablet}
                slidesMobile={slidesMobile}
            />

            <GameBottomComponent
                theme={theme}
                features={features}
                rules={rules}
                selectedTab={selectedTab}
                tabToggler={tabToggler}
                isScrolledToTabs={isScrolledToTabs}
            />
        </div>
    ) : (
        <div ref={container} className={"page__container"}>

            <div className="section">
                <HeaderComponent
                    theme={theme}
                />
            </div>
            <div className="full-container">
                <div className={"game-page__tabs-container game-page__tabs-container_" + theme + (isScrolledToTabs ? ' tabs_scrolled' : '')}>
                    <div className={"game-page__tabs game-page__tabs_" + theme + (isScrolledToTabs ? ' tabs_scrolled' : '')}>
                        <div onClick={() => tabToggler('features')} className={'game-page__tab' + (selectedTab === 'features' ? ' game-page__tab_active' : '')}>
                            FEATURES
                        </div>
                        <div onClick={() => tabToggler('rules')} className={'game-page__tab' + (selectedTab === 'rules' ? ' game-page__tab_active' : '')}>
                            RULES
                        </div>
                        <div
                            className={
                                'game-page__tag ' +
                                (selectedTab === 'features'
                                    ? 'game-page__tag_pos_1'
                                    : '') +
                                (selectedTab === 'rules'
                                    ? 'game-page__tag_pos_2'
                                    : '') +
                                (theme ? ' game-page__tag_' + theme : '')
                            }
                        />
                    </div>
                </div>
                <div className={"section game-page__top  game-page__top_" + theme}>
                    <GameTop
                        banner={banner}
                        name={name}
                        theme={theme}
                        text={text}
                        demo={demo}
                    />
                </div>

                <div className="section fp-auto-height">
                    <div className="page__section">
                        <GamePartners
                            theme={theme}
                            partners={partners}
                        />
                    </div>
                    <div className="page__section">
                        <GameDevicesComponent
                            theme={theme}
                            desktopIcon={desktopIcon}
                            mobileIcon={mobileIcon}
                            tabletIcon={tabletIcon}
                            deviceDesktop={deviceDesktop}
                            deviceTablet={deviceTablet}
                            deviceMobile={deviceMobile}
                            slidesDesktop={slidesDesktop}
                            slidesTablet={slidesTablet}
                            slidesMobile={slidesMobile}
                        />
                    </div>
                    <div className="page__section">
                        <GameBottomComponent
                            theme={theme}
                            features={features}
                            rules={rules}
                            selectedTab={selectedTab}
                            tabToggler={tabToggler}
                            isScrolledToTabs={isScrolledToTabs}
                        />
                    </div>
                    <FooterComponent
                        theme={theme}
                    />
                </div>
            </div>

        </div>
    );


    return (
        <div className={"page game-page game-page_" + theme}>
            {pageContent}
        </div>
    )
}


const mapStateToProps = state => ({
    language: state.language,
    isMobile: state.isMobile,
    page: state.page
})

export default connect(mapStateToProps)(gamePage)
