import React, {Component} from 'react'
import styles from "./style.scss"
import {connect} from "react-redux"




class PartnershipTopComponent extends Component{

    render(){

        return(
            <div className="partnership-top">
                <div className="company-top__container">
                    <div className="partnership-top__title">work with atmosfera</div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state =>({
    language: state.language
})

export default connect(mapStateToProps)(PartnershipTopComponent)