import React, {useEffect, useRef} from 'react'
import {
    Route,
    Switch,
    withRouter,
    useHistory,
    Redirect,
} from "react-router-dom"
import HomePage from "../HomePage/HomePage"
import GamesPage from "../GamesPage/GamesPage"
import NewsSectionPage from "../NewsSectionPage/NewsSectionPage"
import NewsPage from '../NewsPage/NewsPage'
import ContactsPage from "../ContactsPage/ContactsPage"
import GamePageKeno from "../GamePageKeno/GamePageKeno"
import GamePageLer from "../GamePageLer/GamePageLer"
import GamepageAr from '../GamePageAr/GamepageAr'
import PartnershipPage from "../PartnershipPage/PartnershipPage"
import Privacy from "../PrivacyComponent/PrivacyComponent"
import {connect} from "react-redux"
import {changePage, setIsMobile} from "../../ac"
import CompanyPage from '../../components/CompanyPage/CompanyPage'



import useMedia from '../../hooks/useMedia'
import { TransitionGroup } from 'react-transition-group'
import ScrollToTop from '../CommonComponents/ScrollToTopComponent/ScrollToTopComponent'
import PageNotFound from '../PageNotFound/PageNotFound'
import HelmetComponent from '../CommonComponents/HelmetComponent';

import TransitionWrapper from '../CommonComponents/TransitionWrapper/TransitionWrapper'

import useData from '../../hooks/useData'
import GamePage from '../GamePage/GamePage'
import GamePageMusicWheel from '../GamePageMusicWheel/GamePageMusicWheel'
import SurveysPage from '../SurveysPage/SurveysPage'
import GamePageBJ from '../GamePageBJ/GamePageBJ';
import GamePageCR from '../GamePageCR/GamePageCR';

const mainPage = (props) =>  {


    const history = useHistory();

    const {page, location:{pathname}} = props;


    useEffect(()=> {
        const pathname = history.location.pathname.replace("/", "");
        if (page !== pathname) {
            props.dispatch(changePage(pathname));
        }
    }, [history.location.pathname])

    useEffect(()=> {
        const pathname = history.location.pathname.replace("/", "");
        if (page !== null && page !== pathname ) {
            history.push(`/${page}`)
        }
    }, [page])

    const isMobile = useMedia(
        // Media queries
        ['(max-width: 480px), (max-width: 812px) and (max-height: 520px) and (orientation: landscape)'],
        // result values (relates to above media queries by array index)
        [true],
        // Default value
        false
      );

    useEffect(()=> {
        props.dispatch(setIsMobile(isMobile));
    }, [isMobile])

    const observer = () => {
        const height = Math.max(
            window.innerHeight, document.documentElement.clientHeight
        );
        const vh = height/100 + 'px';
        document.body.style.setProperty('--vh', vh);
    };
    observer();
    window.addEventListener(`resize`, event => {
        observer();
    }, false);
    window.addEventListener(`resize`, event => {
        observer();
    }, false);

    window.addEventListener('load', function() {
        observer();
    });


    const newsPageRef = useRef(null);
    const gamesPageRef = useRef(null);

    const newsQuery = 'news-posts?_sort=publish:DESC';
    const { data: newsPages, isLoading: newsIsLoading, isFalse: newsIsFalse } = useData(newsQuery, []);
    let mainNewsPageId, stockNewsPages;
    if (newsPages) {
        mainNewsPageId = newsPages && newsPages.some(news => news.is_main_news)
            ? newsPages.find(news => news.is_main_news).id
            : newsPages[0].id;
        stockNewsPages = newsPages && newsPages.filter(news => news.id !== mainNewsPageId);
    }

    const newsRouters = isMobile ? (
        <Route history={history} path='/news'>
        <ScrollToTop />
            { (!newsIsLoading && !newsIsFalse) ?
            <TransitionGroup>
                <Route history={history} path='/news' key='/news'>
                    {/* Here must be scolltotop when it returns not from /news/news-* page */}
                    <NewsSectionPage ref={newsPageRef} newsPages={stockNewsPages} mainNewsPageId={ mainNewsPageId }/>
                </Route>
                {newsPages.map(newsData => {
                    return (
                        <Route  history={history} path={newsData.link} exact key={newsData.id}>
                            {({ match }) => (
                                <TransitionWrapper parentRef={newsPageRef} match={match}>
                                    <NewsPage newsData={newsData} />
                                </TransitionWrapper>
                            )}
                        </Route>
                    )
                })}
            </TransitionGroup>
            :
             <Route history={history} path='/news' key='/news'>
                {/* Here must be scolltotop when it returns not from /news/news-* page */}
                <NewsSectionPage ref={newsPageRef} newsPages={stockNewsPages} mainNewsPageId={mainNewsPageId}/>
            </Route>
            }
        </Route>
    ) : (
        <Route history={history} path='/news'>
        <ScrollToTop />
            <HelmetComponent title='Our News' description='Stay tuned with ATMOSFERA!' />
            <Route history={history} path='/news' exact>
                <NewsSectionPage newsPages={stockNewsPages} mainNewsPageId={mainNewsPageId}/>
            </Route>
            {newsPages &&  newsPages.map(newsData => {
                return (
                    <Route  history={history} path={newsData.link} exact key={newsData.link}>
                        <NewsPage newsData={newsData} />
                    </Route>
                )
                })}
        </Route>
    );

    const gamesRouters = isMobile ? (
        <Route history={history} path='/games'>
        <ScrollToTop />

            <HelmetComponent title='Our Live Games' description='Live Roulette. Bet on Poker. American and European Bingo Roulettes. Keno. Battleship. MusicWheel and HiLo are coming soon.' />
            <TransitionGroup>
                <Route history={history} path='/games' key='/games'>
                    <GamesPage ref={gamesPageRef}/>
                </Route>
                <Route  history={history} path='/games/game-keno' exact key='/games/game-keno'>
                    {({ match }) => (
                        <TransitionWrapper  parentRef={gamesPageRef} match={match}>
                            <GamePageKeno/>
                        </TransitionWrapper>
                    )}
                </Route>
                <Route  history={history} path='/games/game-ar' exact key='/games/game-ar'>
                    {({ match }) => (
                        <TransitionWrapper  parentRef={gamesPageRef} match={match}>
                            <GamepageAr/>
                        </TransitionWrapper>
                    )}
                </Route>
                <Route  history={history} path='/games/game-cr' exact key='/games/game-cr'>
                    {({ match }) => (
                        <TransitionWrapper  parentRef={gamesPageRef} match={match}>
                            <GamePageCR/>
                        </TransitionWrapper>
                    )}
                </Route>
                <Route  history={history} path='/games/game-ler' exact key='/games/game-ler'>
                    {({ match }) => (
                        <TransitionWrapper  parentRef={gamesPageRef} match={match}>
                            <GamePageLer/>
                        </TransitionWrapper>
                    )}
                </Route>
                <Route  history={history} path='/games/blackjack' exact key='/games/blackjack'>
                    {({ match }) => (
                        <TransitionWrapper  parentRef={gamesPageRef} match={match}>
                            <GamePageBJ/>
                        </TransitionWrapper>
                    )}
                </Route>
                <Route  history={history} path='/games/game-music-wheel' exact key='/games/game-music-wheel'>
                    {({ match }) => (
                        <TransitionWrapper  parentRef={gamesPageRef} match={match}>
                            <GamePageMusicWheel/>
                        </TransitionWrapper>
                    )}
                </Route>
            </TransitionGroup>
        </Route>
    ) : (
        <Route history={history} path='/games'>
        <ScrollToTop />
            <HelmetComponent title='Our Live Games' description='Live Roulette. Bet on Poker. American and European Bingo Roulettes. Keno. Battleship. MusicWheel and HiLo are coming soon.' />
            <Route history={history} path='/games' exact>
                <GamesPage ref={gamesPageRef}/>
            </Route>
            <Route  history={history} path='/games/game-keno' exact key='/games/game-keno'>
                <GamePageKeno/>
            </Route>
            <Route  history={history} path='/games/game-ar' exact key='/games/game-ar'>
                <GamepageAr/>
            </Route>
            <Route  history={history} path='/games/game-cr' exact key='/games/game-cr'>
                <GamePageCR/>
            </Route>
            <Route  history={history} path='/games/game-ler' exact key='/games/game-ler'>
                <GamePageLer/>
            </Route>
            <Route history={history} path='/games/blackjack' exact key='/games/blackjack'>
                <GamePageBJ />
            </Route>
            <Route history={history} path='/games/game-music-wheel' exact key='/games/game-music-wheel'>
                <GamePageMusicWheel />
            </Route>
        </Route>
    );

  return (
    <Switch>
      <Route history={history} exact path="/">
        <HelmetComponent title='ATMOSFERA' description='Enjoy a variety of games by ATMOSFERA Live Casino provider. Live Roulette. Music Wheel. Auto Roulette. Keno are waiting for you.' />
        <ScrollToTop />
        <HomePage />
      </Route>
      {newsRouters}
      {gamesRouters}
      <Route history={history} path="/contacts">
        <HelmetComponent title='Contacts' description='Drop a line, let’s work together.' />
        <ScrollToTop />
        <ContactsPage />
      </Route>
      <Route history={history} path='/company'>
        <HelmetComponent title='Company' description='About company'/>
        <ScrollToTop />
        <CompanyPage />
      </Route>
      <Route history={history} path="/partnership">
        <HelmetComponent title='Partnership' description='Enjoy being our partner. Working with us is easy and fast.' />
        <ScrollToTop />
        <PartnershipPage />
      </Route>
      <Route history={history} path="/privacy-policy">
        <ScrollToTop />
        <Privacy />
      </Route>
      <Route history={history} path="/surveys">
          <SurveysPage />
      </Route>
      <Route path="/404" component={PageNotFound} />
      <Redirect to="/404" />
    </Switch>
  );
  //   }
};

const mapStateToProps = (state) => ({
  language: state.language,
  page: state.page,
  isMobile: state.isMobile,
  loadedResources: state.loadedResources,
});

export default withRouter(connect(mapStateToProps)(mainPage));
