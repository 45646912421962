import React from 'react'
import styles from "./style.scss"
import {connect} from "react-redux"
import Button from "../../../CommonComponents/ButtonComponent/index"
import {changePage} from "../../../../ac"
import { Link, useRouteMatch } from 'react-router-dom'


const itemNewsListComponent = (props) => {

    const {title, text, date, img, type, link} = props;

    let { url } = useRouteMatch();

    return(
        <Link to={`${link}`} key={link} className="item-news-list">
            <div className="item-news-list__top">
                <img className="item-news-list__img" src={img}/>
                <div className="item-news-list__type">{type}</div>
            </div>

            <div className="item-news-list__content">
                <div className="item-news-list__date">{date}</div>
                <div className="item-news-list__title">{title}</div>
                <div className="item-news-list__text">{text}</div>
            </div>
        </Link>
    )

}

const mapStateToProps = state =>({
    language: state.language
})

export default connect(mapStateToProps)(itemNewsListComponent)