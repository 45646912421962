import React, {Component} from 'react'
import styles from "./style.scss"
import {connect} from "react-redux"


function CompanyMissionComponent() {
    return (
        <div className="company-mission">
            <div className="company-mission__title">Our company</div>
            <div className="company-mission__text">
                <p className="company-mission__text_item">
                ATMOSFERA is an international gaming software developer that produces LIVE games 24/7 live streaming. In 2020 the company entered the international market and in a short period of time we concluded more than 25 successful and mutually beneficial partnerships.
                Our portfolio already consists of 7 Live products including such popular games as Live Roulette, Blackjack, Music Wheel, Auto Roulette, Keno.
                </p>
                <p className="company-mission__text_item">
                ATMOSFERA aims to produce classic gaming products by sustaining an innovative, continual development to ensure the highest entertainment value and genuine player loyalty.
                </p>

                <p className="company-mission__text_item">
                ATMOSFERA’s professional team with over 9 years of experience in addition to classic broadcasting over the Internet has developed a unique solution for broadcasting via satellite inside the studio.
                </p>
                <p className="company-mission__text_item">
                    The R&D team developed Lotto Machines that are capable 24/7 and can guarantee a constant drawing process. With the help of the own Academy for training professional Game Presenters (TV hosts) the company is keeping the high standard of the working process in the studio, and the professional team of analysts, designers, and developers creates new game mechanics, relevant and innovative and detail - thoughtful user interfaces, high-quality visual effects, that can change the usual perception in the area of gambling.
                </p>
            </div>
        </div>
    )
  } 
  
  const mapStateToProps = state =>({
    language: state.language
  })
  
  export default connect(mapStateToProps)(CompanyMissionComponent)
