import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { CSSTransition } from "react-transition-group";

import "./style.scss";

import img0 from "../../../img/loader_animation/Atm_SiteLoader_300_00000.png";
import img1 from "../../../img/loader_animation/Atm_SiteLoader_300_00001.png";
import img2 from "../../../img/loader_animation/Atm_SiteLoader_300_00002.png";
import img3 from "../../../img/loader_animation/Atm_SiteLoader_300_00003.png";
import img4 from "../../../img/loader_animation/Atm_SiteLoader_300_00004.png";
import img5 from "../../../img/loader_animation/Atm_SiteLoader_300_00005.png";
import img6 from "../../../img/loader_animation/Atm_SiteLoader_300_00006.png";
import img7 from "../../../img/loader_animation/Atm_SiteLoader_300_00007.png";
import img8 from "../../../img/loader_animation/Atm_SiteLoader_300_00008.png";
import img9 from "../../../img/loader_animation/Atm_SiteLoader_300_00009.png";
import img10 from "../../../img/loader_animation/Atm_SiteLoader_300_00010.png";
import img11 from "../../../img/loader_animation/Atm_SiteLoader_300_00011.png";
import img12 from "../../../img/loader_animation/Atm_SiteLoader_300_00012.png";
import img13 from "../../../img/loader_animation/Atm_SiteLoader_300_00013.png";
import img14 from "../../../img/loader_animation/Atm_SiteLoader_300_00014.png";
import img15 from "../../../img/loader_animation/Atm_SiteLoader_300_00015.png";
import img16 from "../../../img/loader_animation/Atm_SiteLoader_300_00016.png";
import img17 from "../../../img/loader_animation/Atm_SiteLoader_300_00017.png";
import img18 from "../../../img/loader_animation/Atm_SiteLoader_300_00018.png";
import img19 from "../../../img/loader_animation/Atm_SiteLoader_300_00019.png";
import img20 from "../../../img/loader_animation/Atm_SiteLoader_300_00020.png";
import img21 from "../../../img/loader_animation/Atm_SiteLoader_300_00021.png";
import img22 from "../../../img/loader_animation/Atm_SiteLoader_300_00022.png";
import img23 from "../../../img/loader_animation/Atm_SiteLoader_300_00023.png";
import img24 from "../../../img/loader_animation/Atm_SiteLoader_300_00024.png";
import img25 from "../../../img/loader_animation/Atm_SiteLoader_300_00025.png";
import img26 from "../../../img/loader_animation/Atm_SiteLoader_300_00026.png";
import img27 from "../../../img/loader_animation/Atm_SiteLoader_300_00027.png";
import img28 from "../../../img/loader_animation/Atm_SiteLoader_300_00028.png";
import img29 from "../../../img/loader_animation/Atm_SiteLoader_300_00029.png";
import img30 from "../../../img/loader_animation/Atm_SiteLoader_300_00030.png";
import img31 from "../../../img/loader_animation/Atm_SiteLoader_300_00031.png";
import img32 from "../../../img/loader_animation/Atm_SiteLoader_300_00032.png";
import img33 from "../../../img/loader_animation/Atm_SiteLoader_300_00033.png";
import img34 from "../../../img/loader_animation/Atm_SiteLoader_300_00034.png";
import img35 from "../../../img/loader_animation/Atm_SiteLoader_300_00035.png";
import img36 from "../../../img/loader_animation/Atm_SiteLoader_300_00036.png";
import img37 from "../../../img/loader_animation/Atm_SiteLoader_300_00037.png";
import img38 from "../../../img/loader_animation/Atm_SiteLoader_300_00038.png";
import img39 from "../../../img/loader_animation/Atm_SiteLoader_300_00039.png";
import img40 from "../../../img/loader_animation/Atm_SiteLoader_300_00040.png";
import img41 from "../../../img/loader_animation/Atm_SiteLoader_300_00041.png";
import img42 from "../../../img/loader_animation/Atm_SiteLoader_300_00042.png";
import img43 from "../../../img/loader_animation/Atm_SiteLoader_300_00043.png";
import img44 from "../../../img/loader_animation/Atm_SiteLoader_300_00044.png";
import img45 from "../../../img/loader_animation/Atm_SiteLoader_300_00045.png";
import img46 from "../../../img/loader_animation/Atm_SiteLoader_300_00046.png";
import img47 from "../../../img/loader_animation/Atm_SiteLoader_300_00047.png";
import img48 from "../../../img/loader_animation/Atm_SiteLoader_300_00048.png";
import img49 from "../../../img/loader_animation/Atm_SiteLoader_300_00049.png";
import img50 from "../../../img/loader_animation/Atm_SiteLoader_300_00050.png";

const LoaderLogo = () => {
  const logoRef = useRef(null);
  const [hideAndShowLogo, setHideLogo] = useState(true);

  const onComplete = () => {
      setHideLogo(false)
  };

  const addExitClass = () => {
    logoRef.current.parentElement.className = "logo-loader__container-exit";
  };

  const scroll = window.innerWidth < 480 ? -750 : -1500;

  useEffect(() => {
    const anim = gsap
      .timeline({ repeat: 0 })
      .eventCallback('onComplete', onComplete)
      .set(logoRef.current, { y: 0, autoAlpha: 1 })
      .to(logoRef.current, {
        duration: 2,
        y: `${scroll}rem`,
        ease: "steps(50)",
      })

  }, [logoRef]);

  return (
    <CSSTransition
      in={hideAndShowLogo}
      timeout={{
        exit: 500
      }}
      classNames="logo-loader"
      mountOnEnter
      unmountOnExit
      onExit={addExitClass}
    >
      <div className="logo-loader">
        <div className="logo-loader__container">
          <div ref={logoRef} className="logo-loader__animation">
            <img src={img0} className="animation__slide" />
            <img src={img1} className="animation__slide" />
            <img src={img2} className="animation__slide" />
            <img src={img3} className="animation__slide" />
            <img src={img4} className="animation__slide" />
            <img src={img5} className="animation__slide" />
            <img src={img6} className="animation__slide" />
            <img src={img7} className="animation__slide" />
            <img src={img8} className="animation__slide" />
            <img src={img9} className="animation__slide" />
            <img src={img10} className="animation__slide" />
            <img src={img11} className="animation__slide" />
            <img src={img12} className="animation__slide" />
            <img src={img13} className="animation__slide" />
            <img src={img14} className="animation__slide" />
            <img src={img15} className="animation__slide" />
            <img src={img16} className="animation__slide" />
            <img src={img17} className="animation__slide" />
            <img src={img18} className="animation__slide" />
            <img src={img19} className="animation__slide" />
            <img src={img20} className="animation__slide" />
            <img src={img21} className="animation__slide" />
            <img src={img22} className="animation__slide" />
            <img src={img23} className="animation__slide" />
            <img src={img24} className="animation__slide" />
            <img src={img25} className="animation__slide" />
            <img src={img26} className="animation__slide" />
            <img src={img27} className="animation__slide" />
            <img src={img28} className="animation__slide" />
            <img src={img29} className="animation__slide" />
            <img src={img30} className="animation__slide" />
            <img src={img31} className="animation__slide" />
            <img src={img32} className="animation__slide" />
            <img src={img33} className="animation__slide" />
            <img src={img34} className="animation__slide" />
            <img src={img35} className="animation__slide" />
            <img src={img36} className="animation__slide" />
            <img src={img37} className="animation__slide" />
            <img src={img38} className="animation__slide" />
            <img src={img39} className="animation__slide" />
            <img src={img40} className="animation__slide" />
            <img src={img41} className="animation__slide" />
            <img src={img42} className="animation__slide" />
            <img src={img43} className="animation__slide" />
            <img src={img44} className="animation__slide" />
            <img src={img45} className="animation__slide" />
            <img src={img46} className="animation__slide" />
            <img src={img47} className="animation__slide" />
            <img src={img48} className="animation__slide" />
            <img src={img49} className="animation__slide" />
            <img src={img50} className="animation__slide" />
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default LoaderLogo;
