import React, { Component } from 'react';
import styles from './style.scss';
import { connect } from 'react-redux';
import GamePage from '../GamePage/GamePage';
import gameBanner from '../../img/game-page/musicWheel/banner.jpg';
import desktopIcon from '../../img/game-page/musicWheel/laptop.svg';
import tabletIcon from '../../img/game-page/musicWheel/ipad.svg';
import mobileIcon from '../../img/game-page/musicWheel/phone.svg';
import deviceDesktop from '../../img/game-page/musicWheel/device-desktop.jpg';
import deviceTablet from '../../img/game-page/musicWheel/device-tablet.jpg';
import deviceMobile from '../../img/game-page/musicWheel/device-mobile.jpg';

import slideDesktop1 from "../../img/game-page/musicWheel/gallery-desktop/slide-1.jpg";
import slideDesktop2 from "../../img/game-page/musicWheel/gallery-desktop/slide-2.jpg";
import slideDesktop3 from "../../img/game-page/musicWheel/gallery-desktop/slide-3.jpg";

import slideTablet1 from "../../img/game-page/musicWheel/gallery-tablet/slide-1.jpg";
import slideTablet2 from "../../img/game-page/musicWheel/gallery-tablet/slide-2.jpg";
import slideTablet3 from "../../img/game-page/musicWheel/gallery-tablet/slide-3.jpg";

import slideMobile1 from "../../img/game-page/musicWheel/gallery-mobile/slide-1.jpg";
import slideMobile2 from "../../img/game-page/musicWheel/gallery-mobile/slide-2.jpg";
import slideMobile3 from "../../img/game-page/musicWheel/gallery-mobile/slide-3.jpg";
import slideMobile4 from "../../img/game-page/musicWheel/gallery-mobile/slide-4.jpg";

import feature1 from "../../img/game-page/musicWheel/features/feature-1.svg";
import feature2 from "../../img/game-page/musicWheel/features/feature-2.svg";
import feature3 from "../../img/game-page/musicWheel/features/feature-3.svg";

import ruleImg1 from "../../img/rules/musicWheel/ruleImg1.png";
import ruleImg2 from "../../img/rules/musicWheel/ruleImg2.png";
import ruleImg3 from "../../img/rules/musicWheel/ruleImg3.png";
import ruleImg4 from "../../img/rules/musicWheel/ruleImg4.png";
import ruleImg5 from "../../img/rules/musicWheel/ruleImg5.png";
import ruleImg6 from "../../img/rules/musicWheel/ruleImg6.png";
import ruleImg7 from "../../img/rules/musicWheel/ruleImg7.png";

import partner1 from "../../img/game-partners/bb.svg";
import partner2 from "../../img/game-partners/1-xbet.svg";
import partner3 from "../../img/game-partners/betconstruct.svg";
import partner4 from "../../img/game-partners/softgaming.svg";
import partner5 from "../../img/game-partners/parimatch.svg";
import partner6 from "../../img/game-partners/digitan.svg";
import partner7 from "../../img/game-partners/pronet.svg";
import partner8 from "../../img/game-partners/softswiss.svg";
import partner9 from "../../img/game-partners/bogap.svg";
import partner10 from "../../img/game-partners/luckyland.svg";
import partner11 from "../../img/game-partners/technamin.svg";


import { loadResources } from "../../util";
import logo from "../../img/logo-header-ler.svg";
import gilroyBold from "../../fonts/Gilroy/Gilroy-Bold.woff2";
import PTRootUIMedium from "../../fonts/PTRootUI/PTRootUI-Medium.woff2";
import latoRegular from "../../fonts/Lato/Lato-Regular.woff2";
import LoaderLogo from "../CommonComponents/LoaderLogoComponent";

const partners = [
  {
    title: "BetBoom",
    date: "2021",
    logo: partner1,
  },
  {
    title: "1xBet",
    date: "2021",
    logo: partner2,
  },
  {
    title: "BetConstruct",
    date: "2021",
    logo: partner3,
  },
  {
    title: "SoftGamings",
    date: "2021",
    logo: partner4,
  },
  {
    title: "PariMatch",
    date: "2021",
    logo: partner5,
  },
  {
    title: "Digitain",
    date: "2021",
    logo: partner6,
  },
  {
    title: "ProNetGaming",
    date: "2021",
    logo: partner7,
  },
  {
    title: "SoftSwiss",
    date: "2021",
    logo: partner8,
  },
  {
    title: "BoGap",
    date: "2021",
    logo: partner9,
  },
  {
    title: "LuckyLand",
    date: "2021",
    logo: partner10, 
  },
  {
    title: "Technamin",
    date: "2022",
    logo: partner11, 
  },
];

const rules = (
  <div class="rules-content" data-scope-acid-2248="">
    <p class="rules-paragraph">
      Music Wheel is a variety of the popular game «The Wheel of Fortune». The aim of the game is guessing a sector, where the arrow stops after the wheel turn, and getting the prize.
    </p>
    <p class="rules-paragraph">
      The bonus system is applied in the game. In each round (after the bets are off) this is a random choice of one out of seven sectors and a fixed multiplication of payments of a part of its cells. These cells are highlighted on the wheel by blinking white light.
    </p>
    <p class="rules-paragraph">
      A bonus game starts by default. To take part in the bonus game a player should simply guess a bonus sector in the current round and make a bet with one card (or more).
    </p>

    <h2 class="tag-game">Main game</h2>

    <p class="rules-paragraph">To take part in the game you should make a bet on one or more cards with numbers: 2, 4, 6, 12, 16, 24 and 48. When all bets are off the system chooses a bonus sector. Then the stickman turns the wheel. The winning sector is determined after full wheel stop. The arrow at the top screen part shows it.</p>
    <p class="rules-paragraph">If the selected sector and the determined sector match — you get a prize.</p>

    <figure>
      <img class="tag-comb-board" src={ruleImg1} alt="rules" />
    </figure>

    <p class="rules-paragraph">All the bets get paid multiplied by coefficients, which are indicated on the wheel sectors, excluding the cases, when a cell of the given sector is a bonus cell.</p>
    <p class="rules-paragraph"> Please note, that in case of a winning game you will get the final coefficient excluding the sum of the returning bet. For example, 48 will be paid as 47 to 1; 12 will be paid as 11 to 1, etc.</p>

    <figure>
      <img class="tag-comb-board" src={ruleImg2} alt="win" />
    </figure>

    <h2 class="tag-take-part">Bonus game</h2>

    <p class="rules-paragraph">The bonus system contains the following steps:</p>
    
    <ol class="tag-ol">
      <li class="rules-paragraph">After all bets are off and the round is not yet started, the electronic cursor determines the bonus sector in the current round.</li>
      <li class="rules-paragraph">When the sector is determined, a part of the cells become “bonus cells”. These sectors are highlighted by blinking white light.</li>
      <li class="rules-paragraph">If the selected cell falls into the sector, chosen by you in the open bet round and it is a bonus cell, the prize is paid with a higher coefficient. If the selected cell falls into the sector, chosen by you, but it is not a bonus cell, then the prize is paid with the regular coefficient.</li>
    </ol>
    
    <h2 class="tag-take-part">Payments</h2>

    <p class="rules-paragraph">To take part in the game you should make a bet on one or more cards with numbers: 2, 4, 6, 12, 16, 24 and 48. When all bets are off the system chooses a bonus sector. Then the stickman turns the wheel. The winning sector is determined after full wheel stop. The arrow at the top screen part shows it.</p>

    <p class="rules-paragraph">If the selected sector and the determined sector match — you get a prize.</p>

    <div className="tag-table-wrapper">
      <table class="tag-table">
        <thead>
          <tr>
            <th>Wheel sectors</th>
            <th>Number of cells</th>
            <th>Payment</th>
            <th>Bonus payoff</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>2</td>
            <td>24</td>
            <td>1 to 1</td>
            <td>3 to 1</td>
          </tr>
          <tr>
            <td>4</td>
            <td>12</td>
            <td>3 to 1</td>
            <td>7 to 1</td>
          </tr>
          <tr>
            <td>6</td>
            <td>8</td>
            <td>5 to 1</td>
            <td>11 to 1</td>
          </tr>
          <tr>
            <td>12</td>
            <td>4</td>
            <td>11 to 1</td>
            <td>24 to 1</td>
          </tr>
          <tr>
            <td>16</td>
            <td>3</td>
            <td>15 to 1</td>
            <td>39 to 1</td>
          </tr>
          <tr>
            <td>24</td>
            <td>2</td>
            <td>23 to 1</td>
            <td>49 to 1</td>
          </tr>
          <tr>
            <td>48</td>
            <td>1</td>
            <td>47 to 1</td>
            <td>74 to 1</td>
          </tr>
        </tbody>
      </table>
    </div>

    <p class="rules-paragraph">Please note, that in case of any game system upset, the round will be cancelled and all bets will be returned.</p>
  
    <h2 class="tag-take-part">Player's profit</h2>
    
    <p class="rules-paragraph">Average return to player (RTP) makes up 95.5%.</p>
    <p class="rules-paragraph">Overall figures are presented in the table below:</p>

    <div className="tag-table-wrapper">
      <table class="tag-table">
        <thead>
          <tr>
            <th>Sector</th>
            <th>Profit without the bonus game</th>
            <th>Profit with the bonus game</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>2</td>
            <td>88,88%</td>
            <td>95.24%</td>
          </tr>
          <tr>
            <td>4</td>
            <td>88,88%</td>
            <td>95.24%</td>
          </tr>
          <tr>
            <td>6</td>
            <td>88,88%</td>
            <td>95.24%</td>
          </tr>
          <tr>
            <td>12</td>
            <td>88,88%</td>
            <td>95.77%</td>
          </tr>
          <tr>
            <td>16</td>
            <td>88,88%</td>
            <td>95.24%</td>
          </tr>
          <tr>
            <td>24</td>
            <td>88,88%</td>
            <td>95.77%</td>
          </tr>
          <tr>
            <td>48</td>
            <td>88,88%</td>
            <td>96.03%</td>
          </tr>
        </tbody>
      </table>
    </div>

    <h2>Gaming process</h2>

    <h3>Controls</h3>

    <p class="rules-paragraph">Before starting please get used to the bet making controls.</p>

    <figure>
      <img src={ruleImg3} alt="controls: 2,4,6,12,16,24,48" />
    </figure>

    <p class="rules-paragraph">Sectors, which can be bet on in the open bet round.</p>

    <figure>
      <img src={ruleImg4} alt="bet" />
    </figure>

    <p class="rules-paragraph">Table limits. This pop-down menu shows minimum and maximum bet limit for each type and coefficient.</p>
  
    <figure>
      <img src={ruleImg5} alt="indicator" />
    </figure>

    <p class="rules-paragraph">Round information indicator shows the present game stage and the time, left for bet closure or bet opening, etc.</p>
  
    <figure>
      <img src={ruleImg6} alt="chip menu" />
    </figure>

    <p class="rules-paragraph">Chip menu allows selecting the necessary chip value. You can also repeat the last bet, cancel the previous action, remove all the bets from the playing table, and multiply your bet x2 or view table statistics.</p>
  
    <figure>
      <img src={ruleImg7} alt="bonus sector" />
    </figure>

    <h2>Game results</h2>

    <p class="rules-paragraph">Each round is finished after the winning sector is displayed in the player interface at the bet screen and in the pop-up window. The prize amount is also displayed in this window.</p>

    <h2>Games history</h2>

    <p class="rules-paragraph">The “History” button opens up the pop-up window. This window displays all the rounds, where you made a bet, and the results of these rounds.</p>

    <h2>Music</h2>
    <p class="rules-paragraph">The gaming process during the draw announcement can be well animated and excited due to the background music.</p>
    <p class="rules-paragraph">The inner part of the wheel contains six sectors with different musical forms. If a cell with a musical note sign is selected, a composition of one of six music styles will be played.</p>
    <p class="rules-paragraph">Just to make sure — this does not affect prizes or winning chances.</p>
  
    <h2>Game problems</h2>

    <p class="rules-paragraph">In case of game failures the round will be temporarily stopped. The host will inform the gamers and the technical staff. If the support team can immediately eliminate the errors, then the game round will continue as usual. If the error can not be eliminated, then all the round bets will be cancelled and returned to gamers.</p>
  
    <h2>Connection problems</h2>

    <p class="rules-paragraph">In case of game disconnection due to connection problems on the gamer's side, all the bets are considered valid. They will be run independently from the gamer. Round results can be reviewed in the “History” tab after reconnection.</p>
  </div>
);
class GamePageMusicWheel extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    loadResources([
      ["image", logo],
      ["image", gameBanner],
      ["font", gilroyBold, { name: "Gilroy", style: "normal", weight: 900 }],
      ["font", gilroyBold, { name: "Gilroy", style: "normal", weight: 800 }],
      ["font", gilroyBold, { name: "Gilroy", style: "normal", weight: 700 }],
      [
        "font",
        PTRootUIMedium,
        { name: "PT Root UI", style: "normal", weight: 500 },
      ],
      ["font", latoRegular, { name: "Lato", style: "normal", weight: 500 }],
      [
        "font",
        latoRegular,
        { name: "Lato", style: "normal", weight: "normal" },
      ],
    ]).then(() => {
      this.props.dispatch({
        type: "set-resources-loaded",
        payload: { loaded: true },
      });
    });
  }

  render() {
    return (
      <>
        <LoaderLogo isLoaded={this.props.loadedResources.loaded} />
        <GamePage
          banner={gameBanner}
          theme="game_music_wheel"
          name="music wheel"
          text="Unique show game with the first fully electronic display in the gambling industry playing any kind of animation. The maximum multiplier in a round is x75 of the bet amount. Simple rules and fun gameplay!"
          desktopIcon={desktopIcon}
          tabletIcon={tabletIcon}
          mobileIcon={mobileIcon}
          deviceDesktop={deviceDesktop}
          deviceTablet={deviceTablet}
          deviceMobile={deviceMobile}
          slidesDesktop={[
            slideDesktop1,
            slideDesktop2,
            slideDesktop3,
          ]}
          slidesTablet={[slideTablet1, slideTablet2, slideTablet3]}
          slidesMobile={[
            slideMobile1,
            slideMobile2,
            slideMobile3,
            slideMobile4,
          ]}
          features={[
            {
              icon: feature1,
              text: "Low volatility",
            },
            {
              icon: feature3,
              text: "Bonus games every round",
            },
            {
              icon: feature2,
              text: "Unique electronic wheel design",
            },
          ]}
          rules={rules}
          partners={partners}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.language,
  loadedResources: state.loadedResources,
});

export default connect(mapStateToProps)(GamePageMusicWheel);
