import React from "react";
import styles from "./style.scss";
import { connect } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary as AccordionSummaryMUI,
} from "@material-ui/core";
import Button from "../../CommonComponents/ButtonComponent/index";
import CompanyForm from "../CompanyForm/CompanyForm";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function CompanyCareersComponent({ isMobile, theme, data }) {
  const allAccardionStyles = {
    backgroundColor: "#131a60",
    borderRadius: "4px",
    boxShadow: "none",
    marginBottom: "8px",
  };

  const matches = useMediaQuery("(max-width:480px)");

  const closeIconBg = (
    <div className="company-careers__openCloseIcon">
      {matches ? (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 3a1 1 0 0 1 .993.883L9 4v3h3a1 1 0 0 1 .117 1.993L12 9H9v3a1 1 0 0 1-1.993.117L7 12V9H4a1 1 0 0 1-.117-1.993L4 7h3V4a1 1 0 0 1 1-1z"
            fill="#A2B6EF"
            fillRule="nonzero"
          />
        </svg>
      ) : (
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 0a1 1 0 0 1 .993.883L8 1v5h5a1 1 0 0 1 .117 1.993L13 8H8v5a1 1 0 0 1-1.993.117L6 13V8H1a1 1 0 0 1-.117-1.993L1 6h5V1a1 1 0 0 1 1-1z"
            fill="#A2B6EF"
            fillRule="nonzero"
          />
        </svg>
      )}
    </div>
  );

  const closeIcon = (
    <>
      {matches ? (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
          className={"company-careers__svg_close"}
        >
          <path
            d="M12 7a1 1 0 0 1 .117 1.993L12 9H4a1 1 0 0 1-.117-1.993L4 7h8z"
            fill="#A2B6EF"
            fillRule="nonzero"
          />
        </svg>
      ) : (
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          xmlns="http://www.w3.org/2000/svg"
          className={"company-careers__svg_open"}
        >
          <path
            d="M7 0a1 1 0 0 1 .993.883L8 1v5h5a1 1 0 0 1 .117 1.993L13 8H8v5a1 1 0 0 1-1.993.117L6 13V8H1a1 1 0 0 1-.117-1.993L1 6h5V1a1 1 0 0 1 1-1z"
            fill="#A2B6EF"
            fillRule="nonzero"
          />
        </svg>
      )}
      {matches ? (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
          className={"company-careers__svg_open"}
        >
          <path
            d="M8 3a1 1 0 0 1 .993.883L9 4v3h3a1 1 0 0 1 .117 1.993L12 9H9v3a1 1 0 0 1-1.993.117L7 12V9H4a1 1 0 0 1-.117-1.993L4 7h3V4a1 1 0 0 1 1-1z"
            fill="#A2B6EF"
            fillRule="nonzero"
          />
        </svg>
      ) : (
        <svg
          width="14"
          height="2"
          viewBox="0 0 14 2"
          xmlns="http://www.w3.org/2000/svg"
          className={"company-careers__svg_close"}
        >
          <path
            d="M13 0a1 1 0 0 1 .117 1.993L13 2H1A1 1 0 0 1 .883.007L1 0h12z"
            fill="#A2B6EF"
            fillRule="nonzero"
          />
        </svg>
      )}
    </>
  );

  const StyledAccardionSummaryStep1 = withStyles({
    expanded: {
      margin: 0,
    },
    root: {
      padding: matches ? "0px 16px" : "0px 20px 0px 32px",
      height: matches ? "64px" : "80px",
      "&$expanded": {
        minHeight: 0,
      },
    },
    expandIcon: {
      padding: 0,
      marginRight: 0,
    },
  })(AccordionSummaryMUI);

  const StyledAccardionSummaryStep2 = withStyles({
    expanded: {
      margin: 0,
    },
    root: {
      padding: 0,
      height: "5.6rem",
      backgroundColor: "#0C0C4D",
      minHeight: 0,
      "&$expanded": {
        minHeight: 0,
      },
    },
    content: {
      margin: 0,
      "&$expanded": {
        margin: 0,
      },
    },
    expandIcon: {
      marginRight: 0,
      padding: "8px",
    },
  })(AccordionSummaryMUI);

  const StyledAccardionSummaryStep3 = withStyles({
    expanded: {
      margin: 0,
    },
    root: {
      padding: 0,
      "&$expanded": {
        minHeight: 0,
      },
    },
    content: {
      margin: 0,
      "&$expanded": {
        margin: 0,
      },
    },
  })(AccordionSummaryMUI);

  const useStyles = makeStyles({
    root: {
      padding: matches ? "2.4rem 1.6rem" : "3.2rem 1.6rem",
      backgroundColor: "#0C0C4D",
      borderRadius: "0 0 4px 4px",
    },
    form: {
      padding: 0,
      marginBottom: matches ? "1.6rem" : "4rem",
    },
  });
  const classes = useStyles();

  return (
    <div className="company-careers">
      <div className="company-careers__title">Careers</div>
      {data.map((el, idx) => {
        return (
          <Accordion
            key={el.category.id}
            className="company-careers__accardion"
            style={allAccardionStyles}
          >
            <StyledAccardionSummaryStep1
              expandIcon={closeIconBg}
              aria-controls="Game Design"
              id="Game Design"
            >
              <div className="company-careers__nameStep1">
                {el.category.name}
              </div>
              {isMobile ? (
                <div className="company-careers__grayText">
                  {el.list.length}
                </div>
              ) : (
                <div className="company-careers__grayText">
                  Open position:{" "}
                  <span className="company-careers__whiteText">
                    {el.list.length}
                  </span>
                </div>
              )}
            </StyledAccardionSummaryStep1>
            <AccordionDetails
              classes={{ root: classes.root }}
              style={{ display: "block" }}
            >
              {el.list.map((elem, index) => {
                return (
                  <Accordion
                    square={true}
                    key={elem.id}
                    className="company-careers__intoAccardion"
                  >
                    <StyledAccardionSummaryStep2
                      expandIcon={closeIcon}
                      aria-controls={elem.name}
                      id={elem.name}
                    >
                      <div className="company-careers__nameStep2">
                        {elem.name}
                      </div>
                      <div className="company-careers__grayText">
                        {elem.vacancies_employee.name}
                      </div>
                    </StyledAccardionSummaryStep2>
                    <AccordionDetails className="company-careers__requirements">
                      <div className="company-careers__wrapper">
                        <div
                          className="company-careers__requirements_item"
                          dangerouslySetInnerHTML={{ __html: elem.description }}
                        />
                      </div>
                      <Accordion
                        style={allAccardionStyles}
                        className="company-careers__accardion_respond"
                      >
                        <StyledAccardionSummaryStep3>
                          <Button
                            mod={theme ? "type-2_" + theme : "type-1"}
                            title={"Respond"}
                            className={"company-careers__button"}
                          />
                        </StyledAccardionSummaryStep3>
                        <AccordionDetails classes={{ root: classes.form }}>
                          <div className="company-careers__form">
                            <CompanyForm index={elem.id} />
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}

const mapStateToProps = (state) => ({
  language: state.language,
});

export default connect(mapStateToProps)(CompanyCareersComponent);
