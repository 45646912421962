import React from 'react';
import style from './style.scss';

import InputStatus from '../InputStatus'


const errorMessage = ({ errorIndex, msg, ...props }) => {
  return (
    <div className="error">
      <InputStatus type="error" value={errorIndex}/>
      <span className="error_msg">{msg}</span>
    </div>
  );
};

export default errorMessage;
