import React, { useEffect, useState } from 'react'
import styles from "./style.scss"
import {connect} from "react-redux"


const becomeItemComponent = (props) => {


    const {number, title, text, icon, backSide, submitResult} = props;

    const [turnedDown, setTurnedDown] = useState(false);

    useEffect(()=> {
        if (parseInt(number) === 1  && submitResult && submitResult.status) {
            setTimeout(()=> {
                setTurnedDown(true);
                
                setTimeout(()=> {
                    setTurnedDown(false)}, 4000)
            }, 3000);

        };

                
    }, [submitResult])

    return(
        <div className="become-item">
            <div className="become-item__number">{number}</div>
            <div 
                className="become-item__main" 
                style={parseInt(number) === 1 && turnedDown ? {transform: 'rotateX(180deg)'} : {transform: 'rotateX(0)'}}
            >
                <div className="become-item__front">
                    <div className="become-item__content">
                        <div className="become-item__title">{title}</div>
                        <div className="become-item__text">{text}</div>
                    </div>
                    <div className="become-item__icon">
                        <img className="become-item__img" src={icon}/>
                    </div>
                </div>
                {parseInt(number) !== 1 ? null : (
                <div className="become-item__back">
                    <div className="become-item__content">
                        <div className="become-item__title">{backSide.title}</div>
                        <div className="become-item__text">{backSide.text}</div>
                    </div>
                    <div className="become-item__icon">
                        <img className="become-item__img" src={backSide.icon}/>
                    </div>
                    <div className={`become-item__back__progress ${turnedDown ? 'active' : ''}`}></div>
                </div>
            )}
            </div>
            
        </div>
    )
}


const mapStateToProps = state =>({
    language: state.language,
    submitResult: state.submitForm.partnershipForm.submitResult
})

export default connect(mapStateToProps)(becomeItemComponent)