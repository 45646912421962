import React from 'react'
import "./style.scss"
import {connect} from "react-redux"

function PrevButton(props){
    const {actionFunction, mod, theme} = props;
    return(
        <button className={"prev-button " + (mod ? "prev-button_" + mod :"")}  onClick={actionFunction}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <g  fillRule="evenodd">
                    <g fillRule="nonzero">
                        <g>
                            <g>
                                <g>
                                    <path d="M9.293.293c.39-.39 1.024-.39 1.414 0 .36.36.388.928.083 1.32l-.083.094-7 7c-.39.39-1.024.39-1.414 0-.36-.36-.388-.928-.083-1.32l.083-.094 7-7z" transform="translate(-348 -560) translate(328 540) translate(20 20)"/>
                                    <path d="M2.293 7.293c.36-.36.928-.388 1.32-.083l.094.083 7 7c.39.39.39 1.024 0 1.414-.36.36-.928.388-1.32.083l-.094-.083-7-7c-.39-.39-.39-1.024 0-1.414z" transform="translate(-348 -560) translate(328 540) translate(20 20)"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </button>
    )
}

const mapStateToProps = state =>({
    language: state.language
})

export default connect(mapStateToProps)(PrevButton)
