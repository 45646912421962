import React, { Component } from 'react';
import styles from './style.scss';
import { connect } from 'react-redux';
import GamePage from '../GamePage/GamePage';
import gameBanner from '../../img/game-page/keno/banner.jpg';
import desktopIcon from '../../img/game-page/keno/laptop.svg';
import tabletIcon from '../../img/game-page/keno/ipad.svg';
import mobileIcon from '../../img/game-page/keno/phone.svg';

import deviceDesktop from '../../img/game-page/keno/device-desktop.png';
import deviceTablet from '../../img/game-page/keno/device-tablet.png';
import deviceMobile from '../../img/game-page/keno/device-mobile.png';

import slideDesktop1 from '../../img/game-page/keno/gallery-desktop/slide-1.png';
import slideDesktop2 from '../../img/game-page/keno/gallery-desktop/slide-2.png';
import slideDesktop3 from '../../img/game-page/keno/gallery-desktop/slide-3.png';

import slideTablet1 from '../../img/game-page/keno/gallery-tablet/slide-1.png';
import slideTablet2 from '../../img/game-page/keno/gallery-tablet/slide-2.png';
import slideTablet3 from '../../img/game-page/keno/gallery-tablet/slide-3.png';

import slideMobile1 from '../../img/game-page/keno/gallery-mobile/slide-1.png';
import slideMobile2 from '../../img/game-page/keno/gallery-mobile/slide-2.png';
import slideMobile3 from '../../img/game-page/keno/gallery-mobile/slide-3.png'; 

import feature1 from '../../img/game-page/keno/features/feature-1.svg';
import feature2 from '../../img/game-page/keno/features/feature-2.svg';
import feature3 from '../../img/game-page/keno/features/feature-3.svg';

import image1 from '../../img/rules/keno/en-d-01-01.png';
import image2 from '../../img/rules/keno/en-d-01-03.png';
import image3 from '../../img/rules/keno/en-d-01-04.png';
import image4 from '../../img/rules/keno/en-d-01-02.png';

import partner1 from '../../img/game-partners/bb.svg';
import partner3 from '../../img/game-partners/1-xbet.svg';
import partner4 from '../../img/game-partners/pronet.svg';
import partner5 from '../../img/game-partners/luckyland.svg';
import partner6 from '../../img/game-partners/softswiss.svg';

import partner7 from '../../img/game-partners/parimatch.svg';
import partner8 from '../../img/game-partners/betconstruct.svg';
import partner9 from '../../img/game-partners/softgaming.svg';
import partner10 from '../../img/game-partners/arkingroup.svg';
import partner11 from '../../img/game-partners/top-play-gaming.svg';
import partner12 from '../../img/game-partners/technamin.svg';



import {loadResources} from '../../util';
import logo from '../../img/logo-header-def.svg';
import gilroyBold from '../../fonts/Gilroy/Gilroy-Bold.woff2';
import PTRootUIMedium from '../../fonts/PTRootUI/PTRootUI-Medium.woff2';
import LoaderLogo from '../CommonComponents/LoaderLogoComponent';

const partners= [
  {
    title: "BetBoom",
    date: "2019",
    logo: partner1,
  },
  {
    title: "1xBet",
    date: "2019",
    logo: partner3,
  },
  {
    title: "ProNetGaming",
    date: "2020",
    logo: partner4,
  },
  {
    title: "LuckyLand",
    date: "2020",
    logo: partner5,
  },
  {
    title: "SoftSwiss",
    date: "2021",
    logo: partner6,
  },
  {
    title: "PariMatch",
    date: "2021",
    logo: partner7, 
  },
  {
    title: "Betconstruct",
    date: "2021",
    logo: partner8, 
  },
  {
    title: "SoftGamings",
    date: "2021",
    logo: partner9, 
  },
  {
    title: "Arkin Group",
    date: "2020",
    logo: partner10, 
  },
  {
    title: "TopPlayGaming",
    date: "2021",
    logo: partner11, 
  },
  {
    title: "Technamin",
    date: "2022",
    logo: partner12, 
  },

];

// import GamePageKenoRules from './GamePageKenoRules';

const rules = (
  <div className="rules-scroll-section fade-bottom">
    <div className="rules-elem tag-game">
      <div className="rules-title">Game</div>
      <div className="rules-ph">
        <span>
          In this game, you need to predict as many numbers as possible from
          those checked off on the keno card. You can pick 1 to 10 numbers out
          of 80. The more numbers you pick and correctly predict, the more you
          win. The total for all purchased tickets cannot exceed $1,000.
        </span>
      </div>
      <div className="rules-ph">
        The lotto machine randomly draws numbered balls with winning numbers.
      </div>
    </div>
    <div className="rules-elem tag-how-to">
      <div className="rules-title">Playing the Game</div>
      <div className="rules-ph">
        To play in a draw, you need to buy a card with checked off numbers.
      </div>

      <img alt="rules" src={image1} />

      <div className="rules-ph">
        The minimum number of cards is one. The maximum number of cards in a
        game is not limited.
      </div>
      <div className="rules-title-2 tag-nominals">Card Values :</div>

      <ul className="rules-list">
        <li> $1</li>

        <li> $2</li>

        <li> $5</li>

        <li> $10</li>

        <li> $50</li>
      </ul>

      <div className="rules-title-2 tag-colors">Color coding :</div>

      <img alt="rules" src={image4} />

      <div className="rules-ph">
        The higher is the card value, the higher is your possible payout.
      </div>
    </div>
    <div className="rules-elem tag-game-stages">
      <div className="rules-title">Stages of the game</div>
      <div className="rules-title-2 tag-stage1">Stage 1 is buying a card</div>
      <div className="rules-ph">
        A stage of 1 minute in between the draws. You have one minute to look
        through the statistics, decide on your strategy and pick numbers in the
        cards.
      </div>
      <div className="rules-ph">
        If you buy no cards before the live stage begins, you will miss next
        draw. You can also buy a card during the draw before the break between
        games if you don’t take part in the draw.
      </div>
      <div className="rules-ph">
        You can pick number quickly and easily using Quick Pick, Even and Odd
        buttons.
      </div>
      <div className="rules-ph">
        Quick Pick is a random choice of numbers. If you have picked no numbers,
        you may click this button to check off ten random numbers. If you have
        already picked your numbers, you may click this button to replace your
        numbers with random ones.
      </div>
      <div className="rules-ph">
        Odds Quick Pick is a random pick of odd numbers. If you have picked no
        numbers, you may click this button to check off ten random odd numbers.
        If you have already picked your numbers, you may click this button to
        replace your numbers with random ones.
      </div>
      <div className="rules-ph">
        Evens Quick Pick is a random pick of even numbers. If you have picked no
        numbers, you may click this button to check off ten random even numbers.
        If you have already picked your numbers, you may click this button to
        replace your numbers with random ones.
      </div>
      <div className="rules-ph">Click Options to see these buttons.</div>

      <img alt="rules" src={image2} />

      <div className="rules-title-2 tag-stage2">Stage 2 is live</div>
      <div className="rules-ph">
        A 1-minute draw, during which winning cards are determined by filling
        numbers of the balls drawn from the lotto machine.
      </div>
      <div className="rules-ph">
        You can watch drawn balls in the live broadcast or Balls line if you
        disabled or minimized the broadcast.
      </div>
    </div>
    <div className="rules-elem tag-win">
      <div className="rules-title">Win</div>
      <div className="rules-title-2 tag-combinations">
        Winning combinations :
      </div>
      <ol className="rules-list">
        <li>Card with 1 picked number – predict 1 number</li>
        <li>Card with 2 picked numbers – predict from 1 to 2 numbers</li>
        <li>Card with 3 picked numbers – predict from 2 to 3 numbers</li>
        <li>Card with 4 picked numbers – predict from 2 to 4 numbers</li>
        <li>Card with 5 picked numbers – predict from 2 to 5 numbers</li>
        <li>Card with 6 picked numbers – predict from 3 to 7 numbers</li>
        <li>Card with 7 picked numbers – predict from 3 to 7 numbers</li>
        <li>Card with 8 picked numbers – predict from 4 to 8 numbers</li>
        <li>Card with 9 picked numbers – predict from 4 to 9 numbers</li>
        <li>Card with 10 picked numbers – predict from 5 to 10 numbers</li>
      </ol>
      <div className="rules-ph">
        Maximum win amount may be restricted according to the platform rules.
      </div>
      <div className="rules-title-2 tag-coeff-table">Pay Table :</div>
      <div>
        <div className="coefficients-table" data-scope-acid-1730="">
          <div className="table-cell border_3 stage_1"></div>

          <div className="table-cell border_3 stage_1">1</div>

          <div className="table-cell border_3 stage_1">2</div>

          <div className="table-cell border_3 stage_1">3</div>

          <div className="table-cell border_3 stage_1">4</div>

          <div className="table-cell border_3 stage_1">5</div>

          <div className="table-cell border_3 stage_1">6</div>

          <div className="table-cell border_3 stage_1">7</div>

          <div className="table-cell border_3 stage_1">8</div>

          <div className="table-cell border_3 stage_1">9</div>

          <div className="table-cell border_3 stage_1">10</div>

          <div className="table-cell border_4 border_1 stage_1">1</div>

          <div className="table-cell border_4 stage_0 pointer">x 3.6</div>

          <div className="table-cell border_4 stage_0 pointer">x 1</div>

          <div className="table-cell border_4"></div>
          <div className="table-cell border_4"></div>
          <div className="table-cell border_4"></div>
          <div className="table-cell border_4"></div>
          <div className="table-cell border_4"></div>
          <div className="table-cell border_4"></div>
          <div className="table-cell border_4"></div>
          <div className="table-cell border_4 border_0"></div>

          <div className="table-cell border_1 stage_1">2</div>

          <div className="table-cell"></div>

          <div className="table-cell stage_0 pointer">x 8</div>

          <div className="table-cell stage_0 pointer">x 3</div>

          <div className="table-cell stage_0 pointer">x 1</div>

          <div className="table-cell stage_0 pointer">x 1</div>

          <div className="table-cell"></div>
          <div className="table-cell"></div>
          <div className="table-cell"></div>
          <div className="table-cell"></div>
          <div className="table-cell border_0"></div>

          <div className="table-cell border_1 stage_1">3</div>

          <div className="table-cell"></div>
          <div className="table-cell"></div>

          <div className="table-cell stage_0 pointer">x 30</div>

          <div className="table-cell stage_0 pointer">x 9</div>

          <div className="table-cell stage_0 pointer">x 3</div>

          <div className="table-cell stage_0 pointer">x 2</div>

          <div className="table-cell stage_0 pointer">x 2</div>

          <div className="table-cell"></div>
          <div className="table-cell"></div>
          <div className="table-cell border_0"></div>

          <div className="table-cell border_1 stage_1">4</div>

          <div className="table-cell"></div>
          <div className="table-cell"></div>
          <div className="table-cell"></div>

          <div className="table-cell stage_0 pointer">x 80</div>

          <div className="table-cell stage_0 pointer">x 20</div>

          <div className="table-cell stage_0 pointer">x 12</div>

          <div className="table-cell stage_0 pointer">x 4</div>

          <div className="table-cell stage_0 pointer">x 4</div>

          <div className="table-cell stage_0 pointer">x 2</div>

          <div className="table-cell border_0"></div>

          <div className="table-cell border_1 stage_1">5</div>

          <div className="table-cell"></div>
          <div className="table-cell"></div>
          <div className="table-cell"></div>
          <div className="table-cell"></div>

          <div className="table-cell stage_0 pointer">x 125</div>

          <div className="table-cell stage_0 pointer">x 50</div>

          <div className="table-cell stage_0 pointer">x 20</div>

          <div className="table-cell stage_0 pointer">x 16</div>

          <div className="table-cell stage_0 pointer">x 8</div>

          <div className="table-cell border_0 stage_0 pointer">x 5</div>

          <div className="table-cell border_1 stage_1">6</div>

          <div className="table-cell"></div>
          <div className="table-cell"></div>
          <div className="table-cell"></div>
          <div className="table-cell"></div>
          <div className="table-cell"></div>

          <div className="table-cell stage_0 pointer">x 500</div>

          <div className="table-cell stage_0 pointer">x 100</div>

          <div className="table-cell stage_0 pointer">x 70</div>

          <div className="table-cell stage_0 pointer">x 35</div>

          <div className="table-cell border_0 stage_0 pointer">x 30</div>

          <div className="table-cell border_1 stage_1">7</div>

          <div className="table-cell"></div>
          <div className="table-cell"></div>
          <div className="table-cell"></div>
          <div className="table-cell"></div>
          <div className="table-cell"></div>
          <div className="table-cell"></div>

          <div className="table-cell stage_0 pointer">x 1K</div>

          <div className="table-cell stage_0 pointer">x 200</div>

          <div className="table-cell stage_0 pointer">x 150</div>

          <div className="table-cell border_0 stage_0 pointer">x 100</div>

          <div className="table-cell border_1 stage_1">8</div>

          <div className="table-cell"></div>
          <div className="table-cell"></div>
          <div className="table-cell"></div>
          <div className="table-cell"></div>
          <div className="table-cell"></div>
          <div className="table-cell"></div>
          <div className="table-cell"></div>

          <div className="table-cell stage_0 pointer">x 2K</div>

          <div className="table-cell stage_0 pointer">x 1.5K</div>

          <div className="table-cell border_0 stage_0 pointer">x 500</div>

          <div className="table-cell border_1 stage_1">9</div>

          <div className="table-cell"></div>
          <div className="table-cell"></div>
          <div className="table-cell"></div>
          <div className="table-cell"></div>
          <div className="table-cell"></div>
          <div className="table-cell"></div>
          <div className="table-cell"></div>
          <div className="table-cell"></div>

          <div className="table-cell stage_0 pointer">x 5K</div>

          <div className="table-cell border_0 stage_0 pointer">x 1.5K</div>

          <div className="table-cell border_1 stage_1">10</div>

          <div className="table-cell"></div>
          <div className="table-cell"></div>
          <div className="table-cell"></div>
          <div className="table-cell"></div>
          <div className="table-cell"></div>
          <div className="table-cell"></div>
          <div className="table-cell"></div>
          <div className="table-cell"></div>
          <div className="table-cell"></div>

          <div className="table-cell border_0 stage_0 pointer">x 10K</div>
        </div>
      </div>
    </div>
    <div className="rules-elem tag-bet-history">
      <div className="rules-title">Betting history</div>
      <div className="rules-ph">
        You can see the history of games and wins in Game History (Menu &gt;
        Game History).
      </div>

      <img
        alt="rules"
        src={image3}
        style={{ maxHeight: "-webkit-fill-available" }}
      />
    </div>
  </div>
);

class GamePageKeno extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    loadResources([
      ["image", logo],
      ["image", gameBanner],
      ["font", gilroyBold, { name: "Gilroy", style: "normal", weight: 900 }],
      [
        "font",
        PTRootUIMedium,
        { name: "PT Root UI", style: "normal", weight: 500 },
      ],
    ]).then(() => {
      this.props.dispatch({
        type: "set-resources-loaded",
        payload: { loaded: true },
      });
    });
  }

  render() {
    return (
      <>
        <LoaderLogo isLoaded={this.props.loadedResources.loaded} />
        <GamePage
          banner={gameBanner}
          theme="game_keno"
          name="Keno"
          text="Keno is traditionally the most popular game among Live Bingo games. Players wager by choosing numbers ranging from 1 through 80. You can multiply your bet by 10000 times."
          desktopIcon={desktopIcon}
          tabletIcon={tabletIcon}
          mobileIcon={mobileIcon}
          deviceDesktop={deviceDesktop}
          deviceTablet={deviceTablet}
          deviceMobile={deviceMobile}
          slidesDesktop={[
            slideDesktop1,
            slideDesktop2,
            slideDesktop3,
          ]}
          slidesTablet={[slideTablet1, slideTablet2, slideTablet3]}
          slidesMobile={[
            slideMobile1,
            slideMobile2,
            slideMobile3,
          ]}
          features={[
            {
              icon: feature1,
              text: "Free choice",
            },
            {
              icon: feature2,
              text: "Quick bets",
            },
            {
              icon: feature3,
              text: "x10000 payout",
            },
          ]}
          rules={rules}
          partners={partners}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.language,
  loadedResources: state.loadedResources,
});

export default connect(mapStateToProps)(GamePageKeno);
