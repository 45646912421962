import React, { useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group';
import ScrollToTop from '../ScrollToTopComponent/ScrollToTopComponent';
import styles from './style.scss'



const transitionWrapper = (props) => {

    const {match} = props;
    const pageRef= useRef();

    return (
        <CSSTransition
            in={match != null}
            timeout={640}
            classNames="animated_page"
            mountOnEnter
            unmountOnExit
            >
                <div className="animated_page" ref={pageRef}>
                    {props.children}
                </div>
        </CSSTransition>)  
}

export default  transitionWrapper