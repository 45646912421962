import React, { useRef, useState, useEffect } from "react";
import styles from "./style.scss";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import arrayEquals from "../../../functions/arrayEquals";

import InputField from "../../CommonComponents/InputFieldComponent/index";
import iconMail from "../../../img/input-mail.svg";
import iconMessage from "../../../img/input-message.svg";
import iconName from "../../../img/input-user.svg";
import ErrorMessage from "../../CommonComponents/ErrorMessage/index";
import ProgressButton from "../../CommonComponents/ProgressButton";
import { atLeastOneNonSpaceChar } from "../../../util";
import defaultFileSvg from "./img/file.svg";
import successfullUploadFlie from "./img/successfullUploadFlie.svg";
import errorFile from "./img/error.svg";

const partnerSchema = Yup.object().shape({
  name: Yup.string().test(
    "at-least-one-non-space-char",
    "Please introduce yourself",
    atLeastOneNonSpaceChar
  ),
  email: Yup.string()
    .email("Invalid email address")
    .required("This field is required"),
  message: Yup.string().max(250, "maximum 250 characters"),
});

const CompanyForm = ({ submitForm, index }) => {
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const messageRef = useRef(null);
  const [isUpload, setUpload] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileInfo, setFileInfo] = useState(null);
  const [isSubmitDone, setSubmitDone] = useState(false);
  const [formErrors, setFormErrors] = useState([]);

  const uploadHandler = (e) => {
    e.persist();
    const file = e.target.files[0];
    try {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      let type;
      const size = file.size;
      const filename = file.name;

      if (file.type !== null && file.type !== undefined) {
        type = String(file.type).slice(-3);

        setFileName(filename);

        if (
          (type === "peg" || type === "png" || type === "pdf") &&
          size < 5 * 1000000
        ) {
          setUpload(true);
          let result;

          reader.onloadend = async () => {
            result = await reader.result.split(",")[1];
            setFileInfo(
              (prev) =>
                (prev = [
                  {
                    filename,
                    content: result,
                    encoding: "base64",
                    size,
                  },
                ])
            );
          };
        } else {
          setUpload(false);
          setFileInfo(null)
        }
      }
    } catch (error) {}
  };

  const submitHandler = (values, resetFormCallback) => {
    fetch("/api/feedback", {
      method: "POST",
      body: JSON.stringify({ ...values, files: fileInfo }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        setSubmitDone(true);
        if (response.status === 200) {
        } else {
          throw new Error(response.status);
        }

        setTimeout(() => {
          resetFormCallback();
        }, 2000);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="company-form">
      <div className="company-form__container">
        {isSubmitDone ? (
          <div className="company-form__feedback">
            <div className="company-form__feedback_wrapper">
              <div className="company-form__feedback_img">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="13"
                  viewBox="0 0 18 13"
                >
                  <g fill="none" fillRule="evenodd">
                    <g fill="#FFF" fillRule="nonzero">
                      <path
                        d="M361.586 2377.586c.78-.781 2.047-.781 2.828 0 .74.74.78 1.915.117 2.701l-.117.127-9 9c-.74.74-1.915.78-2.701.117l-.127-.117-5-5c-.781-.78-.781-2.047 0-2.828.74-.74 1.915-.78 2.701-.117l.127.117 3.586 3.585 7.586-7.585z"
                        transform="translate(-347.000000, -2377.000000)"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div className="company-form__feedback_title">
                Thanks for your feedback!
              </div>
            </div>
            <p className="company-form__feedback_text">
              We always try our best to respond as soon as possible, you can
              expect a reply in at most 24 hours.
            </p>
          </div>
        ) : (
          <Formik
            initialValues={{
              name: "",
              email: "",
              message: "",
            }}
            validationSchema={partnerSchema}
            onSubmit={(values, { resetForm }) => {
              submitHandler(values, resetForm);
              // need to reset form only after success, now i put callback inside, but maybe use then if i catch error
            }}
          >
            {({
              isSubmitting,
              isValid,
              dirty,
              values,
              errors,
              touched,
              ...props
            }) => {
              useEffect(() => {
                let updatedErrors = [];
                for (const key in errors) {
                  if (touched[key] === true) {
                    updatedErrors = [...updatedErrors, key];
                  }
                }
                if (!arrayEquals(updatedErrors, formErrors)) {
                  setFormErrors(updatedErrors);
                }
              }, [errors, touched]);

              let ErrorMessages =
                submitForm.submitResult && !submitForm.submitResult.status
                  ? [
                      <ErrorMessage
                        key={"submitError"}
                        errorIndex={1}
                        msg={
                          "Problem has occurred (please check your internet connection)"
                        }
                      />,
                    ]
                  : formErrors.map((name, index) => (
                      <ErrorMessage
                        key={name}
                        errorIndex={index + 1}
                        msg={errors[name]}
                      />
                    ));

              return (
                <Form className="company-form__form">
                  <div className="company-form__title">Job application</div>
                  <div className="company-form__fields">
                    <div className="company-form__wrapper">
                      <div className="company-form__field">
                        <InputField
                          inputRef={nameRef}
                          placeholder="Name"
                          page="company"
                          icon={iconName}
                          type="text"
                          name="name"
                          errorIndex={formErrors.indexOf("name") + 1}
                        />
                      </div>
                      <div className="company-form__field">
                        <InputField
                          inputRef={emailRef}
                          placeholder="Email"
                          page="company"
                          icon={iconMail}
                          type="email"
                          name="email"
                          errorIndex={formErrors.indexOf("email") + 1}
                        />
                      </div>
                    </div>
                    <div className="company-form__textarea">
                      <InputField
                        inputRef={messageRef}
                        placeholder="Message or Question"
                        page="company"
                        type="textarea"
                        name="message"
                        icon={iconMessage}
                        errorIndex={formErrors.indexOf("message") + 1}
                        className="jobMessage"
                      />
                    </div>
                    <div>
                      {isUpload === null ? (
                        <img
                          className="company-form__img"
                          src={defaultFileSvg}
                          alt="defaultFileSvg"
                        />
                      ) : isUpload === true ? (
                        <img
                          className="company-form__img"
                          src={successfullUploadFlie}
                          alt="successfullUploadFlie"
                        />
                      ) : (
                        <img
                          className="company-form__img"
                          src={errorFile}
                          alt="errorFile"
                        />
                      )}
                      {fileName === "" ? (
                        <>
                          <label
                            htmlFor={`upload${index}`}
                            className="company-form__field company-form__file_text"
                          >
                            Please attach the file
                          </label>
                          <input
                            onChange={uploadHandler}
                            className="company-form__inputUpload"
                            id={`upload${index}`}
                            type="file"
                          />
                          <span className="company-form__field company-form__file_formatText">
                            ( pdf, jpg, png format. not more than 5Mb)
                          </span>
                        </>
                      ) : (
                        <>
                          <>
                            <label
                              htmlFor={`upload${index}`}
                              className="company-form__field company-form__file_text"
                            >
                              {fileName}
                            </label>
                            <input
                              onChange={uploadHandler}
                              className="company-form__inputUpload"
                              id={`upload${index}`}
                              type="file"
                            />
                          </>
                          {isUpload === false ? (
                            <>
                              <label
                                htmlFor={`upload${index}`}
                                className="company-form__field company-form__file_error"
                              >
                                The file was not attached
                              </label>
                              <input
                                onChange={uploadHandler}
                                className="company-form__inputUpload"
                                id={`upload${index}`}
                                type="file"
                              />
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  {isSubmitDone ? (
                    ""
                  ) : (
                    <div className="company-form__btn">
                      <ProgressButton
                        isDisabled={!(isValid && dirty)}
                        type="submit"
                      />
                    </div>
                  )}
                  <div className="company-form_errors">{ErrorMessages}</div>
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  language: state.language,
  submitForm: state.submitForm.partnershipForm,
});

export default connect(mapStateToProps)(CompanyForm);
