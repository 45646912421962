import React from 'react'
// import "./style.scss"
// styles from share component..
import {connect} from "react-redux"

function mediaLinks(props){
    const {theme} = props;

    const links = {
        fb: `https://facebook.com/ATMOSFERA.is`,
        // twitter: `https://twitter.com/`,
        in: `https://www.linkedin.com/company/atmosferalive`,


    }
    
    return(
        
        <div className={theme ? "share share_" + theme : "share"}>
            {/* <a href="#" className={theme ? "share__link share__link_" + theme : "share__link"}  target="_blank" rel="noreferrer noopener">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                >
                    <defs>
                        <path
                            id="39fp8pp8ka"
                            d="M7.797 2.528c0-.578-.149-1.074-.446-1.489C6.855.364 6.015.018 4.828 0H.123v9.754H4.51c.494 0 .952-.043 1.375-.13a2.694 2.694 0 001.789-1.258c.29-.459.434-.978.434-1.556 0-.56-.127-1.037-.378-1.429-.255-.393-.63-.68-1.126-.861.327-.167.574-.353.743-.555.301-.362.45-.842.45-1.437zm6.565-.86H10.19V.456h4.172V1.67zm-3.686 3.766c.068-.47.236-.842.502-1.117.266-.274.642-.412 1.124-.412.444 0 .816.13 1.118.388.298.261.466.64.5 1.14h-3.244zm3.318-2.746a2.9 2.9 0 011.243 1.067c.297.429.488.926.575 1.491.051.33.071.81.063 1.432H10.63c.031.723.28 1.231.754 1.522.287.18.632.271 1.038.271.427 0 .776-.11 1.044-.33.147-.12.277-.285.388-.496h1.922c-.051.426-.284.862-.697 1.303C14.434 9.65 13.53 10 12.37 10a3.91 3.91 0 01-2.535-.887c-.735-.591-1.1-1.555-1.1-2.887 0-1.25.331-2.21.992-2.877.662-.665 1.519-1 2.574-1 .627 0 1.19.113 1.693.339zM2.064 8.06v-2.6H4.46c.417.004.742.06.973.165.414.19.62.538.62 1.047 0 .6-.213 1.004-.64 1.218-.234.114-.564.171-.987.171H2.064zm0-6.367v2.152H4.43c.422 0 .765-.08 1.03-.242.263-.162.396-.448.396-.856 0-.456-.175-.755-.523-.902-.302-.101-.685-.152-1.151-.152h-2.12z"
                        ></path>
                    </defs>
                    <g  fillRule="evenodd" transform="translate(0 3)">
                        <mask id="nomj529qsb" fill="#fff">
                            <use xlinkHref="#39fp8pp8ka"></use>
                        </mask>
                        <use xlinkHref="#39fp8pp8ka"></use>
                        <g  mask="url(#nomj529qsb)">
                            <path d="M0-3h16v16H0z"></path>
                        </g>
                    </g>
                </svg>
            </a> */}
            <a href={links.fb}  className={theme ? "share__link share__link_" + theme : "share__link"} target="_blank" rel="noreferrer noopener">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                >
                    <defs>
                        <path
                            id="rzixnabnwa"
                            d="M4.964 16V8h2.209l.293-2.758H4.964l.004-1.38c0-.72.068-1.105 1.101-1.105H7.45V0H5.241C2.588 0 1.654 1.338 1.654 3.587v1.655H0V8h1.654v8h3.31z"
                        ></path>
                    </defs>
                    <g fillRule="evenodd" transform="translate(4)">
                        <mask id="96yaccrxjb" fill="#fff">
                            <use xlinkHref="#rzixnabnwa"></use>
                        </mask>
                        <use  fillRule="nonzero" xlinkHref="#rzixnabnwa"></use>
                        <g  mask="url(#96yaccrxjb)">
                            <path d="M-4 0h16v16H-4z"></path>
                        </g>
                    </g>
                </svg>
            </a>
            <a href={links.in}  className={theme ? "share__link share__link_" + theme : "share__link"} target="_blank" rel="noreferrer noopener">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                >
                    <defs>
                        <path
                            id="jd8o1pwtla"
                            d="M3.632 4.967v10.304H.202V4.967h3.43zm8.42-.242C14.307 4.725 16 6.198 16 9.363v5.908h-3.43V9.76c0-1.385-.496-2.33-1.737-2.33-.948 0-1.512.637-1.76 1.252-.09.22-.113.528-.113.836v5.755H5.53s.046-9.338 0-10.304h3.43v1.459c.455-.702 1.27-1.702 3.091-1.702zM1.94 0c1.173 0 1.896.77 1.918 1.78 0 .99-.745 1.78-1.94 1.78h-.022C.744 3.56 0 2.77 0 1.78 0 .77.767 0 1.94 0z"
                        ></path>
                    </defs>
                    <g  fillRule="evenodd" transform="translate(0 .364)">
                        <mask id="1xu2rcautb" fill="#fff">
                            <use xlinkHref="#jd8o1pwtla"></use>
                        </mask>
                        <use  fillRule="nonzero" xlinkHref="#jd8o1pwtla"></use>
                        <g  mask="url(#1xu2rcautb)">
                            <path d="M0-.364h16v16H0z"></path>
                        </g>
                    </g>
                </svg>
            </a>
            {/* <a href={links.twitter}  className={theme ? "share__link share__link_" + theme : "share__link"} target="_blank" rel="noreferrer noopener">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                >
                    <defs>
                        <path
                            id="7bshma77ba"
                            d="M7.769 3.517l.035.572-.578-.07C5.12 3.751 3.283 2.84 1.723 1.311L.96.553l-.197.56c-.416 1.249-.15 2.568.717 3.455.462.49.358.56-.44.268-.277-.093-.52-.163-.543-.128-.08.082.197 1.144.416 1.564.3.583.914 1.155 1.584 1.494l.567.268-.67.012c-.648 0-.671.012-.602.257.231.758 1.144 1.564 2.162 1.914l.717.245-.625.373a6.514 6.514 0 01-3.098.864c-.52.012-.948.058-.948.093 0 .117 1.41.77 2.231 1.027 2.463.76 5.388.432 7.584-.863 1.56-.922 3.122-2.755 3.85-4.529.393-.945.786-2.672.786-3.501 0-.537.035-.607.682-1.249.382-.374.74-.782.81-.899.115-.221.104-.221-.486-.023-.983.35-1.122.303-.636-.222.358-.373.786-1.05.786-1.249 0-.035-.173.024-.37.129-.208.116-.67.291-1.017.397l-.624.198-.567-.385c-.312-.21-.751-.444-.983-.514-.59-.163-1.49-.14-2.023.047-1.445.525-2.358 1.88-2.254 3.361z"
                        ></path>
                    </defs>
                    <g  fillRule="evenodd" transform="translate(0 1.389)">
                        <mask id="1iiecda7qb" fill="#fff">
                            <use xlinkHref="#7bshma77ba"></use>
                        </mask>
                        <use  fillRule="nonzero" xlinkHref="#7bshma77ba"></use>
                        <g  mask="url(#1iiecda7qb)">
                            <path d="M0-1.389h16v16H0z"></path>
                        </g>
                    </g>
                </svg>
            </a> */}
            {/* <a href="#"  className={theme ? "share__link share__link_" + theme : "share__link"}  target="_blank" rel="noreferrer noopener">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                >
                    <defs>
                        <path
                            id="mtq3ygpnaa"
                            d="M8-.001s5.003 0 6.25.343c.69.19 1.232.746 1.416 1.453.297 1.139.33 3.377.334 3.86v.188c-.004.483-.037 2.722-.334 3.86a2.032 2.032 0 01-1.415 1.453c-1.105.305-5.157.34-6.068.343h-.366c-.912-.004-4.964-.038-6.069-.343A2.034 2.034 0 01.333 9.704C0 8.423 0 5.749 0 5.749v-.01c0-.186.012-2.71.334-3.944A2.029 2.029 0 011.748.342C2.996 0 8 0 8 0zm-1.5 3.5v5l4-2.5-4-2.5z"
                        ></path>
                    </defs>
                    <g  fillRule="evenodd" transform="translate(0 2.25)">
                        <mask id="s4oc92e7wb" fill="#fff">
                            <use xlinkHref="#mtq3ygpnaa"></use>
                        </mask>
                        <use  fillRule="nonzero" xlinkHref="#mtq3ygpnaa"></use>
                        <g mask="url(#s4oc92e7wb)">
                            <path d="M0-2.25h16v16H0z"></path>
                        </g>
                    </g>
                </svg>
            </a> */}
            {/* <a href="#"  className={theme ? "share__link share__link_" + theme : "share__link"}  target="_blank" rel="noreferrer noopener">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                >
                    <defs>
                        <path
                            id="g31hghqixa"
                            d="M7.125 0l.357.002h1.306c1.896.003 2.242.015 3.156.057.952.043 1.468.202 1.812.336.456.177.78.388 1.122.73.342.341.553.667.73 1.122.134.344.293.86.336 1.812.04.876.053 1.23.057 2.926v2.033c-.004 1.695-.017 2.05-.057 2.926-.043.951-.202 1.468-.336 1.812-.177.455-.388.78-.73 1.12a3.005 3.005 0 01-1.122.73c-.343.135-.86.294-1.812.337C10.915 15.99 10.606 16 8 16s-2.915-.01-3.943-.057c-.952-.044-1.468-.203-1.813-.336a3.037 3.037 0 01-1.122-.73 3.025 3.025 0 01-.73-1.122c-.134-.344-.293-.86-.337-1.812-.037-.828-.05-1.19-.054-2.657L0 6.982c.003-1.696.016-2.05.055-2.926.044-.951.203-1.468.337-1.812.176-.455.388-.78.73-1.122a3.017 3.017 0 011.122-.73c.344-.135.861-.293 1.813-.337.9-.04 1.249-.053 3.068-.055zM8 2.991a5.011 5.011 0 100 10.023A5.011 5.011 0 108 2.991zm0 1.76a3.253 3.253 0 11.001 6.509A3.253 3.253 0 018 4.75zm5.21-3.128a1.171 1.171 0 10-.002 2.342 1.171 1.171 0 00.003-2.342z"
                        ></path>
                    </defs>
                    <g fillRule="evenodd">
                        <mask id="tggghz9bfb" fill="#fff">
                            <use xlinkHref="#g31hghqixa"></use>
                        </mask>
                        <use  fillRule="nonzero" xlinkHref="#g31hghqixa"></use>
                        <g mask="url(#tggghz9bfb)">
                            <path d="M0 0h16v16H0z"></path>
                        </g>
                    </g>
                </svg>
            </a> */}
        </div>
    )
}

const mapStateToProps = state =>({
    language: state.language
})

export default connect(mapStateToProps)(mediaLinks)


