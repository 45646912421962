import React from 'react'
import { useField } from "formik";

import style from "./style.scss"
import { Link } from 'react-router-dom';

function Checkbox({text, ...props}){

    const [field] = useField(props);

    return(
        <div className="checkbox">
            <label className="checkbox__label">
                <input className="checkbox__input" type="checkbox" name={props.name} {...field} {...props}/>
                <div className="checkbox__check"></div>
            </label>
            <Link className="checkbox__label-text" to='/privacy-policy' target="_blank">{text}</Link>
        </div>
    )
}

export default Checkbox