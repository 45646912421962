import React from 'react'
import styles from "./style.scss"
import {connect} from "react-redux"
import PrevButton from "../../CommonComponents/PrevButtonComponent/index"
import {changePage} from "../../../ac"
import CloseButton from '../../CommonComponents/CloseButtonComponent'
import { useHistory } from 'react-router-dom'

// const envURL = process.env.NODE_ENV === 'development' ?  process.env.React_App_API_DOMAIN : process.env.API_DOMAIN;

const newsTop = (props) => {

    const moveTo = section => {
        props.dispatch(changePage(section));
    };
    
    const {isMobile, banner, title, author, type, date} = props;

    const history = useHistory();

    const button = isMobile ? 
        (<CloseButton actionFunction={()=> history.push('/news')}/>
        ) : (
        <PrevButton
            title={"read more"}
            actionFunction={()=> history.push('/news')}
        />)

    const infoContent = (type && date) ?
        (
            <div className="news-top__info-part">
                <div className="news-top__info-text">
                    {type}
                </div>

                <div className="news-top__info-date">
                    {date}
                </div>
            </div>
        ) : null

    const authorContent = author ?
        (
            <div className="news-top__info-part news-top__info-part_author">
                <div className="news-top__author">
                    {
                        author.avatar && <img className="news-top__author-img" src={`${author.avatar.url}`} />
                    }
                </div>
                <div className="news-top__author-info">
                    <div className="news-top__info-text news-top__author-name">
                        {author.name}
                    </div>
                    <div className="news-top__author-position">
                        {author.position}
                    </div>
                </div>
            </div>
        ) : null


    return (
        <div className="news-top">
            <div className="news-top__img-wrapper">
                <img className="news-top__img" src={banner}/>
            </div>
            <div className="news-top__container">
                <div className="news-top__btn ">
                    {button}
                </div>
                <div className="news-top__content">
                    <div className="news-top__title">
                        {title}
                    </div>
                </div>
                <div className="news-top__info">
                    {authorContent}
                    {infoContent}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state =>({
    language: state.language,
    isMobile: state.isMobile
})

export default connect(mapStateToProps)(newsTop)