import { useEffect } from 'react';

export default function useOutsideAlerter(ref, callback) {
  useEffect(() => {
    
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback(event);
      }
    }
    document.addEventListener('touchend', handleClickOutside);
    return () => {
      document.removeEventListener('touchend', handleClickOutside);
    };
  }, [ref]);
}
