import language from "./language";
import page from "./currentPage";
import isMobile from "./isMobile";
import submitForm from "./submitForm";
import loadedResources from "./loadedResources";
import { combineReducers } from "redux";

export default combineReducers({
  language,
  page,
  isMobile,
  submitForm,
  loadedResources,
});
