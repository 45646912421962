import React, {useEffect, useState} from 'react'
import styles from "./style.scss"
import {connect} from "react-redux"
import img1 from '../../../img/game-1.jpg';
import img2 from '../../../img/game-2.jpg';
import Share from "../../CommonComponents/ShareComponent/index"

function createMarkup(article) {
    return {__html: article};
}

const newsArticle = ({article}) => {

    return(
            <div className="news-article">
                <div className="news-article__container">
                    <div dangerouslySetInnerHTML={createMarkup(article)}></div>
                    <div className="news-article__share">
                        <div className="news-article__share-text">Share</div>
                        <div className="news-article__share-links">
                            <Share/>
                        </div>
                    </div>
                </div>


            </div>
)

}

const mapStateToProps = state =>({
    language: state.language
})

export default connect(mapStateToProps)(newsArticle)