import React, {useState, useEffect, useRef} from 'react'
import styles from "./style.scss"
import {connect, useDispatch} from "react-redux"
import 'fullpage.js/vendors/scrolloverflow.min'
import ReactFullpage from '@fullpage/react-fullpage';
import { Route ,useHistory } from 'react-router-dom'

import HeaderComponent from "../CommonComponents/HeaderComponent/HeaderComponent"
import FooterComponent from "../CommonComponents/FooterComponent/FooterComponent"
import NewsSectionTop from "./NewsSectionTopComponent/NewsSectionTopComponent"
import NewsSectionListComponent from "./NewsSectionListComponent/NewsSectionListComponent"

import {loadResources} from '../../util';

import gilroyBold from '../../fonts/Gilroy/Gilroy-Bold.woff2';
import PTRootUIMedium from '../../fonts/PTRootUI/PTRootUI-Medium.woff2';
import LoaderLogo from '../CommonComponents/LoaderLogoComponent';

const newsSectionPage = ({loadedResources, isMobile, newsPages, mainNewsPageId}) => {

    const dispatch = useDispatch();

    useEffect(() => {
        const loadedNews = newsPages ? newsPages.map(newsPage => ['image', newsPage.preview.url]) : [];
        loadResources([
                ...loadedNews,
                ['font', gilroyBold,
                {name: 'Gilroy', style: 'normal', weight: 900}
                ],
                ['font', PTRootUIMedium,
                {name: 'PT Root UI', style: 'normal', weight: 500}
                ],
            ])
            .then(() => {
                dispatch({ type: "set-resources-loaded", payload: { loaded: true } });
            })

    }, []);

    const pageContent = isMobile ? (
        <>
            <HeaderComponent/>
            <NewsSectionTop mainNewsPageId={mainNewsPageId}/>
            {newsPages && <NewsSectionListComponent newsPages={newsPages}/>}
            <FooterComponent/>
        </>

    ) : (
        <div className={"page__container"}>
            <div className="section">
                <HeaderComponent/>
            </div>
            <div className="section fp-auto-height">
                <NewsSectionTop mainNewsPageId={mainNewsPageId}/>
                <div className="page__section">
                    {newsPages && <NewsSectionListComponent  newsPages={newsPages}/>}
                </div>

                <FooterComponent/>
            </div>
        </div>
    )

    return(
        <div className={"page"}>
            <LoaderLogo isLoaded={loadedResources.loaded} />
            {pageContent}
        </div>

    )

}

const mapStateToProps = state =>({
    language: state.language,
    isMobile: state.isMobile,
    loadedResources: state.loadedResources,
})

export default connect(mapStateToProps)(newsSectionPage)
