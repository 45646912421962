import React, { Component } from "react";
import styles from "./style.scss";
import { connect } from "react-redux";
import GamePage from "../GamePage/GamePage";
import gameBanner from "../../img/game-page/CR/banner.jpg";
import gameBannerMobile from "../../img/game-page/CR/banner-mobile.jpg";

import desktopIcon from "../../img/game-page/CR/laptop.svg";
import tabletIcon from "../../img/game-page/CR/ipad.svg";
import mobileIcon from "../../img/game-page/CR/phone.svg";
import deviceDesktop from "../../img/game-page/CR/device-desktop.jpg";
import deviceTablet from "../../img/game-page/CR/device-tablet.jpg";
import deviceMobile from "../../img/game-page/CR/device-mobile.jpg";

import slideDesktop1 from "../../img/game-page/CR/gallery-desktop/slide-1.jpg";
import slideDesktop2 from "../../img/game-page/CR/gallery-desktop/slide-2.jpg";
import slideDesktop3 from "../../img/game-page/CR/gallery-desktop/slide-3.jpg";

import slideTablet1 from "../../img/game-page/CR/gallery-tablet/slide-1.jpg";
import slideTablet2 from "../../img/game-page/CR/gallery-tablet/slide-2.jpg";
import slideTablet3 from "../../img/game-page/CR/gallery-tablet/slide-3.jpg";

import slideMobile1 from "../../img/game-page/CR/gallery-mobile/slide-1.jpg";
import slideMobile2 from "../../img/game-page/CR/gallery-mobile/slide-2.jpg";
import slideMobile3 from "../../img/game-page/CR/gallery-mobile/slide-3.jpg";

import feature1 from "../../img/game-page/CR/features/feature1.svg";
import feature2 from "../../img/game-page/CR/features/feature2.svg";
import feature3 from "../../img/game-page/CR/features/feature3.svg";

import partner1 from "../../img/game-partners/BETB2B.svg";
import partner3 from "../../img/game-partners/1win.svg";
import partner4 from "../../img/game-partners/pronet.svg";
import partner5 from "../../img/game-partners/infinGame.svg";
import partner6 from "../../img/game-partners/betconstruct.svg";
import partner7 from "../../img/game-partners/softswiss.svg";
import partner8 from "../../img/game-partners/parimatch.svg";
import partner9 from "../../img/game-partners/softgaming.svg";
import partner11 from "../../img/game-partners/top-play-gaming.svg";

import rules1 from "../../img/rules/cr/rules-1.png";
import rules2 from "../../img/rules/cr/rules-2.png";
import rules3 from "../../img/rules/cr/rules-3.png";
import rules4 from "../../img/rules/cr/rules-4.png";
import rules5 from "../../img/rules/cr/rules-5.png";

import { loadResources } from "../../util";
import logo from "../../img/logo-header-def.svg";
import gilroyBold from "../../fonts/Gilroy/Gilroy-Bold.woff2";
import PTRootUIMedium from "../../fonts/PTRootUI/PTRootUI-Medium.woff2";
import LoaderLogo from "../CommonComponents/LoaderLogoComponent";

const partners = [
  {
    title: "1win",
    date: "2023",
    logo: partner3,
  },
  {
    title: "SoftSwiss",
    date: "2023",
    logo: partner7,
  },
  {
    title: "PariMatch",
    date: "2023",
    logo: partner8,
  },
  {
    title: "SoftGamings",
    date: "2023",
    logo: partner9,
  },
  {
    title: "BetB2B",
    date: "2023",
    logo: partner1,
  },
  {
    title: "Betconstruct",
    date: "2023",
    logo: partner6,
  },
  {
    title: "ProNetGaming",
    date: "2023",
    logo: partner4,
  },
  {
    title: "infingame",
    date: "2023",
    logo: partner5,
  },
  {
    title: "TopPlayGaming",
    date: "2023",
    logo: partner11,
  },
];

const rules = (
  <div>
    <h1>Game</h1>
    <p>
      Cocktail Roulette is a bonus game in the game show genre, which is based
      on the rules of classic European roulette. The objective of the game is to
      guess which number will come up on the roulette wheel. Additionally, in
      each round of the game, between 3 to 5 bonus numbers are randomly
      selected, some of which, when landed upon, will multiply the payout for a
      win several times over. And one of them triggers an extra bonus game where
      3 more winning numbers will be determined, each of which can also turn out
      to be a bonus!
    </p>
    <p>
      The RTP (Return to Player) of the game is 97.3%, just like in the classic
      European roulette.
    </p>
    <h1>Game Process</h1>
    <p>
      The round starts with betting. At this stage, you can place one or
      multiple bets on the main layout or the Track Field. You have access to
      all the classic roulette bets. However, please keep in mind that in the
      bonus game, only bets placed on a single number, also known as Straight Up
      bets, will participate. Winnings from other bets will be calculated based
      on the regular odds.
    </p>
    <h1>Blitz Round</h1>
    <p>
      When betting is finished, the game randomly selects 3 to 5 numbers on the
      roulette wheel, to each of which one of the possible bonuses is assigned.
      The game features two types of bonuses: Bonus Multiplier and Shaker
      Sector.
    </p>
    <p>
      In each draw, one of the numbers is chosen as the Shaker Sector. When the
      roulette wheel lands on this number, an additional draw is triggered in
      the game, during which 3 more winning numbers are determined using a
      lottery machine. Furthermore, the number on the roulette wheel, on which
      the ball is landed, will also be considered a winning number.
    </p>
    <p>
      The remaining bonus numbers are randomly assigned a Bonus Multiplier,
      which can be 50x, 100x, 150x, 250x, or 500x. This multiplier determines
      the payout amount when one of these numbers comes up on the roulette
      wheel.
    </p>
    <p>
      The selected bonus numbers are displayed on the screens in the broadcast
      window. Additionally, information about the bonus sectors is duplicated in
      the game interface within the main layout and on special Bonus Tickets:
    </p>
    <img className="CR-rules-img" src={rules1} />
    <div className="CR-rules-img-mobile">
      <img  src={rules2} />
    </div>
    <h1>Draw</h1>
    <p>
      The draw begins with the spinning of the roulette wheel. It is performed
      simultaneously with the determination of the bonus sectors. After the
      bonuses are chosen, the roulette wheel spins a few more times, and then
      the ball lands in one of the pockets on the roulette wheel. The number of
      this pocket is considered to be the winning number.
    </p>
    <p>There are several possible outcomes:</p>
    <ol>
      <li>
        The ball lands in a pocket with no bonuses assigned. In this case, the
        winners receive their payouts based on the regular odds, and the draw
        comes to an end. 
      </li>
      <li>
        The ball lands in a pocket with an assigned Bonus Multiplier. In this
        scenario, the winners receive their payouts according to the bonus
        multiplier assigned to the number that came up. The draw then comes to
        an end. 
      </li>
      <li>
        The ball lands in a pocket chosen as the Shaker Sector. In this case,
        the number that comes up is considered to be the winning number, and an
        Additional Draw is initiated in the game.
      </li>
    </ol>
    <h1>Additional Draw</h1>
    <p>
      The Additional Draw is initiated when the ball on the roulette wheel lands
      in the pocket chosen as the Shaker Sector. The Additional Draw begins with
      the activation of a lottery machine containing 37 balls, each marked with
      a number corresponding to one of the numbers on the roulette wheel.
    </p>
    <p>
      During the Additional Draw, 3 balls with numbers are drawn from the
      lottery machine, and each of these numbers is considered to be the winning
      number in the given round. In addition, the Bonus Multipliers selected
      during the Blitz Round remain in effect. This means that the relevant
      bonus multiplier can also be applied to the number drawn from the lottery
      machine.
    </p>
    <p>
      If you placed bets on multiple numbers that turned out to be the winning
      numbers in this draw, the payouts for these wins are summed up. If a ball
      with the number that has previously come up on the roulette wheel is drawn
      from the lottery machine, the payout for that number will be doubled,
      making it 49:1 instead of 24:1.
    </p>
    <p>
      Once all the winning numbers are determined, the winners receive their
      payouts, and the draw comes to an end.
    </p>
    <p>Please note:</p>
    <ul>
      <li>
        In the Additional Draw, only bets made on a single number (Straight Up)
        participate. Other bets will be cleared from the layout at the beginning
        of the Additional Draw.
      </li>
      <li>
        In one game, there can only be 1 Additional Draw. A repeated landing on
        the Shaker Sector will not trigger another bonus game.
      </li>
    </ul>
    <h1>Bets</h1>
    <p>
      In the course of gambling, you have access to all the classic roulette
      bets:
    </p>
    <div className="CR-table-wrapper">
      <table className="CR-table">
        <thead>
          <tr>
            <th>Designation</th>
            <th width={"137px"}>Payout</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Straight</td>
            <td>
              24-499:1
              <br />
              <br />
              *When the winning number turns out to be a bonus number.
            </td>
            <td>
              A bet on any single number, including “0”, is placed directly on
              this number.
            </td>
          </tr>
          <tr>
            <td>Split</td>
            <td>17:1</td>
            <td>
              A bet on two neighboring numbers is placed on the line between
              these two numbers. This bet can also be placed on 0 and 1, 0 and
              2, 0 and 3.
            </td>
          </tr>
          <tr>
            <td>Street</td>
            <td>11:1</td>
            <td>
              A bet on three numbers in a row is placed on the line at the end
              of the row. There are other options of its placement, including 0,
              1, 2; 0, 2, 3.
            </td>
          </tr>
          <tr>
            <td>Corner</td>
            <td>8:1</td>
            <td>
              A bet on a group of 4 numbers is placed at the intersection point
              of the four numbers. This bet can also be placed at the
              intersection point of 0, 1, 2 and 3.
            </td>
          </tr>
          <tr>
            <td>Six Line (Double Street)</td>
            <td>5:1</td>
            <td>
              A bet on a group of six numbers (or two rows of three numbers
              each) is placed at the end of two rows on the boundary between
              them.
            </td>
          </tr>
          <tr>
            <td>Column</td>
            <td>2:1</td>
            <td>
              A bet that covers an entire column is placed in cell “2:1” at the
              end of the column.
            </td>
          </tr>
          <tr>
            <td>Dozen</td>
            <td>2:1</td>
            <td>
              A bet on a group of 12 numbers can be placed in the first dozen
              cell (covering numbers 1 to 12), the second dozen cell (covering
              numbers 13 to 24), or the third dozen cell (covering numbers 25 to
              36).
            </td>
          </tr>
          <tr>
            <td>Low (1–18)</td>
            <td>1:1</td>
            <td>
              A bet that covers all the low numbers (1–18) is placed in field
              “1–18”.
            </td>
          </tr>
          <tr>
            <td>High (19–36)</td>
            <td>1:1</td>
            <td>
              A bet that covers all the high numbers (19–36) is placed in field
              “19–36”.
            </td>
          </tr>
          <tr>
            <td>Even</td>
            <td>1:1</td>
            <td>
              A bet that covers all the even numbers on the layout is placed in
              the “Even” cell.
            </td>
          </tr>
          <tr>
            <td>Odd</td>
            <td>1:1</td>
            <td>
              A bet that covers all the odd numbers of the layout is placed in
              the “Odd” cell.
            </td>
          </tr>
          <tr>
            <td>Red</td>
            <td>1:1</td>
            <td>
              A bet that covers all the red numbers on the layout is placed in
              the “Red” cell.
            </td>
          </tr>
          <tr>
            <td>Black</td>
            <td>1:1</td>
            <td>
              A bet that covers all the red numbers on the layout is placed in
              the “Black” cell.
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <h1>Track Field</h1>
    <p>
      The Track Field is a field for quick bets on combinations that are absent
      on the main layout. Each bet on the Track Field corresponds to a set of
      numbers on the main layout, and these bets are placed on the corresponding
      numbers on the main layout. The Track Field is oval in shape: The numbers
      from 0 to 36 are positioned along its contour in the same sequence as they
      appear on the roulette wheel. Inside the oval, there are sectors that are
      not present on the main layout, and these are commonly referred to as
      French bets. French bets are combined bets that cover multiple numbers
      within a sector of the roulette wheel. There are 5 types of bets on the
      Track Field:
    </p>
    <img className="CR-rules-img" src={rules3} />
    <h1>Bet on Neighbors</h1>
    <p>
      A bet on 5 consecutive numbers positioned on the Track Field. To place
      this bet, position a chip with the chosen denomination above the selected
      number. When a chip is placed above a number, 2 more numbers to the left
      and right of the selected number are highlighted, forming a chain of 5
      numbers. When the chain of 5 numbers is selected, the bet is placed on the
      main layout in cells with the corresponding numbers, using chips with the
      chosen denomination. In one game, you can place multiple bets of this
      type.
    </p>
    <img className="CR-rules-img" src={rules4} />
    <h1>Bets on Internal Sectors</h1>
    <p>
      These bets are positioned inside the oval. To place a bet, position a chip
      on the combination within the Track Field. When a chip is placed on any of
      the combinations, all the numbers within that combination are highlighted
      on the main layout. When a combination is selected, chips with the chosen
      denomination are placed on the main layout in cells with the corresponding
      numbers.
    </p>
    <img className="CR-rules-img" src={rules5} />
    <div className="CR-table-wrapper">
      <table className="CR-table">
        <thead>
          <tr>
            <th>Designation</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Voisins de Zero</td>
            <td>
              This is a bet with 9 chips covering 17 numbers.
              <br />
              Corner: 25/26/28/29 (2 chips)
              <br />
              Street: 0/2/3 (2 chips)
              <br />
              Split: 4/7, 12/15, 18/21, 19/22, 32/35 (5 chips)
            </td>
          </tr>
          <tr>
            <td>Tier</td>
            <td>
              This is a bet with 6 chips covering a sector of 12 numbers:
              <br />
              Split: 5/8, 10/11, 13/16, 23/24, 27/30, 33/36 (6 chips)
            </td>
          </tr>
          <tr>
            <td>Orphelins</td>
            <td>
              A bet with 5 chips covering 8 numbers, spanning 2 sectors of the
              roulette wheel:
              <br />
              Straight: 1 (1 chip)
              <br />
              Split: 6/9, 14/17, 17/20, 31/34 (4 chips)
            </td>
          </tr>
          <tr>
            <td>Zero Spiel</td>
            <td>
              A bet with 4 chips covering a sector of 7 numbers:
              <br />
              Straight: 26 (1 chip)
              <br />
              Split: 0/3, 12/15, 32/35 (3 chips)
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <h1>History</h1>
    <p>
      The results of previous draws with your participation are available in the
      “History” tab. In this tab, you will find a list of rounds in which you
      placed bets, as well as a detailed history for each round, including the
      game results, all bets made, and any winnings.
    </p>
    <h1>Game Issues</h1>
    <p>
      In case of any errors or technical issues in the game, the round is
      temporarily paused. The host notifies the players and technical
      specialists of the issue. If the support team is able to promptly
      eliminate the issue, the round continues as usual. However, if the error
      cannot be fixed, all the bets for the relevant round are canceled, and the
      players receive their wagers back.
    </p>

    <h1>Connectivity Issues</h1>
    <p>
      If a player is disconnected from the game due to any connectivity issues
      on their end, the bets they placed are considered valid and participate in
      the draw in the absence of the player. Following the connection
      restoration, the player can view the results of the respective round in
      the “History” tab.
    </p>
    <h1>Good luck!</h1>
  </div>
);

class GamePageBJ extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    loadResources([
      ["image", logo],
      ["image", gameBanner],
      ["image", gameBannerMobile],
      ["font", gilroyBold, { name: "Gilroy", style: "normal", weight: 900 }],
      [
        "font",
        PTRootUIMedium,
        { name: "PT Root UI", style: "normal", weight: 500 },
      ],
    ]).then(() => {
      this.props.dispatch({
        type: "set-resources-loaded",
        payload: { loaded: true },
      });
    });
  }

  render() {
    return (
      <>
        <LoaderLogo isLoaded={this.props.loadedResources.loaded} />
        <GamePage
          banner={gameBanner}
          bannerMobile={gameBannerMobile}
          theme="game-cr"
          name="COCKTAIL ROULETTE"
          text="Let your players experience the perfect blend of sophistication and fun in the world of live casino gaming with our latest creation."
          desktopIcon={desktopIcon}
          tabletIcon={tabletIcon}
          mobileIcon={mobileIcon}
          deviceDesktop={deviceDesktop}
          deviceTablet={deviceTablet}
          deviceMobile={deviceMobile}
          slidesDesktop={[slideDesktop1, slideDesktop2, slideDesktop3]}
          slidesTablet={[slideTablet1, slideTablet2, slideTablet3]}
          slidesMobile={[slideMobile1, slideMobile2, slideMobile3]}
          features={[
            {
              icon: feature1,
              text: "A Taste of Elegance",
            },
            {
              icon: feature2,
              text: "Shake Up Your Winnings",
            },
            {
              icon: feature3,
              text: "Multiply Your Luck",
            },
          ]}
          rules={rules}
          partners={partners}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.language,
  loadedResources: state.loadedResources,
});

export default connect(mapStateToProps)(GamePageBJ);
