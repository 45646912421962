import React, {Component} from 'react'
import styles from "./style.scss"
import {connect} from "react-redux"

class GamesHeaderComponent extends Component{

    render(){

        return(
            <div className={"games-header"}>
                <div className="games-header__contain">
                    <div className="games-header__title">
                        ATMOSFERA IS <span className="games-header__separator"></span> LIVE GAMES
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state =>({
    language: state.language
})

export default connect(mapStateToProps)(GamesHeaderComponent)