import React, {useEffect, useRef, useState} from 'react';
import { useSelector } from 'react-redux';
import NativeButton from './native-button';
import './style.scss';

const progressButton =  ({clickFunction, isDisabled, type, isSubmitSuccess}) => {

  const buttonClasses = {
    wait: "wait-action",
    error: "error",
    success: "success",
    loading: "loading"
  }
  
  const [buttonStatus, setBtnStatus] = useState(buttonClasses.wait);

  const formSubmit = useSelector((state) => state.submitForm.partnershipForm);

  useEffect(() => {
    const smRes = formSubmit.submitResult;
    const keys = {
      wait: !formSubmit.isSendingForm && smRes === null,
      success: smRes && smRes.status,
      error: smRes && smRes.err && !smRes.status,
      loading: formSubmit.isSendingForm && smRes === null,
    }
    const key = Object.keys(keys).find(k => keys[k] === true);
    if (key) {
      setBtnStatus(buttonClasses[key]);
      return;
    }
    setBtnStatus(buttonClasses.wait);
  }, [formSubmit]);

  return (
    <div className={`progress-button elastic ${buttonStatus}`}>
      <button
        onClick={clickFunction}
        disabled={isDisabled}
        type={type === "submit" ? "submit" : "button"}
        className="btn-loading">
        <span>
        Send
        </span>
      </button>

      <span className="loader"></span>
      <span className="error">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.414 5.586 12 9.17l3.586-3.585a2 2 0 0 1 2.945 2.701l-.117.127L14.83 12l3.585 3.586a2 2 0 0 1-2.828 2.828L12 14.83l-3.586 3.585a2 2 0 0 1-2.945-2.701l.117-.127L9.17 12 5.586 8.414a2 2 0 1 1 2.828-2.828z"
            fill="#FFF"
            fillRule="nonzero"
          />
        </svg>
      </span>
      <span className="success">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.586 5.586a2 2 0 0 1 2.945 2.701l-.117.127-9 9a2 2 0 0 1-2.701.117l-.127-.117-5-5A2 2 0 0 1 6.287 9.47l.127.117L10 13.17l7.586-7.585z"
            fill="#FFF"
            fillRule="nonzero"
          />
        </svg>
      </span>
    </div>
  );
};

export default progressButton;
