import React, {Component} from 'react'
import styles from "./style.scss"
import {connect} from "react-redux"
import ContactsFormComponent from "./ContactsFormComponent/ContactsFormComponent"
import MediaLinks from "../../CommonComponents/MediaLinksComponent/index"


class ContactsComponent extends Component{

    render(){

        return(
            <div className="contacts">
                <div className="contacts__container">
                    <div className="contacts__part contacts__part_left">
                        <div className="contacts__title">Stay tuned</div>
                        <div className="contacts__items">
                            <div className="contacts__item">
                                <a className="contacts__item-link" href="mailto:info@atmosfera.is">info@atmosfera.is</a>
                                <div className="contacts__item-info">General email address</div>
                            </div>
                            <div className="contacts__item">
                                <a className="contacts__item-link" href="mailto:partner@atmosfera.is">partner@atmosfera.is</a>
                                <div className="contacts__item-info">Business development team regarding partnership</div>
                            </div>
                            <div className="contacts__item">
                                <a className="contacts__item-link" href="mailto:pr@atmosfera.is">pr@atmosfera.is</a>
                                <div className="contacts__item-info">PR & media inquiries</div>
                            </div>
                            <div className="contacts__item">
                                <a className="contacts__item-link" href="mailto:hr@atmosfera.is">hr@atmosfera.is</a>
                                <div className="contacts__item-info">Human Resources office</div>
                            </div>
                        </div>
                        <div className="contacts__share">
                            <MediaLinks />
                        </div>
                    </div>
                    <div className="contacts__part contacts__part_right">
                        <div className="contacts__title">Say hello</div>
                        <div className="contacts__form">
                            <ContactsFormComponent/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state =>({
    language: state.language
})

export default connect(mapStateToProps)(ContactsComponent)