import React from 'react';
import { connect } from "react-redux";
import HeaderComponent from "../CommonComponents/HeaderComponent/HeaderComponent";
import FooterComponent from "../CommonComponents/FooterComponent/FooterComponent";

const surveysPage = props => {
    const { isMobile } = props;
    const pageContent = isMobile ? (
        <>
            <HeaderComponent />
            <iframe style={{marginTop: '8rem'}} src="https://docs.google.com/forms/d/e/1FAIpQLSdMEKruLZ2iYZ_DtBSQaqMR1fV6795Nu76hXJNLzQfF0AFnHg/viewform?embedded=true%22"  width="100%" height="600" frameborder="0" marginheight="0" marginwidth="0">
                Загрузка…
            </iframe>
            <FooterComponent />
        </>
        ) : (
        <div className="page__container">
            <div className="section">
            <HeaderComponent />
            </div>
            <iframe style={{marginTop: '8rem'}} src="https://docs.google.com/forms/d/e/1FAIpQLSdMEKruLZ2iYZ_DtBSQaqMR1fV6795Nu76hXJNLzQfF0AFnHg/viewform?embedded=true%22"  width="100%" height="600" frameborder="0" marginheight="0" marginwidth="0">
                Загрузка…
            </iframe>
            <div className="section">
                <FooterComponent />
            </div>
        </div>
    );
    return pageContent;
};

const mapStateToProps = (state) => ({
    isMobile: state.isMobile,
});

export default connect(mapStateToProps)(surveysPage);