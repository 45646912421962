import React from 'react'
import styles from "./style.scss"
import {connect} from "react-redux"
import BecomeItemComponent from "./BecomeItemComponent/BecomeItemComponent"
import partner1 from "../../../img/partner-become/partner-1.svg"
import partner2 from "../../../img/partner-become/partner-2.svg"
import partner3 from "../../../img/partner-become/partner-3.svg"
import partner4 from "../../../img/partner-become/partner-4.svg"
import partner5 from "../../../img/partner-become/partner-5.svg"
import partner6 from "../../../img/partner-become/partner-6.svg"
import partnerDone from "../../../img/done-2.svg"

const partnerBecomeComponent = (props) => {

    
    const items = [{
        number: 1,
        title: "Partner Application",
        text: "Fill in the registration form below and let’s continue from there",
        icon: partner1,
        backSide: {
            title: 'Thank you!',
            text: "You can expect a reply in at most 24 hours.",
            icon: partnerDone,
        }
    },
    {
        number: 2,
        title: "Contracting Process",
        text: "Negotiation and clarification of all questions",
        icon: partner2
    },
    {
        number: 3,
        title: "Games Integration",
        text: "API integration and its testing",
        icon: partner3
    },
    {
        number: 4,
        title: "Marketing and Product Placement",
        text: "Discussion and agreement of games positioning and their promo",
        icon: partner4
    },
    {
        number: 5,
        title: "Evaluation and Review ",
        text: "Detailed games test on all devices",
        icon: partner5
    },
    {
        number: 6,
        title: "Live",
        text: "Final test and games opening to all players",
        icon: partner6
    }
]

    return(
        <div className="partner-become">
            <div className="partner-become__container">
                <div className="partner-become__title">How to become a partner</div>
                <div className="partner-become__items">
                    {items.map(item => (
                        <BecomeItemComponent
                            key={item.number}
                            number={props.isMobile ? `0${item.number}` : item.number}
                            title={item.title}
                            text={item.text}
                            icon={item.icon}
                            backSide={item.backSide}
                        />
                    ))}
                </div>
            </div>

        </div>
    )
}

const mapStateToProps = state =>({
    language: state.language,
    isMobile: state.isMobile
})

export default connect(mapStateToProps)(partnerBecomeComponent)