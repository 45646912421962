import React from 'react'
import styles from "./style.scss"
import {connect} from "react-redux"
import Button from "../../../CommonComponents/ButtonComponent/index"
import {changePage} from "../../../../ac"


const itemListComponent = (props) => {

    const moveTo = section =>{
        props.dispatch(changePage(section))
    };
    const {title, text, date, bg, logo, status, isMobile, link} = props;

    let infoBlock = (!isMobile &&  status === 'ok') ?  (
        <div className="item-list__info" onClick={() => moveTo(`games/${link}`)} >
            <div className="item-list__logo">
                <img className="item-list__logo-img" src={logo}/>
            </div>
            <div className="item-list__btns">
                <div className="item-list__btn">
                    <Button
                        mod={"type-1"}
                        title={"More details"}
                    />
                </div>
            </div>
            <div className="item-list__date">{date}</div>
        </div>
    ) : null;

    let mobileLink = isMobile && status === 'ok' ? {onClick: () => moveTo(`games/${link}`)} : null;


    return(
        <a className={"item-list"  +  (status === 'soon' ? " item-list__soon" : "")} {...mobileLink}>
            <img className="item-list__img" src={bg}/>
            <div className={"item-list__content"} >
                <div className="item-list__title">{title}</div>
                <div className="item-list__text">
                    <span>{text}</span>
                    <span className="item-list__text__year">{date}</span>
                </div>
                {status === 'soon' ? <span className="item-list__status">coming soon</span> : null}
            </div>
            {infoBlock}
        </a>
    )
}

const mapStateToProps = state =>({
    language: state.language,
    isMobile: state.isMobile
})

export default connect(mapStateToProps)(itemListComponent)