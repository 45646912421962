const loadImage = src => new Promise((resolve, reject) => {
  const image = new Image();
  image.onload = resolve;
  image.onerror = reject;
  image.src = src;
});

const loadVideo = src => new Promise(resolve => {
  const video = document.createElement('video');
  const onCanPlayThrough = () => {
    resolve();
    video.removeEventListener('canplaythrough', onCanPlayThrough);
  };
  video.addEventListener('canplaythrough', onCanPlayThrough);
  video.src = src;
  video.load();
});

const loadFont = async (src, {name, ...options}) => {
  const fontFace = new FontFace(name, `url(${src})`, options);
  document.fonts.add(await fontFace.load());
};

export const loadResources = resources => {
  return Promise.all(
  resources.map(([type, src, options]) => {
    
    if (type === 'image') {
      return loadImage(src, options);
    } else if (type === 'font') {
      return loadFont(src, options);
    }
  })
)
}

export const atLeastOneNonSpaceChar = inputValue =>
  !!(inputValue && inputValue.match(/[^ ]/))

export const isTouch = () =>
    'ontouchstart' in window || navigator.msMaxTouchPoints > 0;

export const url = process.env.NODE_ENV === 'development' ?  `${process.env.React_App_API_DOMAIN}` : `${window.location.protocol}//cms.${window.location.hostname}`;