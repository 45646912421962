import React from 'react';
import {connect} from "react-redux"
import { Link } from 'react-router-dom';
import FooterComponent from '../CommonComponents/FooterComponent/FooterComponent';
import HeaderComponent from '../CommonComponents/HeaderComponent/HeaderComponent';
import {ReactComponent as Page404SVG} from '../../img/404.svg'
import styles from './style.scss'

const pageNotFound = (props) => {
    return (
        <div  className="page-404">
            <HeaderComponent />
            <div className="page-404-wrapper">
                <div className="page-404-image"><Page404SVG /></div>
                <h2 className="page-404-title">oops! Page not found</h2>
                <p className="page-404-text">Try to go back to the home page <Link to="/" className="page-404-link">Home</Link></p>
            </div>
            <FooterComponent />
        </div>
    );
};

const mapStateToProps = state =>({
    language: state.language,
    isMobile: state.isMobile
})

export default connect(mapStateToProps)(pageNotFound)