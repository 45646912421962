import React from 'react'
import "./style.scss"
import {connect} from "react-redux"
function Close(props){
        const {closeFunction, theme} = props;

        return(
            <div onClick={closeFunction}
                className={theme ? "close close_" + theme : "close"}>
                <div className={theme ? "close__icon close__icon_" + theme : "close__icon"}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 23 23"
                    >
                        <path
                            fillRule="nonzero"
                            d="M18.266 4.734c.519.518.559 1.333.12 1.897l-.12.135-4.734 4.733 4.734 4.735c.562.56.562 1.471 0 2.032a1.436 1.436 0 01-1.897.12l-.135-.12-4.735-4.734-4.733 4.734a1.435 1.435 0 01-2.032 0 1.436 1.436 0 01-.12-1.897l.12-.135 4.733-4.735-4.733-4.733a1.435 1.435 0 010-2.032 1.436 1.436 0 011.897-.12l.135.12L11.5 9.467l4.735-4.733a1.435 1.435 0 012.032 0z"
                        ></path>
                    </svg>
                </div>
            </div>
        )
}

const mapStateToProps = state =>({
    language: state.language
})

export default connect(mapStateToProps)(Close)