import React, { useEffect, useState } from "react";
import styles from "./style.scss";
import { connect } from "react-redux";
import "fullpage.js/vendors/scrolloverflow.min";
import ReactFullpage from "@fullpage/react-fullpage";
import HeaderComponent from "../CommonComponents/HeaderComponent/HeaderComponent";
import FooterComponent from "../CommonComponents/FooterComponent/FooterComponent";
import CompanyTopComponent from "../CompanyPage/CompanyTopComponent/CompanyTopComponent";
import CompanyMissionComponent from "../CompanyPage/CompanyMissonComponent/CompanyMissonComponent";
import CompanyCareersComponent from "../CompanyPage/CompanyCareersComponent/CompanyCareersComponent";
import FeaturesComponent from "../HomePage/FeaturesComponent/FeaturesComponent";
import LoaderLogo from "../CommonComponents/LoaderLogoComponent";

const companyPage = (props) => {
  const [scrollIndex, setScrollIndex] = useState(1);
  const [destinationIndex, setDestinationIndex] = useState(0);
  const [data, setData] = useState([]);
  const [countVacansy, setCountVacansy] = useState(0);

  const { isMobile, theme } = props;

  const url =
    process.env.NODE_ENV === "development"
      ? process.env.React_App_API_DOMAIN
      : `${window.location.protocol}//cms.${window.location.hostname}`;

  useEffect(() => {
    fetch(`${url}/vacancies`)
      .then((response) => response.json())
      .then((data) => setData(data));
  }, []);

  useEffect(() => {
    const count = data.reduce((prevVal, curr) => {
      return (prevVal += curr.list.length);
    }, 0);

    setCountVacansy(count);
  }, [data]);

  return (
    <div className={"page"}>
      {isMobile ? (
        <>
          <HeaderComponent />
          <CompanyTopComponent vacancies={countVacansy} />
          <CompanyMissionComponent />
          <FeaturesComponent classname="companyPage" />
          <CompanyCareersComponent
            data={data}
            isMobile={isMobile}
            theme={theme}
          />
          <FooterComponent />
        </>
      ) : (
        <div className={"page__container"}>
          <div className="section">
            <HeaderComponent destinationIndex={destinationIndex} />
          </div>
          <div className="section fp-auto-height">
            <div className="section fp-auto-height">
              <CompanyTopComponent vacancies={countVacansy} />
              <CompanyMissionComponent />
              <FeaturesComponent classname="companyPage" />
              <CompanyCareersComponent data={data} />
              <FooterComponent />
            </div>
          </div>
        </div>
      )}
      <LoaderLogo />
    </div>
  );
};

const mapStateToProps = (state) => ({
  language: state.language,
  isMobile: state.isMobile,
});

export default connect(mapStateToProps)(companyPage);
