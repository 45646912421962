import React from 'react'
import "./style.scss"
import {connect} from "react-redux"
import svg1 from '../../../img/features-item/features-item-1.svg';
import svg2 from '../../../img/features-item/features-item-2.svg';
import svg3 from '../../../img/features-item/features-item-3.svg';
import svg4 from '../../../img/features-item/features-item-4.svg';


function FeaturesComponent({classname}){

    let featursData = [
        {
            title: 'Unique games',
            img: svg1,
            items: [
                'Modern design','Fair play','High win rates'
            ]
        },
        {
            title: 'Innovative Studio',
            img: svg2,
            items: [
                'Own TV host academy','Control system of quality stream','Own certified gaming machines'
            ] 
        },
        {
            title: 'Fast&Easy Integration',
            img: svg3,
            items: [
                '14 days from request to launch','Constant assistance'
            ]
        },
        {
            title: 'Reliable Partner',
            img: svg4,
            items: [
                'Favorable terms','Data transparency','Profit'
            ]
        }
    ]

        return(
            <div className={`features ${classname}`}>
                {featursData.map((feature, index)=> {
                    return (
                        <div className="features__item" key={feature.title[0] + index}>
                            <img src={feature.img} className="features__img" alt=""/>
                            <div className="features__title">{feature.title}</div>
                            <ul className="features__list">
                                {feature.items.map((item, index) => (<li className="features__list-item" key={item[0] + index}>{item}</li>))}
                            </ul>
                        </div>
                    )
                })}
            </div>
        )
}

const mapStateToProps = state =>({
    language: state.language
})

export default connect(mapStateToProps)(FeaturesComponent)
