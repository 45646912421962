import React, {useRef, useState} from 'react'
import styles from "./style.scss"
import {connect} from "react-redux"
import Swiper from 'react-id-swiper';
import { SwiperSlide } from 'swiper/react';
import SwiperCore, {Pagination} from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';



import Arrow from "../../../CommonComponents/ArrowComponent/index"

import {changePage} from "../../../../ac";
import isMobile from '../../../../reducers/isMobile';


function GameDevicesSlider(props){
        const refSwiper = useRef(null);

        const moveTo = section =>{
            props.dispatch(changePage(section))
        };

        const [slidesState, slidesWatch] = useState({
            prev: 'disabled',
            next: 'active'
        });


        const {theme, slides, isMobile} = props;


        return(
        <div className={"game-devices-slider game-devices-slider_" + theme}>
            <div className={"game-devices-slider__arrow game-devices-slider__arrow_next" }>
                <Arrow
                    sliderFunction={() => {
                        refSwiper.current.swiper.slideNext()
                    }}
                    isDisabled={slidesState.next === 'disabled'}
                    direction="next"
                    theme={theme}
                    size={isMobile ? 'small' : null}

                />
            </div>
            <div className={"game-devices-slider__arrow game-devices-slider__arrow_prew"}>
                <Arrow
                    sliderFunction={() => {
                        refSwiper.current.swiper.slidePrev()
                    }}
                    isDisabled={slidesState.prev === 'disabled'}
                    direction="prev"
                    theme={theme}
                    size={isMobile ? 'small' : null}
                />
            </div>
            <div className={"game-devices-slider__container"}>
                <Swiper
                    ref={refSwiper}
                    pagination={{ clickable: true, el: '.game-devices-slider__pagination', bulletClass: 'game-devices-slider__pagination-bullet', bulletActiveClass: 'game-devices-slider__pagination-bullet_active',}}
                    on={{
                        slideChange: ({activeIndex, imagesLoaded}) =>{
                            slidesWatch({
                                prev: activeIndex === 0 ? 'disabled': 'active',
                                next: (imagesLoaded - activeIndex === 1) ? 'disabled': 'active'
                            })
                        }
                    }}
                    theme={theme}
                >
                    {slides.map((slide, index) => (
                        <SwiperSlide key={index}>
                            <div className="game-devices-slider__slide">
                                <img className="game-devices-slider__img" src={slide}/>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
        )
}

const mapStateToProps = state =>({
    language: state.language,
    isMobile: state.isMobile
})
SwiperCore.use([Pagination]);


export default connect(mapStateToProps)(GameDevicesSlider)