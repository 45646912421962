import React, { Component } from 'react';
import styles from './style.scss';
import { connect } from 'react-redux';
import GamePage from '../GamePage/GamePage';
import gameBanner from '../../img/game-page/Ar/banner.jpg';
import desktopIcon from '../../img/game-page/ler/laptop.svg';
import tabletIcon from '../../img/game-page/ler/ipad.svg';
import mobileIcon from '../../img/game-page/ler/phone.svg';
import deviceDesktop from '../../img/game-page/Ar/device-desktop.png';
import deviceTablet from '../../img/game-page/Ar/device-tablet.png';
import deviceMobile from '../../img/game-page/Ar/device-mobile.png';

import slideDesktop1 from "../../img/game-page/Ar/gallery-desktop/slide-1.jpg";
import slideDesktop2 from "../../img/game-page/Ar/gallery-desktop/slide-2.jpg";
import slideDesktop3 from "../../img/game-page/Ar/gallery-desktop/slide-3.jpg";

import slideTablet1 from "../../img/game-page/Ar/gallery-tablet/slide-1.jpg";
import slideTablet2 from "../../img/game-page/Ar/gallery-tablet/slide-2.jpg";
import slideTablet3 from "../../img/game-page/Ar/gallery-tablet/slide-3.jpg";

import slideMobile1 from "../../img/game-page/Ar/gallery-mobile/slide-1.png";
import slideMobile2 from "../../img/game-page/Ar/gallery-mobile/slide-2.png";
import slideMobile3 from "../../img/game-page/Ar/gallery-mobile/slide-3.png";

import feature1 from "../../img/game-page/Ar/features/feature1.svg";
import feature2 from "../../img/game-page/Ar/features/feature2.svg";
import feature3 from "../../img/game-page/Ar/features/feature3.svg";

import rules1 from "../../img/rules/ar/rules1.png";
import rules2 from "../../img/rules/ar/rules2.png";
import rules3 from "../../img/rules/ar/rules3.png";
import rules4 from "../../img/rules/ar/rules4.png";
import rules5 from "../../img/rules/ar/rules5.png";
import rules6 from "../../img/rules/ar/rules6.png";
import rules7 from "../../img/rules/ar/rules7.png";
import rules8 from "../../img/rules/ar/rules8.png";
import rules9 from "../../img/rules/ar/rules9.png";
import rules10 from "../../img/rules/ar/rules10.png";
import rules11 from "../../img/rules/ar/rules11.png";

import partner1 from "../../img/game-partners/bb.svg";
import partner2 from "../../img/game-partners/luckyland.svg";
import partner3 from "../../img/game-partners/pronet.svg";
import partner4 from "../../img/game-partners/softgaming.svg";
import partner5 from "../../img/game-partners/parimatch.svg";
import partner6 from "../../img/game-partners/technamin.svg";

import { loadResources } from "../../util";
import logo from "../../img/logo-header-ler.svg";
import gilroyBold from "../../fonts/Gilroy/Gilroy-Bold.woff2";
import PTRootUIMedium from "../../fonts/PTRootUI/PTRootUI-Medium.woff2";
import LoaderLogo from "../CommonComponents/LoaderLogoComponent";

const partners = [
  {
    title: "BetBoom",
    date: "2022",
    logo: partner1,
  },
  {
    title: "LuckyLand",
    date: "2022",
    logo: partner2,
  },
  {
    title: "ProNetGaming",
    date: "2022",
    logo: partner3,
  },
  {
    title: "SoftGamings",
    date: "2022",
    logo: partner4,
  },
  {
    title: "PariMatch",
    date: "2022",
    logo: partner5,
  },
  {
    title: "Technamin",
    date: "2022",
    logo: partner6,
  },
];

const rules = (
  <div className="game-menu-rules-wrapper">
    <p>
      European Roulette is one of the most popular games in casino history. A huge popularity of the game can be explained by its dynamics and a variety of betting options. Many players prefer European Roulette, because the casino has a lower advantage in winning in this game version compared to its American version.
    </p>
    <p>
      In the case of Auto Roulette, the presence of a dealer is not provided for. Drawing is performed in the automatic mode, around-the-clock. The entourage of a gambling studio includes slot machines serving a decorative purpose.
    </p>
    <p>
      The roulette wheel spinning and the ball starting are performed automatically with the use of a mechanical and pneumatic drive. After the number is drawn and the drawing round is ended, the central section of the roulette lifts, the ball falls into a hole, and betting for the next draw game starts.
    </p>
    <p>
      The Return to Player (RTP) percent amounts to 97.30%.
    </p>
    <h2 name="game">Main game</h2>
     <p>
        Roulette is played by betting on which position the ball will land at the end of a single spin of the wheel.
        The roulette wheel consists of 37 numbered cells, 18 of them are red and 18 are black, and 1 is green (Zero).
        Various types of bets are available, and more than one bet can be placed during each game.
        Each game table has minimum and maximum betting limits for each position.
    </p>
    <p>
        At the very beginning of the game the countdown timer starts, then the players are allowed to place their bets
        on the roulette layout using chips. Betting stops when the correspondent information message is displayed on the screen.
        The roulette is started prior to betting termination.
        The ball is started on the spinning roulette wheel in the direction opposite to the wheel rotation direction.
        When the ball falls into a cell, the winning number is displayed. If this number is identical to that,
        for which the bet is placed, or falls within the range, for which the bet is placed, the winning is paid out.
    </p>
    <p>
        Winnings are calculated based on the odds of the winning bet.
        The biggest win is paid if the winning bet is placed on a single number (Straight).
        Next, in descending order: on 2 numbers (Split), on 3 numbers (Street), on 4 numbers (Corner), and on 6 numbers (Line).
        The key game events are: placing bets, placing the ball on a spinning wheel, falling of the ball into a cell with a number, and payouts of winnings.
    </p>
    <h2 name="wheel">Roulette wheel and ball</h2>
    <p>
      The roulette wheel consists of&nbsp;37&nbsp;cells with numbers from «1»
      to&nbsp;«36» located in&nbsp;the following order:{" "}
      <span className="numgreen">0</span>, <span className="numred">32</span>,
      15, <span className="numred">19</span>, 4,{" "}
      <span className="numred">21</span>, 2, <span className="numred">25</span>,
      17, <span className="numred">34</span>, 6,{" "}
      <span className="numred">27</span>, 13, <span className="numred">36</span>
      , 11, <span className="numred">30</span>, 8,{" "}
      <span className="numred">23</span>, 10, <span className="numred">5</span>,
      24, <span className="numred">16</span>, 33,{" "}
      <span className="numred">1</span>, 20, <span className="numred">14</span>,
      31, <span className="numred">9</span>, 22,{" "}
      <span className="numred">18</span>, 29, <span className="numred">7</span>,
      28, <span className="numred">12</span>, 35,{" "}
      <span className="numred">3</span>, 26, on black and red background, and
      the 0 cell (Zero) is located on a green background. The ball is placed on
      the spinning roulette wheel in the direction opposite to the wheel
      rotation, and as a result it falls into a cell with the number that
      becomes the winning number.
    </p>
    <figure className="game-menu-rules-figure1">
      <img src={rules1} />
    </figure>
    <h2 name="field">Playing field</h2>
    <p>
      Each of&nbsp;the numbers on&nbsp;the field corresponds to&nbsp;the number
      and color of&nbsp;the cell on&nbsp;the roulette wheel. The field
      is&nbsp;divided into two sectors for internal and external bets. Internal
      bets include 36&nbsp;cells with numbers arranged in&nbsp;series,
      in&nbsp;three columns, and the Zero cell. External bets include bets
      on&nbsp;dozens, even, odd, low numbers (1–18), high numbers (19–36), red,
      black, and three columns.
    </p>

    <figure className="game-menu-rules-figure2">
      <img src={rules2} />
    </figure>
    <h3 name="bets">Bets on&nbsp;the playing field</h3>
    <p>
      In&nbsp;order to&nbsp;make a&nbsp;bet, you need to&nbsp;place a&nbsp;chip
      with the chosen denomination on&nbsp;the cell with the selected number
      or&nbsp;on&nbsp;the cell with the group of&nbsp;numbers in&nbsp;the sector
      of&nbsp;external bets. And when the chip will cover any possible
      combination of&nbsp;bets, all numbers of&nbsp;this combination will
      be&nbsp;highlighted.
    </p>
    <figure className="game-menu-rules-figure3">
      <img src={rules3} />
    </figure>

    <p>Betting chips of various denominations.</p>
    <figure className="game-menu-rules-figure11 last">
      <img src={rules4} />
    </figure>

    <table className="game-menu-rules-table1">
      <tbody>
        <tr>
          <th></th>
          <th>Name</th>
          <th>Coefficient</th>
          <th>Description</th>
        </tr>
        <tr>
          <td>1</td>
          <td>Straight</td>
          <td>35:1</td>
          <td>
            The bet is placed on any individual number, including “0”, and
            directly on the number.
          </td>
        </tr>
        <tr>
          <td>2</td>
          <td>Split</td>
          <td>17:1</td>
          <td>
            The bet is placed on two adjacent numbers and is located on the line
            between these numbers. The bet may also include 0 and 1, 0 and 2, as
            well as 0 and 3.
          </td>
        </tr>
        <tr>
          <td>3</td>
          <td>Street</td>
          <td>11:1</td>
          <td>
            The bet on three numbers in one line is placed on the line at the
            end of the row. There are other options of this bet – it can include
            0, 1, 2; 0, 2, 3.
          </td>
        </tr>
        <tr>
          <td>4</td>
          <td>Corner</td>
          <td>8:1</td>
          <td>
            The bet on a group of four numbers is placed at the point of contact
            of four numbers. A bet can also be placed at the points of contact
            of 0, 1, 2, and 3.
          </td>
        </tr>
        <tr>
          <td>5</td>
          <td>Line</td>
          <td>5:1</td>
          <td>
            The bet on a group of six numbers (or two rows of three numbers) is
            placed at the end of two rows on the border between them.
          </td>
        </tr>
        <tr>
          <td>6</td>
          <td>Column</td>
          <td>2:1</td>
          <td>
            The bet contains the entire column and is placed on the cell “2 to
            1” at the end of the column.
          </td>
        </tr>
        <tr>
          <td>7</td>
          <td>Dozen</td>
          <td>2:1</td>
          <td>
            The bet on a group of twelve numbers may be placed on the cell of
            the first dozen (from 1 to 12), on the cell of the second dozen
            (from 13 to 24), or on the cell of the third dozen (from 25 to 36).
          </td>
        </tr>
        <tr>
          <td>8</td>
          <td>1:18</td>
          <td>1:1</td>
          <td>
            The bet contains all the low numbers (1–18) and is placed on the
            “1–18” field.
          </td>
        </tr>
        <tr>
          <td>9</td>
          <td>19-36</td>
          <td>1:1</td>
          <td>
            The bet contains all the high numbers (19–36) and is placed on the
            “19–36” field.
          </td>
        </tr>
        <tr>
          <td>10</td>
          <td>Even</td>
          <td>1:1</td>
          <td>
            The bet contains all even numbers on the playing field and is placed
            on the “Even” cell.
          </td>
        </tr>
        <tr>
          <td>11</td>
          <td>Odd</td>
          <td>1:1</td>
          <td>
            The bet contains all odd numbers on the playing field and is placed
            on the “Odd” cell.
          </td>
        </tr>
        <tr>
          <td>12</td>
          <td>Red</td>
          <td>1:1</td>
          <td>
            The bet contains all red numbers on the playing field and is placed
            on the “Red” cell.
          </td>
        </tr>
        <tr>
          <td>13</td>
          <td>Black</td>
          <td>1:1</td>
          <td>
            The bet contains all black numbers on the playing field and is
            placed on the “Black” cell.
          </td>
        </tr>
      </tbody>
    </table>

    <figure className="game-menu-rules-figure4">
      <img src={rules5} />
    </figure>

    <h3 name="snake">Snake bet</h3>
    <p>
      The bet is&nbsp;placed at&nbsp;the point of&nbsp;contact between the cells
      of&nbsp;the third dozen and the first column. Straight bets are placed
      on&nbsp;a&nbsp;chain of&nbsp;12&nbsp;red numbers: 1, 5, 9, 12, 14, 16, 19,
      23, 27, 30, 32, 34.
    </p>
    <figure className="game-menu-rules-figure5">
      <img src={rules6} />
    </figure>

    <h3 name="track">Track field</h3>
    <p>
      A&nbsp;field for quick bets on&nbsp;combinations that are absent
      on&nbsp;the main playing field. Each bet on&nbsp;the track field
      corresponds to&nbsp;a&nbsp;set of&nbsp;numbers on&nbsp;the main playing
      field, these bets are placed on&nbsp;the corresponding numbers of&nbsp;the
      main field. The field has an&nbsp;oval shape: numbers from
      0&nbsp;to&nbsp;36&nbsp;are located along the field contour in&nbsp;the
      same order in&nbsp;which they are located on&nbsp;the roulette wheel.
      Sectors inside the oval, which are absent on&nbsp;the main playing field,
      are called French. French bets are combined bets covering several numbers
      of&nbsp;the roulette wheel sector. There are 5&nbsp;types of&nbsp;bets
      on&nbsp;the track field:
    </p>
    <figure className="game-menu-rules-figure6">
      <img src={rules7} />
    </figure>

    <h3 name="neighboring">Bet on&nbsp;Neighbors</h3>
    <p>
      Bet on&nbsp;five numbers sequentially located on&nbsp;the track field.
      In&nbsp;order to&nbsp;make a&nbsp;bet, the player should place a&nbsp;chip
      with the chosen denomination on&nbsp;the desired number. When the chip
      is&nbsp;placed on&nbsp;a&nbsp;number, two more numbers are highlighted
      on&nbsp;each side of&nbsp;the selected number, which forms a&nbsp;chain
      of&nbsp;five numbers. After selecting a&nbsp;chain of&nbsp;five numbers,
      the bet is&nbsp;placed on&nbsp;the main playing field in&nbsp;the cells
      with the corresponding numbers as&nbsp;a&nbsp;chip with selected
      denomination. Multiple bets on&nbsp;Neighbors can be&nbsp;placed during
      one game.
    </p>

    <figure className="game-menu-rules-figure7">
      <img src={rules8} />
    </figure>

    <h3 name="inner">Bets on&nbsp;inside sectors</h3>
    <p>
      Bets are placed inside the «oval». In&nbsp;order to&nbsp;make a&nbsp;bet,
      the player places a&nbsp;chip on&nbsp;the combination inside the track
      field. When a&nbsp;chip is&nbsp;placed on&nbsp;any of&nbsp;the
      combinations, all numbers of&nbsp;this combination are highlighted
      on&nbsp;the main playing field. Chips with the selected denomination are
      placed on&nbsp;the main playing field in&nbsp;the cells with the
      corresponding numbers.
    </p>
    <figure className="game-menu-rules-figure8">
      <img src={rules9} />
    </figure>

    <table className="game-menu-rules-table2">
      <tbody>
        <tr>
          <th></th>
          <th>Name</th>
          <th>Description</th>
        </tr>
        <tr>
          <td>1</td>
          <td>Neighbors</td>
          <td>
            The bet is made on five numbers sequentially located on the “oval”,
            and it is placed on the middle number in the sequence.{" "}
          </td>
        </tr>
        <tr>
          <td>2</td>
          <td>Serie 0/2/3</td>
          <td>
            Bet consisting of 9&nbsp;chips covering 17&nbsp;numbers:
            <ul>
              <li>Split bet on: 0/2/3 (1&nbsp;chip)</li>
              <li>
                Split bet on: 4/7, 12/15, 18/21, 19/22, 25/29, 26/28, and 32/35
                (7&nbsp;chips)
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>3</td>
          <td>Serie 5/8</td>
          <td>
            Bet consisting of 6&nbsp;chips covering the sector consisting of
            12&nbsp;numbers:
            <ul>
              <li>
                Split bet on: 5/8, 10/11, 13/16, 23/24, 27/30, and 33/36
                (6&nbsp;chips)
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>4</td>
          <td>Orph.</td>
          <td>
            Bet consisting of 5&nbsp;chips covering 8&nbsp;numbers —
            2&nbsp;sectors of the roulette wheel:
            <ul>
              <li>Straight bet on: 1 (1&nbsp;chip) </li>
              <li>Split bet on: 6/9, 14/17, 17/20, and 31/34 (4&nbsp;chips)</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>5</td>
          <td>Zero</td>
          <td>
            Bet consisting of 4&nbsp;chips covering the sector consisting of
            7&nbsp;numbers:
            <ul>
              <li>Straight bet on: 26 (1&nbsp;chip)</li>
              <li>Split bet on: 0/3, 12/15, and 32/35 (3&nbsp;chips)</li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>

    <figure className="game-menu-rules-figure9">
      <img src={rules10} />
    </figure>
    <figure className="game-menu-rules-figure10">
      <img src={rules11} />
    </figure>
  </div>
);

class GamePageAr extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    loadResources([
      ["image", logo],
      ["image", gameBanner],
      ["font", gilroyBold, { name: "Gilroy", style: "normal", weight: 900 }],
      [
        "font",
        PTRootUIMedium,
        { name: "PT Root UI", style: "normal", weight: 500 },
      ],
    ]).then(() => {
      this.props.dispatch({
        type: "set-resources-loaded",
        payload: { loaded: true },
      });
    });
  }

  render() {
    return (
      <>
        <LoaderLogo isLoaded={this.props.loadedResources.loaded} />
        <GamePage
          banner={gameBanner}
          theme="game_ar"
          name="AUTO ROULETTE"
          text="Newest Auto-Roulette will glamor your players
          with fast rounds and slots hall studio with a land based casino vibe!"
          desktopIcon={desktopIcon}
          tabletIcon={tabletIcon}
          mobileIcon={mobileIcon}
          deviceDesktop={deviceDesktop}
          deviceTablet={deviceTablet}
          deviceMobile={deviceMobile}
          slidesDesktop={[slideDesktop1, slideDesktop2, slideDesktop3]}
          slidesTablet={[
            slideTablet1,
            slideTablet2,
            slideTablet3,
          ]}
          slidesMobile={[
            slideMobile1,
            slideMobile2,
            slideMobile3,
          ]}
          features={[
            {
              icon: feature1,
              text: "Real Slots hall studio",
            },
            {
              icon: feature2,
              text: "Super fast rounds",
            },
            {
              icon: feature3,
              text: "Maximum game transparency",
            },
          ]}
          rules={rules}
          partners={partners}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.language,
  loadedResources: state.loadedResources,
});

export default connect(mapStateToProps)(GamePageAr);
