import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import style from "./style.scss"

import { ReactComponent as CheckSVG} from '../../../img/check.svg';

const popupModalComponent = (props) => {

    const popupRef = useRef();

    useEffect(()=> {
        if (props.isMobile) popupRef.current.scrollIntoView();
    }, [])

    return (
        <div className="popup-modal" ref={popupRef}>
            <div  className="popup-modal-icon">
                <CheckSVG />
            </div>
            <h2  className="popup-modal-title">Thank you!</h2>
            <p  className="popup-modal-text">We always try our best to respond as soon as possible, you can expect a reply in at most 24 hours.</p>
        </div>
    );
};


const mapStateToProps = (state) => ({
    language: state.language,
    isMobile: state.isMobile
  });
  
  export default connect(mapStateToProps)(popupModalComponent);
  