import React, { Component } from "react";
import styles from "./style.scss";
import { connect } from "react-redux";
import partner1 from "../../../img/game-partners/bb.svg";
import partner2 from "../../../img/game-partners/1-xbet.svg";
import partner3 from "../../../img/game-partners/betconstruct.svg";
import partner4 from "../../../img/game-partners/pronet.svg";
import partner5 from "../../../img/game-partners/7-islands.svg";
import partner6 from "../../../img/game-partners/softswiss.svg";
import PartnerItemComponent from "./PartnerItemComponent/PartnerItemComponent";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";

class GamePartners extends Component {
  render() {
    const { theme, partners, isMobile } = this.props;

    const content = isMobile ? (
      <Swiper
        spaceBetween={this.props.isMobile ? 16 : 24}
        slidesPerView={"auto"}
        scrollbar={{ draggable: true }}
        watchOverflow={true}
        slidesOffsetBefore={this.props.isMobile ? 16 : 0}
      >
        {partners.map((partner) => (
          <SwiperSlide key={partner.title}>
            <PartnerItemComponent
              title={partner.title}
              date={partner.date}
              logo={partner.logo}
              theme={theme}
              link={partner.link}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    ) : (
      partners.map((partner) => (
        <PartnerItemComponent
          key={partner.title}
          title={partner.title}
          date={partner.date}
          logo={partner.logo}
          theme={theme}
          link={partner.link}
        />
      ))
    );

    return (
      <div className={"game-partners game-partners_" + theme}>
        <div className="game-partners__container">{content}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.language,
  isMobile: state.isMobile,
});

export default connect(mapStateToProps)(GamePartners);
