import React, {useEffect, useState} from 'react'
import styles from "./style.scss"
import {connect} from "react-redux"
import {Link, NavLink} from 'react-router-dom'

import Button from "../../CommonComponents/ButtonComponent/index"
import {changePage} from "../../../ac"

import {isTouch} from "../../../util"

import { ReactComponent as Logo} from '../../../img/logo.svg';
// import { ReactComponent as LogoLong} from '../../../img/logo_long.svg';
import { ReactComponent as LogoLongAlt} from '../../../img/logo-header-ler.svg';
import { ReactComponent as LogoLong} from '../../../img/logo-header-def.svg';

const headerComponent = (props) => {



    const [isColored, setIsColored] = useState(false);

    const {isMobile, page, theme} = props;

   
    useEffect(()=> {

        const isIpad = isTouch() && !isMobile;

        const scrollHandler = (ev) => {
            const {destinationIndex = null} = props;
            if (destinationIndex) {
                setIsColored(true)
            }
            else {
                const scrollingElement = document.querySelector('.iScrollIndicator');
                let offset = window.pageYOffset;
                let scrollState = scrollingElement ? scrollingElement.style.webkitTransform !== "translate(0px, 0px) translateZ(0px)" : !!offset
                 setIsColored(scrollState);          
            }
    
        };

        if (!isMobile ||  page === 'news') window.addEventListener('wheel', ev => scrollHandler(ev));
        if (isIpad) window.addEventListener('touchmove', ev => scrollHandler(ev));

        return () => {
            window.removeEventListener('wheel', ev => scrollHandler(ev));
            isIpad && window.removeEventListener('touchmove', ev => scrollHandler(ev));
        }
    }, [props.destinationIndex])

    // alt colored for mobile notcoloroed initial state
    useEffect(()=> {

        let lastOffset = null;
        const scrollHandler = (ev) => {
            const offsetDiff = window.pageYOffset - lastOffset;
            const headerVisible = offsetDiff < 0
                || window.pageYOffset <= 0; // иначе хедер скрывается при оттяжке на айфоне на сафари
            setIsColored(!headerVisible);
            lastOffset = window.pageYOffset;
        }
        
        if (isMobile) {
            scrollHandler();
            window.addEventListener('scroll', ev => scrollHandler(ev))
        }

        return () => window.removeEventListener('scroll', ev => scrollHandler(ev));
    }, [page])




    const [burgerIsOpen, setBurgerIsOpen] = useState(false);
    useEffect(()=> {
            const pageCont = (page === "privacy-policy" || page === "contacts")?
                document.querySelector(`${page === "contacts"?'.page__container':'.section'}`) : document.querySelector('.page');
        pageCont && [...pageCont.children].map(elm =>
                elm.classList.toggle('d-none', elm.className !== 'header' && burgerIsOpen && isMobile)
            );
        pageCont && pageCont.classList.toggle('view-height', burgerIsOpen && isMobile &&(page === "privacy-policy" || page === "contacts"));

    },[burgerIsOpen, isMobile, page]);

    const headerClasses = (isColored ? "header header__colored" : "header") + (isMobile && page === 'news' ? " is__alt" : "") + (theme ? " header_" + theme : "") + (theme && isColored? " header__colored_" + theme : "") + (burgerIsOpen ? " is__open " : '');;

    let logo= null;
    if(isMobile) {
        // TODO Here we need to add mobile logoAlt and add current paths!
        logo = <Logo />
    }

    const moveTo = section =>{
        props.dispatch(changePage(section))
    }


    return(
        <div  className={ headerClasses}>
            <div className={"header__inner"}>
                <Link to="/" className={(theme ? "header__logo header__logo_" + theme : "header__logo") + (isMobile ? " mobile" : "")}>
                    {logo}
                </Link>
                <div className={burgerIsOpen ? "header__burger__toggler is__open" : "header__burger__toggler"} onClick={()=> setBurgerIsOpen(!burgerIsOpen)}></div>
                <div className={burgerIsOpen ? "header__burger is__open" : "header__burger"}>
                    <div className={"header__nav"}>
                        <NavLink to="/games"  activeClassName={"header__nav-element_active"} className={"header__nav-element" + (theme ? " header__nav-element_" + theme : "")}><span className={"header__nav-link" + (theme ? " header__nav-link header__nav-link_" + theme : '')} >GAMES</span></NavLink>
                        <NavLink to="/partnership"  activeClassName="header__nav-element_active" className="header__nav-element"><span className={"header__nav-link" + (theme ? " header__nav-link header__nav-link_" + theme : '')} >PARTNERSHIP</span></NavLink>
                        <NavLink to="/company"   activeClassName="header__nav-element_active" className="header__nav-element"><span className={"header__nav-link"  + (theme ? " header__nav-link header__nav-link_" + theme : '')} >COMPANY</span></NavLink>
                        <NavLink to="/news"   activeClassName="header__nav-element_active" className="header__nav-element"><span className={"header__nav-link"  + (theme ? " header__nav-link header__nav-link_" + theme : '')} >NEWS</span></NavLink>
                        <NavLink to="/contacts"  activeClassName="header__nav-element_active" className="header__nav-element"><span className={"header__nav-link"  + (theme ? " header__nav-link header__nav-link_" + theme : '')} >CONTACTS</span></NavLink>
                    </div>
                    <div className="header__lang">
                        {/*<Lang*/}
                            {/*type={'header'}*/}
                            {/*theme = {theme}*/}
                        {/*>*/}
                        {/*</Lang>*/}
                    </div>
                    <div className={"header__login"}>
                        <Button
                        mod={theme ? "type-2_" + theme : "type-1"}
                        title={"Say Hello!"}
                        clickFunction={()=> moveTo('contacts')}
                        />
                    </div>
                    
                </div>
                
                    

            </div>

        </div>
    )
    
}

const mapStateToProps = state =>({
    language: state.language,
    page: state.page,
    isMobile: state.isMobile
})

export default connect(mapStateToProps)(headerComponent)
