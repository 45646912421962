import React, { Component } from "react";
import styles from "./style.scss";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// import Lang from "../../CommonComponents/LangComponent/index"
// import Share from "../../CommonComponents/ShareComponent/index"
import MediaLinks from "../../CommonComponents/MediaLinksComponent/index";
import { changePage } from "../../../ac";
import Button from "../../CommonComponents/ButtonComponent/index";

import partner1 from "../../../img/partners/footer/betBoom.svg";
import partner2 from "../../../img/partners/footer/1XBet.svg";
import partner3 from "../../../img/partners/footer/softswiss.svg";
import partner4 from "../../../img/partners/footer/betConstruct.svg";
import partner5 from "../../../img/partners/footer/digitain.svg";
import partner6 from "../../../img/partners/footer/topPlayGaming.svg";
import partner7 from "../../../img/partners/footer/technamin.svg";
import partner8 from "../../../img/partners/footer/cyprusSportingClubs.svg";
import partner9 from "../../../img/partners/footer/softGaming.svg";
import partner10 from "../../../img/partners/footer/boGap.svg";
import partner11 from "../../../img/partners/footer/pariMatch.svg";
import partner12 from "../../../img/partners/footer/pronetGaming.svg";
import partner13 from "../../../img/partners/footer/luckyLand.svg";
import partner14 from "../../../img/partners/footer/arkinGroup.svg";

import partnerWhite1 from "../../../img/partners/footer/partners-white/betBoom.svg";
import partnerWhite2 from "../../../img/partners/footer/partners-white/1XBet.svg";
import partnerWhite3 from "../../../img/partners/footer/partners-white/softwiss.svg";
import partnerWhite4 from "../../../img/partners/footer/partners-white/betconstruct.svg";
import partnerWhite5 from "../../../img/partners/footer/partners-white/digitain.svg";
import partnerWhite6 from "../../../img/partners/footer/partners-white/topPlayGaming.svg";
import partnerWhite7 from "../../../img/partners/footer/partners-white/technamin.svg";
import partnerWhite8 from "../../../img/partners/footer/partners-white/cyprusSportingClubs.svg";
import partnerWhite9 from "../../../img/partners/footer/partners-white/softGamings.svg";
import partnerWhite10 from "../../../img/partners/footer/partners-white/bogap.svg";
import partnerWhite11 from "../../../img/partners/footer/partners-white/pariMatch.svg";
import partnerWhite12 from "../../../img/partners/footer/partners-white/pronetGaming.svg";
import partnerWhite13 from "../../../img/partners/footer/partners-white/luckyLand.svg";
import partnerWhite14 from "../../../img/partners/footer/partners-white/arkinGroup.svg";

import { ReactComponent as Logo } from "../../../img/logo.svg";
import { ReactSVG } from "react-svg";

const partners = [
  partner1,
  partner2,
  partner3,
  partner4,
  partner5,
  partner6,
  partner7,
  partner8,
  partner9,
  partner10,
  partner11,
  partner12,
  partner13,
  partner14,
];
const partnersWhite = [
  partnerWhite1,
  partnerWhite2,
  partnerWhite3,
  partnerWhite4,
  partnerWhite5,
  partnerWhite6,
  partnerWhite7,
  partnerWhite8,
  partnerWhite9,
  partnerWhite10,
  partnerWhite11,
  partnerWhite12,
  partnerWhite13,
  partnerWhite14,
];

class FooterComponent extends Component {
  render() {
    const moveTo = (section) => {
      this.props.dispatch(changePage(section));
    };
    const { theme } = this.props;

    let logo = null;
    if (this.props.isMobile) {
      // TODO Here we need to add mobile logoAlt and add current paths!
      logo = <Logo />;
    }

    const partnerLogoWhite =
      theme === "game_ler" ||
      theme === "game_ar" ||
      theme === "game_bj" ||
      theme === "game-cr";

    return (
      <div className={theme ? "footer footer_" + theme : "footer"}>
        <div className="footer__inner">
          <div
            className={
              theme ? "footer__top footer__top_" + theme : "footer__top"
            }
          >
            <div className="footer-ticker">
              {partnersWhite.map((partner, i) => (
                <ReactSVG
                  key={i}
                  src={partner}
                  className={
                    partnerLogoWhite
                      ? "footer__partner-white"
                      : "footer__partner"
                  }
                />
              ))}

              {partnersWhite.map((partner, i) =>
                i < 7 ? (
                  <ReactSVG
                    key={i}
                    src={partner}
                    className={
                      partnerLogoWhite
                        ? "footer__partner-white"
                        : "footer__partner"
                    }
                  />
                ) : null
              )}
            </div>
          </div>
        </div>
        <div className="footer__inner">
          <div className="footer__main">
            {this.props.isMobile && (
              <div className={"footer__button"}>
                <Button
                  mod={theme ? "type-2_" + theme : "type-1"}
                  title={"Say Hello!"}
                  clickFunction={() => moveTo("contacts")}
                />
              </div>
            )}
            <div className="footer__columns">
              {!this.props.isMobile && (
                <Link
                  to="/"
                  className={
                    theme
                      ? "footer__logo footer__logo_" + theme
                      : "footer__logo"
                  }
                >
                  {logo}
                </Link>
              )}
              <div className="footer__col footer__col_1">
                <Link
                  to="/games"
                  className={
                    theme
                      ? "footer__item footer__item_" + theme
                      : "footer__item"
                  }
                >
                  games
                </Link>
                <ul className="footer__list">
                  <li
                    className={
                      theme
                        ? "footer__list-item footer__list-item_" + theme
                        : "footer__list-item"
                    }
                  >
                    <Link to="/games/game-ler" className="footer__list-link">
                      Live Roulette
                    </Link>
                  </li>
                  <li
                    className={
                      theme
                        ? "footer__list-item footer__list-item_" + theme
                        : "footer__list-item"
                    }
                  >
                    <Link to="/games/game-ar" className="footer__list-link">
                      Auto Roulette
                    </Link>
                  </li>
                  <li
                    className={
                      theme
                        ? "footer__list-item footer__list-item_" + theme
                        : "footer__list-item"
                    }
                  >
                    <Link
                      to="/games/game-music-wheel"
                      className="footer__list-link"
                    >
                      Music Wheel
                    </Link>
                  </li>
                  <li
                    className={
                      theme
                        ? "footer__list-item footer__list-item_" + theme
                        : "footer__list-item"
                    }
                  >
                    <Link to="/games/game-keno" className="footer__list-link">
                      Keno
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer__col footer__col_2">
                <Link
                  to="/games"
                  className={
                    theme
                      ? "footer__item footer__item_" + theme
                      : "footer__item"
                  }
                >
                  games
                </Link>
                <ul className="footer__list">
                  <li
                    className={
                      theme
                        ? "footer__list-item footer__list-item_" + theme
                        : "footer__list-item"
                    }
                  >
                    <Link to="/games/blackjack" className="footer__list-link">
                      Blackjack
                    </Link>
                  </li>
                  <li
                    className={
                      theme
                        ? "footer__list-item footer__list-item_" + theme
                        : "footer__list-item"
                    }
                  >
                    <Link to="/games/game-cr" className="footer__list-link">
                      Cocktail Roulette
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer__col footer__col_3">
                {this.props.isMobile && (
                  <Link
                    to="/games"
                    className={
                      theme
                        ? "footer__item footer__item_" + theme
                        : "footer__item"
                    }
                  >
                    games
                  </Link>
                )}
                <Link
                  to="/partnership"
                  className={
                    theme
                      ? "footer__item footer__item_" + theme
                      : "footer__item"
                  }
                >
                  Partnership
                </Link>
                <Link
                  to="/company"
                  className={
                    theme
                      ? "footer__item footer__item_" + theme
                      : "footer__item"
                  }
                >
                  company
                </Link>
                <Link
                  to="/news"
                  className={
                    theme
                      ? "footer__item footer__item_" + theme
                      : "footer__item"
                  }
                >
                  news
                </Link>
              </div>
              <div className="footer__links">
                <Link
                  to="/contacts"
                  className={
                    theme
                      ? "footer__item footer__item_" + theme
                      : "footer__item"
                  }
                >
                  contacts
                </Link>
                <p className={theme ? "footer__mail_" + theme : "footer__mail"}>
                  info@atmosfera.is
                </p>
                <MediaLinks theme={theme} />
              </div>
              {!this.props.isMobile && (
                <div className={"footer__button"}>
                  <Button
                    mod={theme ? "type-1_" + theme : "type-1"}
                    title={"Say Hello!"}
                    clickFunction={() => moveTo("contacts")}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className={
            theme ? "footer__bottom footer__bottom_" + theme : "footer__bottom"
          }
        >
          <div className="footer__inner">
            <div className="footer__bottom-contain">
              <div
                className={
                  theme ? "footer__copy footer__copy_" + theme : "footer__copy"
                }
              >
                © Atmosfera Inc. 2020–2023
              </div>
              <Link
                to="/privacy-policy"
                className={
                  theme
                    ? "footer__info-item footer__info-item_" + theme
                    : "footer__info-item"
                }
              >
                Privacy
              </Link>
              {this.props.isMobile && (
                <Link
                  to="/"
                  className={
                    theme
                      ? "footer__logo footer__logo_" + theme
                      : "footer__logo"
                  }
                >
                  {logo}
                </Link>
              )}
              {/* <div className="footer__info"> */}
              {/* <a href="#" className={theme ? "footer__info-item footer__info-item_" + theme : "footer__info-item"}>
                                    Terms & Conditions
                                </a> */}
              {/* <a href="#" className={theme ? "footer__info-item footer__info-item_" + theme : "footer__info-item"}>
                                    Cookies
                                </a> */}
              {/* </div> */}
              {/* <div className="footer__lang"> */}
              {/* <div className="footer__lang__contain"> */}
              {/*<Lang*/}
              {/*type={'footer'}*/}
              {/*theme={theme}*/}
              {/*>*/}
              {/*</Lang>*/}
              {/* </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.language,
  isMobile: state.isMobile,
});

export default connect(mapStateToProps)(FooterComponent);
