import React from 'react';

import style from './style.scss';
import {ReactComponent as ValidImg} from '../../../img/done.svg'

const inputStatus = ({type, value}) => {

    const modClass = type ? `is__${type}` : '';

    return (
        <div className={`input-status ${modClass}`}>
            <div className="input-status__value">{type === 'error' ? value : <ValidImg />}</div>
        </div>
    );
};

export default inputStatus;