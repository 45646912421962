function extend(a, b) {
  for (var key in b) {
    if (b.hasOwnProperty(key)) {
      a[key] = b[key];
    }
  }
  return a;
}

function SVGEl(el) {
  this.el = el;
  // all the paths
  this.paths = [].slice.call(this.el.querySelectorAll('path'));
  // arrays to save all paths and lengths
  this.pathsArr = new Array();
  this.lengthsArr = new Array();
  this._init();
}

SVGEl.prototype._init = function() {
  var self = this;
  this.paths.forEach(function(path, i) {
    self.pathsArr[i] = path;
    path.style.strokeDasharray = self.lengthsArr[i] = path.getTotalLength();
  });
  this.draw(0);
};

SVGEl.prototype.draw = function(val) {
  for(var i = 0, len = this.pathsArr.length; i < len; ++i) {
    this.pathsArr[i].style.strokeDashoffset = this.lengthsArr[i] * (1 - val)
  }
};

function UIProgressButton(el, options) {
  this.el = el;
  this.options = extend({}, this.options);
  extend(this.options, options);
  this._init();
}

UIProgressButton.prototype._init = function() {
  this.button = this.el.querySelector('button');
  this.progressEl = new SVGEl(this.el.querySelector('svg.progress-circle'));
  this.successEl = new SVGEl(this.el.querySelector('svg.success-check'));
  this.errorEl = new SVGEl(this.el.querySelector('svg.failure-cross'));
  // init events
  this._initEvents();
};

UIProgressButton.prototype._initEvents = function() {
  var self = this;
  this.button.addEventListener('click', function() {
    if (!self.disabled)  self._submit();
  });
};

UIProgressButton.prototype._submit = function() {
  this.el.classList.add('loading');

  var self = this,
    onEndBtnTransFn = function(event) {
      this.removeEventListener('transitionend', onEndBtnTransFn);
      const progressFunc = () => {
        let progress = 0;
        self.setProgress(progress);
        self.interval = setInterval(function() {
          progress = Math.min(progress + Math.random() * 0.2, 1);
          self.setProgress(progress);
        }, 100);
      }

      // set timeout for wating button animation finished
      setTimeout(progressFunc,  600);
    };

  this.button.addEventListener('transitionend', onEndBtnTransFn);
};

UIProgressButton.prototype.stop = function(status) {
  var self = this,
      endLoading = function() {

        // set progress to 100% to play full  progress animation, length of animation 250ms, that's why use settimeout for button animation
        clearInterval(self.interval);
        self.setProgress(1);
        setTimeout(()=> {
          self.progressEl.draw(0);
        
          if (typeof status === 'number') {
            var statusClass = status >= 0 ? 'success' : 'failure',
                statusEl = status >= 0 ? self.successEl : self.errorEl;

            statusEl.draw(1);
            // add classes to element
            self.el.classList.add(statusClass);
            // after options.statusTime remove status, undraw stroke, and enable button
            setTimeout(function() {
              self.el.classList.remove(statusClass);
              statusEl.draw(0);
              self.setProgress(0);
            }, self.options.statusTime);    
          }

          self.el.classList.remove('loading');
        }, 250)
        
        
      };
      endLoading()
  // setTimeout(endLoading, self.options.waitingTime);
};

UIProgressButton.prototype.setProgress = function(val) {
  
  this.progressEl.draw(val);
};

export default UIProgressButton;