import { useState, useEffect } from 'react';
import {url} from "../../src/util"

const parseQuery = (query) => {

  if (query.includes('news-post/')) {
    return url +'/'+ query
  } 

  let result;

  switch (query) {
    case 'news-list':
      result = 'news-posts?_sort=publish:DESC';
      break;
    case 'mainpage-news':
      result = 'news-posts?_sort=publish:DESC&_limit=2&_start=0'
      break;
    default:
    result = 'news-posts?_sort=publish:DESC'
      break;
  }

   return result;
}

const useData = ( query, params, deps = []) => {


  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFalse, setIsFalse] = useState(false);
  let request = '';

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      request = `${url}/${parseQuery(query)}`;
      try {
         const result = await fetch(request).then(response => response.json()) || [];

        // here we simplify data structure, change url for absolute path and add some custom prop
        // TODO images have dif formats in prop formats. need use dif sizes for dif situations
        setData(result.map(({id, title, preview_article, publish, news_category,  preview, is_main_news } )=> {
          return {
              id: id,
              title: title,
              text: preview_article,
              date: new Date(publish).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }),
              type: news_category && news_category.title,
              link: `/news/news-${id}`,
              preview: preview ? {
                url: preview.url,
              } : {url: null},
              url: `${url}/news-posts`,
              is_main_news
          }
       }));
      } catch (error) {
        console.error(error);
        setIsFalse(true);
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, deps);
  return {
    data,
    isLoading,
    isFalse,
  };
};

export default useData;
