export function getGames(){
    return{
        type: 'get-games'
    }
}

export function changePage(value){
    return {
        type: 'switch-page',
        value
    }
}

export function setLang( value ){
    return{
        type: 'set-language',
        value
    }
}

export function setIsMobile( value ){
    return{
        type: 'set-isMobile',
        value
    }
}
