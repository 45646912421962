import React, { useState} from 'react'
import {connect} from "react-redux"
import "./style.scss"
import Close from "../../../CommonComponents/CloseComponent/index"
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';

import GameDevicesSlider from "../GameDevicesSliderComponent/GameDevicesSliderComponent"
import Button from '../../../CommonComponents/ButtonComponent';
import { ReactComponent as FullScreenIconSVG} from '../../../../img/icon-fullscreen.svg';


function GameDevicesImgComponent(props){
    function getModalStyle() {
        const top = 0;
        const left = 0;
        return {
            top: `${top}%`,
            left: `${left}%`
        };
    }

    const useStyles = makeStyles(theme => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            position: 'absolute',
            width: "100vw",
            height: "calc(var(--vh) * 100)",
            background: "red"
        },
    }));
    const {theme, src, slides, isMobile} = props;
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        if (!open) {
            setOpen(true);
        }

    };

    const handleClose = () => {
        setOpen(false);
    };



    return(
        <div className={"game-devices-img game-devices-img_" + theme} onClick={handleOpen}>
            <img className="game-devices-img__img" src={src}/>
            <Modal
                open={open}
                onClose={handleClose}
                container={document.querySelector(".page")}
            >
                <div className={"game-devices-img__modal game-devices-img__modal_"+ theme}>
                    <div className="game-devices-img__modal-close">
                        <Close
                            theme = {theme}
                            closeFunction = {handleClose}
                        />
                    </div>
                    <GameDevicesSlider
                        theme = {theme}
                        slides = {slides}
                    />
                </div>

            </Modal>
            {isMobile ? 
            <>
            <div className="game-devices-img__fullscreen-btn">
                <Button
                    mod = {"type-2_" + theme + " btn__is__small"}
                    title={"view gallery"}
                    clickFunction={handleOpen}
                    icon={<FullScreenIconSVG style={{width: '1.8rem', height: '1.8rem', marginRight: '1.6rem'}}/>}
                />
            </div> 
            </>
            : null}
        </div>
    )
}

const mapStateToProps = state =>({
    language: state.language,
    isMobile: state.isMobile
})

export default connect(mapStateToProps)(GameDevicesImgComponent);/**
 * Created by tany on 12.12.2020.
 */
