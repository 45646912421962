import React, { useState, useEffect } from 'react';
import styles from './style.scss';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import arrayEquals from '../../../../functions/arrayEquals'

import Button from '../../../CommonComponents/ButtonComponent/index';
import CheckBox from '../../../CommonComponents/CheckBoxComponent/index';
import InputField from '../../../CommonComponents/InputFieldComponent/index';
import ErrorMessage from '../../../CommonComponents/ErrorMessage/index';

import iconMail from '../../../../img/input-mail.svg';
import iconName from '../../../../img/input-user.svg';
import iconMessage from '../../../../img/input-message.svg';
import PopupModalComponent from '../../../CommonComponents/PopupModalComponent';
import {atLeastOneNonSpaceChar} from '../../../../util';

const contactsSchema = Yup.object().shape({
  name: Yup.string().test(
    'at-least-one-non-space-char',
    'Please introduce yourself',
    atLeastOneNonSpaceChar,
  ),
  email: Yup.string()
    .email('Invalid email address')
    .required('This field is required'),
  message: Yup.string()
    .test(
      'at-least-one-non-space-char',
      'This field is required',
      atLeastOneNonSpaceChar,
    )
    .max(250, 'Максимум 250 знаков'),
  privacy: Yup.boolean()
    .oneOf([true], 'You must accept the terms and conditions')
    .required('You must accept the terms and conditions'),
});

const contactsFormComponent = (props) => {

  const [showPopup, setShowPopup] = useState(false);
  
  const onSubmitHandler = (values) =>  {

      fetch('/api/feedback', {
          method: 'POST',
          body: JSON.stringify({...values}),
          headers: {
              'Content-Type': 'application/json'
          },
      }).then((msg) => {
          console.info("ok")
      });

    setShowPopup(true);
  }

  return showPopup ? <PopupModalComponent /> : (
    <Formik
      initialValues={{
        name: '',
        email: '',
        message: '',
        privacy: false,
      }}
      validationSchema={contactsSchema}
      onSubmit={(values, { resetForm }) => {
        onSubmitHandler(values);
        resetForm();
      }}
    >
      {({ isSubmitting, isValid, dirty, values, errors, touched, ...props }) => {
        const [formErrors, setFormErrors] = useState([]);
            useEffect(() => {
              let updatedErrors = [];
              for (const key in errors  ) {
                if (touched[key] === true) {
                  updatedErrors = [...updatedErrors, key]
                }
              }
              if (!arrayEquals(updatedErrors, formErrors)) {
                setFormErrors(updatedErrors);
              }
            }, [errors, touched]);

            let ErrorMessages = formErrors.map(
              (name, index) => 
                (<ErrorMessage
                  key={name}
                  errorIndex={index+1}
                  msg={errors[name]}
                />)
            )

        return (
          <Form className="contacts-form">
            <div className="contacts-form__input">
              <InputField
                placeholder="Your name"
                icon={iconName}
                type="text"
                name="name"
                errorIndex={formErrors.indexOf('name') + 1}
              />
            </div>

            <div className="contacts-form__input">
              <InputField
                placeholder="Your email"
                icon={iconMail}
                type="email"
                name="email"
                errorIndex={formErrors.indexOf('email') + 1}
              />
            </div>

            <div className="contacts-form__input">
              <InputField
                placeholder="Your message"
                area="textarea"
                icon={iconMessage}
                type="textarea"
                name="message"
                errorIndex={formErrors.indexOf('message') + 1}
              />
            </div>
            <div className="contacts-form__privacy">
              <CheckBox name="privacy" text="Privacy Policy" />
            </div>
            <div className="contacts-form__btn">
              <Button
                mod={'type-1'}
                title={'hello!'}
                type="submit"
                isDisabled={!(isValid && dirty)}
                clickFunction={(e) => console.log('submit')}
              />
            </div>
            <div className="contacts-form__errors">
              {ErrorMessages}
            </div>
          </Form>
        )}}
      </Formik>
  );
}

const mapStateToProps = (state) => ({
  language: state.language,
});

export default connect(mapStateToProps)(contactsFormComponent);
