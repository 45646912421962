import React, { useState, useEffect, useRef } from "react";

import { connect } from "react-redux";
import ItemNewsListComponent from "./ItemNewsListComponent/ItemNewsListComponent";
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { withStyles } from "@material-ui/core/styles";
import "./style.scss";

const StyledPagination = withStyles({
  root: {
    padding: "5.6rem 0",
  },
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#A2B6EF",
      fontSize: "1.6rem",
      background: "#131a60",
      margin: "0 0.4rem",
    },
    "& .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-disabled": {
      opacity: 1,
      "& svg": {
        "& g": {
          "& path": {
            opacity: 0.5,
          },
        },
      },
    },
    "& .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root:hover": {
      "& svg": {
        "& g": {
          "& path": {
            fill: "#FFF",
          },
        },
      },
    },
    "& .MuiPaginationItem-root:hover": {
      background: "#202D72",
      color: "#FFF",
    },
    "& .MuiPaginationItem-ellipsis": {
      background: "transparent",
    },
    "& .Mui-selected": {
      background: "linear-gradient(to right, #0e78db, #015cf5)",
      color: "#FFF",
      pointerEvents: 'none'
    },
    "& .Mui-selected:hover": {
      background: "linear-gradient(to right, #0e78db, #015cf5)",
      pointerEvents: 'none'
    },
    "& .MuiSvgIcon-root": {
      width: "2rem",
      height: "2rem",
      fill: "#FFF",
    },
    "& li:first-child": {
      marginRight: "2rem",
      "& button:first-child": {
        margin: 0,
      },
    },
    "& li:last-child": {
      marginLeft: "2rem",
    },
    "& li": {
      "& button": {
        width: "4.8rem",
        height: "4.8rem",
        margin: 0,
      },
    },
  },
})(Pagination);

const arrowBackComp = () => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>contacts / icon / 16 / arrow copy</title>
      <desc>Created with Sketch.</desc>
      <g
        transform="rotate(180, 8,8)"
        id="contacts-/-icon-/-16-/-arrow-copy"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M7.29289322,5.29289322 C7.65337718,4.93240926 8.22060824,4.90467972 8.61289944,5.20970461 L8.70710678,5.29289322 L12.7071068,9.29289322 C13.0976311,9.68341751 13.0976311,10.3165825 12.7071068,10.7071068 C12.3466228,11.0675907 11.7793918,11.0953203 11.3871006,10.7902954 L11.2928932,10.7071068 L8,7.415 L4.70710678,10.7071068 C4.34662282,11.0675907 3.77939176,11.0953203 3.38710056,10.7902954 L3.29289322,10.7071068 C2.93240926,10.3466228 2.90467972,9.77939176 3.20970461,9.38710056 L3.29289322,9.29289322 L7.29289322,5.29289322 Z"
          id="Path-5"
          fill="#A2B6EF"
          fillRule="nonzero"
          transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
        />
      </g>
    </svg>
  );
};

const arrowForwardComp = () => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>contacts / icon / 16 / arrow copy</title>
      <desc>Created with Sketch.</desc>
      <g
        id="contacts-/-icon-/-16-/-arrow-copy"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M7.29289322,5.29289322 C7.65337718,4.93240926 8.22060824,4.90467972 8.61289944,5.20970461 L8.70710678,5.29289322 L12.7071068,9.29289322 C13.0976311,9.68341751 13.0976311,10.3165825 12.7071068,10.7071068 C12.3466228,11.0675907 11.7793918,11.0953203 11.3871006,10.7902954 L11.2928932,10.7071068 L8,7.415 L4.70710678,10.7071068 C4.34662282,11.0675907 3.77939176,11.0953203 3.38710056,10.7902954 L3.29289322,10.7071068 C2.93240926,10.3466228 2.90467972,9.77939176 3.20970461,9.38710056 L3.29289322,9.29289322 L7.29289322,5.29289322 Z"
          id="Path-5"
          fill="#A2B6EF"
          fillRule="nonzero"
          transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
        />
      </g>
    </svg>
  );
};

const newsSectionListComponent = ({ newsPages }) => {
  const scrollRef = useRef();
  const [pageSize, setPageSize] = useState(12);
  const [page, setPage] = useState(1);
  const [data, setData] = useState(newsPages.slice(0, pageSize));
  const [sceleton, setSceleton] = useState(false);

  useEffect(() => {
    setData(newsPages.slice(0, pageSize));
  }, [pageSize]);

  const handleChange = (e, value) => {
    setSceleton(true);
    window.scrollTo({ top: scrollRef.current.offsetTop - 100 });

    setTimeout(() => {
      setPage(value);
      setPageSize(pageSize);
      setData(newsPages.slice(pageSize * (value - 1), pageSize * value));
      setSceleton(false);
    }, 600);
  };

  return (
    <div className={"news-section-list"} ref={scrollRef}>
      <div className="news-section-list__inner">
        <div className="news-section-list__items">
          {data.map(({ title, text, preview, date, type, link }) => {
            return sceleton ? (
              <div className="news-section-list__sceleton-item" key={link}></div>
            ) : (
              <div className="news-section-list__item" key={link}>
                <ItemNewsListComponent
                  title={title}
                  text={text}
                  img={preview.url}
                  date={date ? date : null}
                  type={type ? type : null}
                  link={link}
                />
              </div>
            );
          })}
        </div>
        <div className="news-pagination">
          <StyledPagination
            count={Math.ceil(newsPages.length / pageSize)}
            renderItem={(item) => (
              <PaginationItem
                slots={{ previous: arrowBackComp, next: arrowForwardComp }}
                {...item}
                variant="text"
                boundaryCount={0}
              />
            )}
            page={page}
            onChange={handleChange}
            variant="outlined"
            shape="rounded"
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  language: state.language,
  isMobile: state.isMobile,
});

export default connect(mapStateToProps)(newsSectionListComponent);
