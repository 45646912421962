import React, { Component } from 'react';
import styles from './style.scss';
import { connect } from 'react-redux';

import image from '../../../img/games/keno-banner.jpg';
import ResizeObserver from 'resize-observer-polyfill'
import { Parallax } from 'react-scroll-parallax';

class GameBottomComponent extends Component {
  state = {
    activeTab: 'features',
  };

  tabSwitch(tab, tabToggler) {
      tabToggler(tab)
    if (this.props.reBuildHandler) this.props.reBuildHandler();
  }

  classToggler(tabs){
      tabs.classList.toggle('tabs_sticky',tabs.getBoundingClientRect().y < 90)
  }

  componentWillReceiveProps(nextProps, nextContext) {
      if(this.props.selectedTab === 'rules' && nextProps.selectedTab === 'features'){
          this.props.reBuildHandler && this.props.reBuildHandler();
      }
  }

    render() {
    const speed = this.props.isMobile ? 0 : 10;
    const { theme, features, rules, isScrolledToTabs, tabToggler, selectedTab } = this.props;


    return (
      <div className={'game-bottom game-bottom_' + theme}>

        <div className="game-bottom__container">
          <div className={'game-bottom__tabs game-bottom__tabs_' + theme + (isScrolledToTabs ? ' hide_tabs' : '')}>
            <div
              onClick={() => this.tabSwitch('features', tabToggler)}
              className={
                'game-bottom__tab game-bottom__tab_' +
                theme +
                (selectedTab === 'features'
                  ? ' game-bottom__tab_active'
                  : '')
              }
            >
              Features
            </div>
            <div
              onClick={() => this.tabSwitch('rules', tabToggler)}
              className={
                'game-bottom__tab game-bottom__tab_' +
                theme +
                (selectedTab === 'rules'
                  ? ' game-bottom__tab_active'
                  : '')
              }
            >
              Rules
            </div>
            <div
              className={
                'game-bottom__tag ' +
                (selectedTab === 'features'
                  ? 'game-bottom__tag_pos_1'
                  : '') +
                (selectedTab === 'rules'
                  ? 'game-bottom__tag_pos_2'
                  : '') +
                (theme ? ' game-bottom__tag_' + theme : '')
              }
            />
          </div>
          <div className="game-bottom__content">
            <div
              className={
                'game-bottom__content-item' +
                (selectedTab === 'features'
                  ? ' game-bottom__content-item_active'
                  : '')
              }
            >
              <div className={"game-bottom__features game-bottom__features_" + theme}>
                  {/* <div className={'game-bottom__decor game-bottom__decor_' + theme}>
                      <div
                          className={'game-bottom__decor-bg game-bottom__decor-bg_' + theme}
                      ></div>
                  </div> */}
                {features.map((feature, index) => (
                  <div
                    className={
                      'game-bottom__feature game-bottom__feature_' + theme
                    }
                    key={index}
                  >
                    <div
                      className={
                        'game-bottom__feature-icon game-bottom__feature-icon_' +
                        theme +
                        ' game-bottom__feature-icon_' +
                        theme +
                        '_' +
                        (index + 1)
                      }
                    >
                      <img src={feature.icon} />
                    </div>
                    <div className={"game-bottom__feature-text game-bottom__feature-text_" + theme}>
                      {feature.text}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div
              className={
                'game-bottom__content-item' +
                (selectedTab === 'rules'
                  ? ' game-bottom__content-item_active'
                  : '')
              }
            >
              <div className="game-bottom__rules">
                {/* <div className="game-bottom__rules-text">{rules.subscribe}</div> */}
                {/* {rules.sections.map(section => (
                                        <div key={section.title} className="game-bottom__rules-section">
                                            {section.title ? <div className="game-bottom__rules-title">{section.title}</div> : null}
                                            {section.text ? <div className="game-bottom__rules-text">{section.text}</div> : null}
                                            {section.image ? <div className="game-bottom__rules-imageWrapper" ><img className="game-bottom__rules-image" src={section.image} /></div> : null}
                                        </div>
                                    ))} */}
                {rules}
              </div>
            </div>
            <div className={`game-bottom__decor game-bottom__decor_${theme}`}>
              <Parallax speed={speed} className={`game-bottom__decor-pseudo game-bottom__decor-pseudo_${theme}`}/>
              <div className={`game-bottom__decor-bg game-bottom__decor-bg_${theme}`}></div>
              <Parallax speed={speed} className={`game-bottom__decor-pseudo game-bottom__decor-pseudo_${theme}`}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.language,
  isMobile: state.isMobile,

});

export default connect(mapStateToProps)(GameBottomComponent);
