import React from 'react'
import styles from "./style.scss"
import {connect} from "react-redux"
import NavButton from "../../CommonComponents/NavButtonComponent/index"
import OurGamesSlider from "./OurGamesSliderComponent/OurGamesSliderComponent"
import 'swiper/swiper.scss';
import {getGames} from "../../../ac/index"
import {changePage} from "../../../ac"

const ourGamesComponent = (props) => {

    const moveTo = section =>{
        props.dispatch(changePage(section))
    };
        
    return(
        <div className={"our-games"}>
            <div  className="part__title-container">
                <h2 className="part__title">Our games</h2>
                <NavButton
                    buttonText={"All games"}
                    actionFunction={() => moveTo('games')}
                />
            </div>
            <div className="our-games__slider">
                <OurGamesSlider />
            </div>
        </div>
    )
    
}

const mapStateToProps = state =>({
    language: state.language
})

export default connect(mapStateToProps)(ourGamesComponent)