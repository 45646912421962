import React, { useState, useEffect } from "react";
import styles from "./style.scss";
import { connect, useDispatch } from "react-redux";
import HeaderComponent from "../CommonComponents/HeaderComponent/HeaderComponent";
import FooterComponent from "../CommonComponents/FooterComponent/FooterComponent";
import GamesHeaderComponent from "./GamesHeaderComponent/GamesHeaderComponent";
import GamesListComponent from "./GamesListComponent/GamesListComponent";
import "fullpage.js/vendors/scrolloverflow.min";
import ReactFullpage from "@fullpage/react-fullpage";
import { loadResources } from "../../util";
import image1 from "../../img/logo-header-def.svg";
import image2 from "../../img/games/keno.png";
import image3 from "../../img/games/ler.jpg";
import image9 from "../../img/games/mw.jpg";
import gilroyBold from "../../fonts/Gilroy/Gilroy-Bold.woff2";
import latoMedium from "../../fonts/Lato/Lato-Medium.woff2";
import LoaderLogo from "../CommonComponents/LoaderLogoComponent";

const gamesPage = (props) => {
  const { isMobile } = props;

  const pageContent = isMobile ? (
    <>
      <HeaderComponent />
      <GamesHeaderComponent />
      <GamesListComponent isMobile={isMobile}/>
      <FooterComponent />
    </>
  ) : (
    <div className={"page__container"}>
      <div className="section">
        <HeaderComponent />
      </div>
      <ReactFullpage
        scrollOverflow={true}
        licenseKey={"YOUR_KEY_HERE"}
        scrollingSpeed={600} /* Options here */
        render={({ state, fullpageApi }) => {
          return (
            <ReactFullpage.Wrapper>
              <div className="section fp-auto-height">
                <GamesHeaderComponent />
                <GamesListComponent />
                <FooterComponent />
              </div>
            </ReactFullpage.Wrapper>
          );
        }}
      />
    </div>
  );

  const dispatch = useDispatch();

  useEffect(() => {
    loadResources([
      ["image", image1],
      ["image", image2],
      ["image", image3],
      ["image", image9],
      ["font", gilroyBold, { name: "Gilroy", style: "normal", weight: "bold" }],
      ["font", latoMedium, { name: "Lato", style: "normal", weight: 500 }],
    ]).then(() => {
      dispatch({ type: "set-resources-loaded", payload: { loaded: true } });
    });
  }, []);
  return (
    <div className={"page"}>
      <LoaderLogo isLoaded={props.loadedResources.loaded} />
      {pageContent}
    </div>
  );
};

const mapStateToProps = (state) => ({
  language: state.language,
  isMobile: state.isMobile,
  loadedResources: state.loadedResources,
});

export default connect(mapStateToProps)(gamesPage);
