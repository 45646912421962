import React, { useState, useEffect } from "react";
import styles from "./style.scss";
import { connect, useDispatch } from "react-redux";
import HeaderComponent from "../CommonComponents/HeaderComponent/HeaderComponent";
import FooterComponent from "../CommonComponents/FooterComponent/FooterComponent";
import ContactsComponent from "./ContactsComponent/ContactsComponent";
import "fullpage.js/vendors/scrolloverflow.min";
import { loadResources } from "../../util";
import image1 from "../../img/logo-header-def.svg";
import image2 from "../../img/logo.svg";
import latoMedium from "../../fonts/Lato/Lato-Medium.woff2";
import latoRegular from "../../fonts/Lato/Lato-Regular.woff2";
import latoBold from "../../fonts/Lato/Lato-Bold.woff2";
import LoaderLogo from "../CommonComponents/LoaderLogoComponent";

const contactssPage = (props) => {
  const [scrollIndex, setScrollIndex] = useState(1);
  const [destinationIndex, setDestinationIndex] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    loadResources([
      ["image", image1],
      ["image", image2],
      ["font", latoMedium, { name: "Lato", style: "normal", weight: 500 }],
      [
        "font",
        latoRegular,
        { name: "Lato", style: "normal", weight: "normal" },
      ],
      ["font", latoBold, { name: "Lato", style: "normal", weight: "bold" }],
    ]).then(() => {
      dispatch({ type: "set-resources-loaded", payload: { loaded: true } });
    });
  }, []);

  const { isMobile } = props;
  const pageContent = isMobile ? (
    <div className={"page__container"}>
      <HeaderComponent />
      {/* TODO: if page Section margin will be the same on mobile version in another pages, need to return this class and remove margin from contacts div */}
      {/* <div className="page__section"> */}
      <ContactsComponent />
      {/* </div> */}

      <FooterComponent />
    </div>
  ) : (
    <div className={"page__container"}>
      <div className="section">
        <HeaderComponent destinationIndex={destinationIndex} />
      </div>
      <div className="section">
        <ContactsComponent />
        {/*<CompanyPartComponent/>*/}
        <FooterComponent />
      </div> 
    </div>
  );

  return (
    <div className={"page"}>
      <LoaderLogo isLoaded={props.loadedResources.loaded} />
      {pageContent}
    </div>
  );
};

const mapStateToProps = (state) => ({
  language: state.language,
  isMobile: state.isMobile,
  loadedResources: state.loadedResources,
});

export default connect(mapStateToProps)(contactssPage);
