import React, { useRef} from 'react'
import styles from "./style.scss"
import {connect} from "react-redux"
import Swiper from 'react-id-swiper';
import { SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import Arrow from "../../CommonComponents/ArrowComponent/index"
import slide1 from '../../../img/partnership-slider/04_SoftLogo.jpg';
import slide2 from '../../../img/partnership-slider/02_Cube.jpg';
import slide3 from '../../../img/partnership-slider/03_City.jpg';
import slide4 from '../../../img/partnership-slider/01_Puzzle.jpg';
import isMobile from '../../../reducers/isMobile';

function PartnershipSlider(props){
    const refMedia = useRef(null);
    const refInfo = useRef(null);

    const sliderContexts = [
        {title: 'Best offer',
        list: [
            "We are flexible when it comes to terms",
            "We are flexible when it comes to technology",
            "We are flexible when it comes to people"
        ],
        image: slide1
    },
        {title: 'Unique development',
        list: [
            "Our own Research and Development Team developed Lotto Machines that are capable of running 24/7",
            "The machines are certified and meet industry standards",
            "As part of creating the standard content delivery network system (CDN), we developed our own space satellite CDN that is allowing us to deliver HD video contents all across the biggest country in the world with eleven time zones"
        ],
        image: slide2
    },
        {title: 'Live gaming expert',
        list: [
            "We are an exclusive bingo provider for the one and only operator in Russia which runs more than 500 betting shops across the country",
            "We make sure that the business gets gaming content 24/7 in HD quality via satellite and internet",
        ],
        image: slide3
    },
        {title: 'A good team',
        list: [
            "We believe that it’s all about a good atmosphere",
            "Positive work environment makes employees feel good",
            "Feeling good helps to deliver good vibes",
            "Good vibes bring in good results",
            "Good results bring in well-performing products to our partners"
        ],
        image: slide4
    }
    ]

    const {isMobile} = props;

    const sliders = isMobile ? (
        <Swiper
            loop={true}
            ref={refMedia}
            slidesPerView={1}
            pagination={{ clickable: true, el: '.partnership-slider__pagination'}}
            spaceBetween={16}
        >

            {sliderContexts.map(slider => (
                <SwiperSlide key={slider.title}>
                    <div className="partnership-slider__media">
                        <div className={"partnership-slider__media-slide"}>
                            <img className="partnership-slider__media-img" src={slider.image}/>
                        </div>
                    </div>
                    <div className="partnership-slider__info">
                        <div className={"partnership-slider__info-slide"}>
                            <div className="partnership-slider__slide-title">{slider.title}</div>
                            <div className="partnership-slider__slide-text">
                                <ul className="partnership-slider__slide-list">
                                    {slider.list.map((elem, index) => (
                                        <li className="partnership-slider__slide-listItem" key={`${slider.title}_${index}`}>{elem}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            ))}
            
        </Swiper>
    ) : (
        <>
            <div className="partnership-slider__info">
                <div className="partnership-slider__arrows">
                    <div className="partnership-slider__arrow partnership-slider__arrow_prew">
                        <Arrow
                            sliderFunction={() => {
                                refMedia.current.swiper.slidePrev();
                                refInfo.current.swiper.slidePrev();
                            }}
                            direction="prev"
                        />
                    </div>
                    <div className="partnership-slider__arrow partnership-slider__arrow_next">
                        <Arrow
                            sliderFunction={() => {
                                refMedia.current.swiper.slideNext();
                                refInfo.current.swiper.slideNext();
                            }}
                            direction="next"
                        />
                    </div>
                </div>
                <Swiper
                    loop={true}
                    ref={refInfo}
                    slidesPerView={1}
                    pagination={{ clickable: true, el: '.partnership-slider__pagination'}}
                    noSwiping={true}
                    on={{
                        slideChange: state =>{
                            refInfo.current.swiper && refInfo.current.swiper.slideTo(state.activeIndex);
                        }
                    }}
                    speed= {640}
                >

                    {sliderContexts.map(slider => (
                        <SwiperSlide key={slider.title}>
                            <div className={"partnership-slider__info-slide"}>
                                <div className="partnership-slider__slide-title">{slider.title}</div>
                                <div className="partnership-slider__slide-text">
                                    <ul className="partnership-slider__slide-list">
                                        {slider.list.map((elem, index) => (
                                            <li className="partnership-slider__slide-listItem" key={`${slider.title}_${index}`}>{elem}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                    
                </Swiper>
            </div>
            <div className="partnership-slider__media">
                <Swiper
                    loop={true}
                    ref={refMedia}
                    slidesPerView={1}
                    noSwiping={true}
                    on={{
                        slideChange: state =>{
                            refMedia.current.swiper && refMedia.current.swiper.slideTo(state.activeIndex);
                        }
                    }}
                    speed= {640}
                >
                    {sliderContexts.map(({image}) => (
                        <SwiperSlide key={image}>
                            <div className={"partnership-slider__media-slide"}>
                                <img className="partnership-slider__media-img" src={image}/>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </>

    )


    return(
        <div className="partnership-slider">
            <div className="partnership-slider__container">
                {sliders}
            </div>
        </div>
    )
}

const mapStateToProps = state =>({
    language: state.language,
    isMobile: state.isMobile
})

export default connect(mapStateToProps)(PartnershipSlider)
