import React from 'react'
import  {render} from 'react-dom'
import {Provider} from 'react-redux'
import store from "./store"
import MainPage from "./components/MainPage/MainPage"
import { ParallaxProvider } from 'react-scroll-parallax';

import {
    BrowserRouter
} from "react-router-dom"
import {createBrowserHistory} from 'history';

import { gsap } from 'gsap'
import { CSSPlugin } from 'gsap/CSSPlugin'

// Force CSSPlugin to not get dropped during build
gsap.registerPlugin(CSSPlugin);

const history = createBrowserHistory();

render(
        <Provider store = {store}>
            <ParallaxProvider>
                <BrowserRouter history={history}>
                    <MainPage/>
                </BrowserRouter>
            </ParallaxProvider>
        </Provider>
        , document.getElementById('root')
);