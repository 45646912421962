import React, {Component} from 'react'
import styles from "./style.scss"
import {connect} from "react-redux"
import { Parallax } from 'react-scroll-parallax';

import GameDevicesImg from "./GameDevicesImgComponent/GameDevicesImgComponent"


class GameDevicesComponent extends Component{

    state = {
        activeDevice: "desktop",
    };


    
    render(){
        const speed = this.props.isMobile ? 0 : 10;

        const switchdevice = device =>{
            this.setState({
                activeDevice: device
            })
        };
        const {theme, desktopIcon, mobileIcon, tabletIcon, deviceDesktop, deviceTablet, deviceMobile, slidesDesktop, slidesTablet, slidesMobile} = this.props;

        return(
                <div className={"game-devices" + (theme ? " game-devices_" + theme : "")}>
                    <div className="game-devices__container">
                        <h2 className="game-devices__title">Play on any device</h2>
                        <div className={"game-devices__select game-devices__select_" + theme}>
                            <div onClick={() => switchdevice("desktop")} className={"game-devices__select-item game-devices__select-item_" + theme + (this.state.activeDevice === "desktop" ? " game-devices__select-item_active": "")}>
                                <img className="game-devices__select-item-img" src={desktopIcon}/>
                            </div>

                            <div onClick={() => switchdevice("mobile")} className={"game-devices__select-item game-devices__select-item_" + theme +  (this.state.activeDevice === "mobile" ? " game-devices__select-item_active": "")}>
                                <img className="game-devices__select-item-img" src={tabletIcon}/>
                            </div>

                            <div onClick={() => switchdevice("tablet")} className={"game-devices__select-item game-devices__select-item_" + theme + (this.state.activeDevice === "tablet" ? " game-devices__select-item_active": "")}>
                                <img className="game-devices__select-item-img" src={mobileIcon}/>
                            </div>
                        </div>
                        <div className={"game-devices__images game-devices__images_" + theme}>
                            {/* <div> */}
                                <Parallax speed={speed} className={`game-devices__images-pseudo game-devices__images-pseudo_${theme}`}/>
                                <Parallax speed={speed} className={`game-devices__images-pseudo game-devices__images-pseudo_${theme}`}/>
                            {/* </div> */}
                            <div className={"game-devices__img-container" + (this.state.activeDevice === "desktop" ? " game-devices__img-container_active" : "")}>
                                <GameDevicesImg
                                    theme = {theme}
                                    src = {deviceDesktop}
                                    slides = {slidesDesktop}
                                />
                            </div>

                            <div className={"game-devices__img-container" +  (this.state.activeDevice === "mobile" ? " game-devices__img-container_active" : "")}>
                                <GameDevicesImg
                                    theme = {theme}
                                    src = {deviceTablet}
                                    slides = {slidesTablet}
                                />
                            </div>

                            <div  className={"game-devices__img-container" + (this.state.activeDevice === "tablet" ? " game-devices__img-container_active" : "")}>
                                <GameDevicesImg
                                    theme = {theme}
                                    src = {deviceMobile}
                                    slides = {slidesMobile}
                                />
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

const mapStateToProps = state =>({
    language: state.language,
    isMobile: state.isMobile,
})

export default connect(mapStateToProps)(GameDevicesComponent)
