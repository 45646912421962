import React, {Component} from 'react'
import styles from "./style.scss"
import {connect} from "react-redux"
import topBg from "../../../img/top-bg.jpg"



class CompanyTopComponent extends Component{

    render(){

        return(
            <div className="company-top">
                <img className="company-top__bg" src={topBg}/>
                <div className="company-top__container">
                    <div className="company-top__title">Atmosfera is a company</div>
                    <div className="company-top__vacancyInfo">Open position:  <span>{this.props.vacancies}</span></div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state =>({
    language: state.language
})

export default connect(mapStateToProps)(CompanyTopComponent)
