import React from 'react'
import styles from "./style.scss"
import {connect, useDispatch} from "react-redux"
import NavButton from "../../CommonComponents/NavButtonComponent/index"
import {changePage} from "../../../ac"

import Swiper from 'react-id-swiper';
import { SwiperSlide } from 'swiper/react';
import AtmosferaBanner from '../../../img/new-pages/news-16/atmosferaBanner.jpg';

import betandbeatBanner from "../../../img/new-pages/news-17/betandbeatBanner.jpg";

const newsComponent =({isMobile, newsPages}) => {
    const dispatch = useDispatch();
    const moveTo = section =>{
        dispatch(changePage(section))
    }

    let news = newsPages.map(({title, banner_mob, banner, link, text, preview})=> (
        <a className="news__item" href={link} key={link}>
        <img className="news__img" src={preview.url} alt={title}/>
        <div className="news__item-content">
            <div className="news__item-title">
                {title}
            </div>
            <div className="news__item-text">
                {text}
            </div>
            <div className="news__item-link">
                <NavButton
                    actionFunction={() => moveTo(link)}
                    buttonText="Read more"
                    textmod="dense"
                />
            </div>
        </div>
    </a>
    ))

    if (isMobile) news = (
        <Swiper
            spaceBetween={16}
            autoplay={{ delay: 4000, disableOnInteraction: false}}
            slidesPerView={'auto'}
            centeredSlides={true}
            >
            {news.map((elem,i) => (
                <SwiperSlide key={`swiper${i}`}>
                    {elem}
                </SwiperSlide>
            ))}
        </Swiper>
    )

    return (
        <div className="news">
            <div className="part__title-container">
                <h2 className="part__title">LATEST NEWS</h2>
                <NavButton
                    actionFunction={() => moveTo("news")}
                    buttonText={"All news"}
                />
            </div>

            <div className="news__inner">
                {news}           
            </div>
        </div>
    )
}

const mapStateToProps = state =>({
    language: state.language,
    isMobile: state.isMobile
})

export default connect(mapStateToProps)(newsComponent)