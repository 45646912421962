import React, { useState } from 'react'
import styles from "./style.scss"
import {connect} from "react-redux"
import {  SwiperSlide } from 'swiper/react';
import Swiper from 'react-id-swiper';

import studio1 from '../../../img/studio/studio-img_1.jpg';
import studio1Webp from '../../../img/studio/studio-img_1.webp';
import studio1Mobile from '../../../img/studio/mobile/studio-img_1.jpg';
import studio1MobileWebp from '../../../img/studio/mobile/studio-img_1.webp';

import game1 from '../../../img/studio/studio-img_2.jpg';
import game1Webp from '../../../img/studio/studio-img_2.webp';
import game1Mobile from '../../../img/studio/mobile/studio-img_2.jpg';
import game1MobileWebp from '../../../img/studio/mobile/studio-img_2.webp';

import game2 from '../../../img/studio/studio-img_3.jpg';
import game2Webp from '../../../img/studio/studio-img_3.webp';
import game2Mobile from '../../../img/studio/mobile/studio-img_3.jpg';
import game2MobileWebp from '../../../img/studio/mobile/studio-img_3.webp';

import icon1 from "../../../img/studio/studio-icon-1.svg"
import icon2 from "../../../img/studio/studio-icon-2.svg"
import icon3 from "../../../img/studio/studio-icon-3.svg"
import iconMob1 from "../../../img/studio/studio-icon-mob-1.svg"
import iconMob2 from "../../../img/studio/studio-icon-mob-2.svg"
import iconMob3 from "../../../img/studio/studio-icon-mob-3.svg"

const studioComponent = (props) => {

    const [activeStudio, setActiveStudio] = useState(0)

    const toggleStudio = index =>{
        setActiveStudio(index)
    }

    const studioItems = [
        {
            title: 'Game Presenters Academy',
            text: 'The place where committed trainers grow talents, help professionals improve and succeed.',
            image: studio1,
            imageWebp: studio1Webp,
            imageMobile: studio1Mobile,
            imageMobileWebp: studio1MobileWebp,
            icon: icon1,
            iconMob: iconMob1,
        },
        {
            title: 'Dedicated Supervision Team',
            text: 'We conduct the continuing oversight of studio work thanks to individual controlling of each studio room.',
            image: game1,
            imageWebp: game1Webp,
            imageMobile: game1Mobile,
            imageMobileWebp: game1MobileWebp,
            icon: icon2,
            iconMob: iconMob2,
        },
        {
            title: 'Inclusive Design',
            text: 'Design developed down to the smallest detail and adapted according to the latest innovations and trends.',
            image: game2,
            imageWebp: game2Webp,
            imageMobile: game2Mobile,
            imageMobileWebp: game2MobileWebp,
            icon: icon3,
            iconMob: iconMob3,
        }
    ]


    const studioJSX = props.isMobile ? 
        (<div className="studio__inner">
            <Swiper 
                spaceBetween={16}
                slidesPerView={'auto'}
                centeredSlides={true}
                >
                {studioItems.map((item, index) => (
                    <SwiperSlide key={item.title}>
                        <div className="studio__item__mobile">
                            <div className={"studio__img-cont  studio__img-cont_active"} key={item.image}>
                                <picture>
                                    <source srcset={item.imageMobileWebp} type="image/webp" />
                                    <img src={item.imageMobile} alt={item.title} className="studio__img" />
                                </picture>
                            </div>
                            <div className={`studio__item studio__item_active`} key={item.title}>
                                <div className="studio__item-img" style={{'backgroundImage': `url(${item.iconMob})`}}></div>
                                <div className="studio__item-content">
                                    <div className="studio__item-title">{item.title}</div>
                                    <div className="studio__item-text">{item.text}</div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
        ) : (
        <div className="studio__inner">
            <div className="studio__items">
                {studioItems.map((item, index) => (
                    <div 
                        onClick={() => toggleStudio(index)} 
                        className={"studio__item" + (activeStudio === index ? " studio__item_active" : "")}
                        key={item.title}>
                        <div className="studio__item-img" style={{'backgroundImage': `url(${item.icon})`}}></div>
                        <div className="studio__item-content">
                            <div className="studio__item-title">{item.title}</div>
                            <div className="studio__item-text">{item.text}</div>
                        </div>
                    </div>)
                )}
            </div>

            <div className="studio__images">
                {studioItems.map((item, index) => (
                    <div className={"studio__img-cont" + (activeStudio === index ? " studio__img-cont_active" : "")} key={item.image}>
                        <picture >
                            <source srcset={item.imageWebp} type="image/webp" />
                            <img src={item.image} alt={item.title} className="studio__img" />
                        </picture>
                    </div>)
                )}
            </div>
        </div>)

    

    return(
        <div className="studio">
            <div  className="part__title-container">
                <h2 className="part__title">OUR STUDIO</h2>
            </div>
            {studioJSX}
        </div>
    )
}

const mapStateToProps = state =>({
    language: state.language,
    isMobile: state.isMobile
})

export default connect(mapStateToProps)(studioComponent)