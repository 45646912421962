import React, {useRef} from 'react'
import {connect} from "react-redux"
import { useHistory } from 'react-router'

import styles from "./style.scss"

import PrevButton from "../../CommonComponents/PrevButtonComponent/index"
import CloseButton from '../../CommonComponents/CloseButtonComponent'

const gameTop = (props) => {

    const {banner, name, text, theme, isMobile, parentRef, demo} = props;

    const history = useHistory();

    const topComponent = useRef(null);

    const button = isMobile ? 
    (<CloseButton
            actionFunction={()=> history.push('/games')}
            theme = {theme}
        />
    ) : (
    <PrevButton
        title={"read more"}
        actionFunction={()=> history.push('/games')}
        mod = {theme}
    />)

    return(
        <div ref={topComponent} className="game-top">
            <img className={"game-top__img" + (" game-top__img_" + theme )} src={banner}/>
            <div className={"game-top__container game-top__container_" + theme}>
                <div className="game-top__content">
                    <div className={"game-top__btn game-top__btn_back game-top__btn_" + theme}>
                        {button}
                    </div>
                    <h1 className="game-top__title">
                        {name}
                    </h1>
                    <p test className="game-top__text">
                        {text}
                    </p>
                    {demo && <a href={demo} className={"game-top__demo game-top__demo_" + theme}>
                        play demo
                    </a>}

                    {/*<div className="game-top__btns">*/}
                        {/*<div className="game-top__btn game-top__btn_demo">*/}
                            {/*<Button*/}
                                {/*mod = {"type-1_" + theme}*/}
                                {/*title={"play demo"}*/}
                            {/*/>*/}
                        {/*</div>*/}

                    {/*</div>*/}
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = state =>({
    language: state.language,
    isMobile: state.isMobile
})

export default connect(mapStateToProps)(gameTop)
