import React, {Component} from 'react'
import styles from "./style.scss"
import {connect} from "react-redux"
import Button from "../../../CommonComponents/ButtonComponent/index"


class PartnerItemComponent extends Component{

    render(){
        const {title, date, logo, theme, link} = this.props;
        return(
            <div className={"partner-item partner-item_" + theme}>
                <div className={"partner-item__logo partner-item__logo_" + theme}>
                    <img className={"partner-item__img partner-item__img_" + theme} src={logo}/>
                </div>
                <div className={"partner-item__title partner-item__title_" + theme}>
                    {title}
                </div>
                <div className={"partner-item__date partner-item__date_" + theme}>
                    {date}
                </div> 
            </div>
        )
    }
}

const mapStateToProps = state =>({
    language: state.language
})

export default connect(mapStateToProps)(PartnerItemComponent)