import React, { useEffect, useRef, useState } from "react";
import styles from "./style.scss";
import { connect, useDispatch } from "react-redux";
import HeaderComponent from "../CommonComponents/HeaderComponent/HeaderComponent";
import FooterComponent from "../CommonComponents/FooterComponent/FooterComponent";

import PartnershipTopComponent from "../PartnershipPage/PartnershipTopComponent/PartnershipTopComponent";
import PartnershipSliderComponent from "../PartnershipPage/PartnershipSliderComponent/PartnershipSliderComponent";
import PartnerBecomeComponent from "../PartnershipPage/PartnerBecomeComponent/PartnerBecomeComponent";
import PartnershipFormComponent from "../PartnershipPage/PartnershipFormComponent/PartnershipFormComponent";

import "fullpage.js/vendors/scrolloverflow.min";
import ReactFullpage from "@fullpage/react-fullpage";

import {loadResources} from '../../util';
import image1 from '../../img/logo-header-def.svg';
import image2 from '../../img/partnership-slider/04_SoftLogo.jpg';
import image3 from '../../img/partner-become/partner-1.svg';
import image4 from '../../img/partner-become/partner-2.svg';
import image5 from '../../img/partner-become/partner-3.svg';
import image6 from '../../img/partner-become/partner-4.svg';
import image7 from '../../img/partner-become/partner-5.svg';
import image8 from '../../img/partner-become/partner-6.svg';
import gilroyBold from '../../fonts/Gilroy/Gilroy-Bold.woff2';
import latoRegular from '../../fonts/Lato/Lato-Regular.woff2';
import LoaderLogo from "../CommonComponents/LoaderLogoComponent";

const partnershipPage = (props) => {
  const { isMobile, submitResult } = props;

  const fullPageRef = useRef();
  const becomeRef = useRef();
  const formRef = useRef();
  const sectionRef = useRef();

  useEffect(() => {
    if (submitResult && submitResult.status) {
      // delay for animation
      setTimeout(() => {
        if (isMobile) {
          window.scrollTo({
            top: becomeRef.current.offsetTop - 200,
            behavior: "smooth",
          });
        } else {
          const iscrollInstance = sectionRef.current.querySelector(
            ".fp-scrollable"
          ).fp_iscrollInstance;
          const scrollPosition = -becomeRef.current.offsetTop + 100;
          iscrollInstance.scrollTo(0, scrollPosition, 1000);
        }
      }, 1500);
    }
  }, [submitResult]);

  const pageContent = isMobile ? (
    <>
      <HeaderComponent />
      <PartnershipTopComponent />
      <PartnershipSliderComponent />
      <div ref={becomeRef}>
        <PartnerBecomeComponent />
      </div>
      <PartnershipFormComponent />
      <FooterComponent />
    </>
  ) : (
    <div className={"page__container"}>
      <div className="section">
        <HeaderComponent />
      </div>
      <ReactFullpage
        ref={fullPageRef}
        scrollOverflow={true}
        licenseKey={"YOUR_KEY_HERE"}
        scrollingSpeed={600}
        scrollBar={true}
        render={(props) => {
          return (
            <ReactFullpage.Wrapper>
              <div className="section fp-auto-height" ref={sectionRef}>
                <PartnershipTopComponent />
                <PartnershipSliderComponent />
                <div className="become__section" ref={becomeRef}>
                  <PartnerBecomeComponent />
                </div>
                <div className=" page__main-container" ref={formRef}>
                  <PartnershipFormComponent />
                </div>
                <FooterComponent />
              </div>
            </ReactFullpage.Wrapper>
          );
        }}
      />
    </div>
  );

  const dispatch = useDispatch();
  useEffect(() => {
    loadResources([
      ["image", image1],
      ["image", image2],
      ["image", image3],
      ["image", image4],
      ["image", image5],
      ["image", image6],
      ["image", image7],
      ["image", image8],
      ["font", gilroyBold, { name: "Gilroy", style: "normal", weight: 900 }],
      ["font", gilroyBold, { name: "Gilroy", style: "normal", weight: 700 }],
      [
        "font",
        latoRegular,
        { name: "Lato", style: "normal", weight: "normal" },
      ],
    ]).then(() => {
      dispatch({
        type: "set-resources-loaded",
        payload: { loaded: true },
      });
    });
  }, []);
  return (
    <div className={"page"}>
      <LoaderLogo isLoaded={props.loadedResources.loaded} />
      {pageContent}
    </div>
  );
};

const mapStateToProps = (state) => ({
  language: state.language,
  isMobile: state.isMobile,
  submitResult: state.submitForm.partnershipForm.submitResult,
  loadedResources: state.loadedResources,
});

export default connect(mapStateToProps)(partnershipPage);
