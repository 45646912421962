import React,{useEffect, useState} from 'react'
import styles from "./style.scss"
import {connect, useDispatch} from "react-redux"
import Button from "../../CommonComponents/ButtonComponent/index"
import gameBanner from '../../../img/new-pages/news-3/banner.jpg';
import { changePage } from '../../../ac';
import {url} from "../../../../src/util"

const newsSectionTop = ({mainNewsPageId, isMobile}) => {
    const dispatch = useDispatch();

    const moveTo = section =>{
        dispatch(changePage(section))
    };
    const [articleData, articleDataSet] = useState({});

    useEffect(() =>{
        (async () => {
            mainNewsPageId && articleDataSet(await fetch(`${url}/news-posts/${mainNewsPageId}`).then(response => response.json()) || {});
        })()
    }, [mainNewsPageId]);

    const bannerName = isMobile ? 'banner_mob': 'banner';

    const banner = articleData && articleData[bannerName] && articleData[bannerName].url;

    return(

        <div className="news-section-top">
            <div className="news-section-top__banner" style={{
                backgroundImage:`url(${banner})`,
                }} />
            <div className="news-section-top__container">
                <div className="news-section-top__content">
                    <div className="news-section-top__title">
                        {articleData.title}
                    </div>
                    <div className="news-section-top__text">
                        {articleData['preview_article']}
                    </div>

                    <div className="news-section-top__btns">
                        <div className="news-section-top__btn news-section-top__btn_demo">
                            <Button
                                mod={isMobile ? "" : "type-1"}
                                title={"read more"}
                                clickFunction={()=> moveTo(`news/news-${mainNewsPageId}`)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

const mapStateToProps = state =>({
    language: state.language,
    isMobile: state.isMobile
})

export default connect(mapStateToProps)(newsSectionTop)