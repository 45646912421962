import React, { Component } from "react";
import styles from "./style.scss";
import { connect } from "react-redux";
import GamePage from "../GamePage/GamePage";
import gameBanner from "../../img/game-page/BJ/banner.jpg";
import gameBannerMobile from "../../img/game-page/BJ/bannerMobile.jpg";

import desktopIcon from "../../img/game-page/BJ/laptop.svg";
import tabletIcon from "../../img/game-page/BJ/ipad.svg";
import mobileIcon from "../../img/game-page/BJ/phone.svg";
import deviceDesktop from "../../img/game-page/BJ/device-desktop.png";
import deviceTablet from "../../img/game-page/BJ/device-tablet.png";
import deviceMobile from "../../img/game-page/BJ/device-mobile.png";

import slideDesktop1 from "../../img/game-page/BJ/gallery-desktop/slide-1.png";
import slideDesktop2 from "../../img/game-page/BJ/gallery-desktop/slide-2.png";
import slideDesktop3 from "../../img/game-page/BJ/gallery-desktop/slide-3.png";

import slideTablet1 from "../../img/game-page/BJ/gallery-tablet/slide-1.png";
import slideTablet2 from "../../img/game-page/BJ/gallery-tablet/slide-2.png";
import slideTablet3 from "../../img/game-page/BJ/gallery-tablet/slide-3.png";

import slideMobile1 from "../../img/game-page/BJ/gallery-mobile/slide-1.png";
import slideMobile2 from "../../img/game-page/BJ/gallery-mobile/slide-2.png";
import slideMobile3 from "../../img/game-page/BJ/gallery-mobile/slide-3.png";

import feature1 from "../../img/game-page/BJ/features/feature1.svg";
import feature2 from "../../img/game-page/BJ/features/feature2.svg";
import feature3 from "../../img/game-page/BJ/features/feature3.svg";

import partner1 from "../../img/game-partners/BETB2B.svg";
import partner3 from "../../img/game-partners/1win.svg";
import partner4 from "../../img/game-partners/pronet.svg";
import partner5 from "../../img/game-partners/infinGame.svg";
import partner6 from "../../img/game-partners/betconstruct.svg";
import partner7 from "../../img/game-partners/softswiss.svg";
import partner8 from "../../img/game-partners/parimatch.svg";
import partner9 from "../../img/game-partners/softgaming.svg";
import partner11 from "../../img/game-partners/top-play-gaming.svg";

import rules1 from "../../img/rules/bj/insurance.svg";
import rules2 from "../../img/rules/bj/stand.svg";
import rules3 from "../../img/rules/bj/hit.svg";
import rules4 from "../../img/rules/bj/double.svg";
import rules5 from "../../img/rules/bj/split.svg";
import rules6 from "../../img/rules/bj/history.svg";

import { loadResources } from "../../util";
import logo from "../../img/logo-header-ler.svg";
import gilroyBold from "../../fonts/Gilroy/Gilroy-Bold.woff2";
import PTRootUIMedium from "../../fonts/PTRootUI/PTRootUI-Medium.woff2";
import LoaderLogo from "../CommonComponents/LoaderLogoComponent";

const partners = [
  {
    title: "1win",
    date: "2023",
    logo: partner3,
  },
  {
    title: "SoftSwiss",
    date: "2023",
    logo: partner7,
  },
  {
    title: "PariMatch",
    date: "2023",
    logo: partner8,
  },
  {
    title: "SoftGamings",
    date: "2023",
    logo: partner9,
  },
  {
    title: "BetB2B",
    date: "2023",
    logo: partner1,
  },
  {
    title: "Betconstruct",
    date: "2023",
    logo: partner6,
  },
  {
    title: "ProNetGaming",
    date: "2023",
    logo: partner4,
  },
  {
    title: "infingame",
    date: "2023",
    logo: partner5,
  },
  {
    title: "TopPlayGaming",
    date: "2023",
    logo: partner11,
  },
];

const rules = (
  <div>
    <p>
      Blackjack is one of the most popular card games in casinos all around the
      world. The high popularity of Blackjack is commonly attributed with the
      high speed of the game and the simplicity of its rules. We invite you to
      take part in a dynamic and exciting game Atmosfera Blackjack.
    </p>
    <h1>Game</h1>
    <p>
      The goal of the game is to score more points for your cards than the
      dealer scores without exceeding 21. The number of points scored by other
      players does not matter. Blackjack is considered the highest hand - a
      combination in which the sum of the points of the player's two initial
      cards is 21.
    </p>
    <h1>Card value</h1>
    <p>
      8 standard decks of 52 cards each are used for the game. The cards have
      the following nominal values:
    </p>
    <ol style={{ marginBottom: "5rem" }}>
      <li>Score of the cards from 2 to 10 is equal to their nominal value.</li>
      <li>Figure cards (Jack, Queen and King) give 10 points.</li>
      <li>
        The nominal value of an Ace is 1 or 11, depending on what suits your
        hand best.
      </li>
    </ol>
    <h1>Participation in the Game</h1>
    <p>
      To participate in the game, you need to take a free seat at the table and,
      during the betting phase, make a bet that would be equal to or would
      exceed the minimum allowable bet at the table (you can view table limits
      in the game interface). You can occupy from 1 to 7 seats at the same
      table. At that, you are required to make a bet of the same amount for each
      seat.
    </p>
    <p>
      At the end of the acceptance of bets, the dealer deals to the players who
      made the bet, one open card each. The deal goes clockwise, starting with
      the player to the left of the dealer. The dealer deals the last card to
      himself. The dealer then deals a second upcard to each player. The
      dealer's second card remains closed - it will be opened only after all
      players have made decisions on their hands.
    </p>
    <h1>Insurance</h1>
    <p>
      If the dealer's face-up card is an ace, the players are encouraged to
      purchase insurance. This insurance allows you to compensate for the risks
      associated with the fact that the dealer may have Blackjack. The insurance
      amount is equal to the half of your main bet (or the half of the total bet
      amount if you occupy more than one seat at the table). When all the
      players have decided on insurance, the dealer should check their
      downturned card to see if they have Blackjack. If the dealer does not have
      Blackjack, the game continues and the insurance amounts of the players are
      burned down. If the dealer has Blackjack, the round ends, but you will
      receive the insurance payout equal to 2:1 of the insurance amount. If you
      also have Blackjack on your hand, in addition to the insurance payout, you
      will receive back your main bet on the hand with the Blackjack
      combination.
    </p>
    <p>
      In those situations where the possibility of the insurance purchase is
      provided for, the game interface includes additional buttons allowing to
      make the correspondent decision. Click on “Yes” if you want to insure your
      bet, or “No” if you prefer to decline the insurance option:
    </p>
    <img className="BJ-buttons" src={rules1} />
    <p>
      Please note that if the dealer's upturned card is a 10 (or any
      picture-card), players will not be proposed the insurance option. The game
      will continue as usual.
    </p>
    <h1>Actions of the Player</h1>
    <p>
      After the dealer has dealt two cards to the players (unless the dealer has
      Blackjack after checking the second card), the players can take turns
      trying to improve the amount of points in their hands. To do this, the
      dealer asks all players, starting with the player to his left, whether the
      player wants to take additional actions with his hand. When it's your
      turn, you will have 15 seconds to make a decision. Depending on the
      situation, 4 action options may be available to you: Stop, Draw a card,
      Double or Split the hand.
    </p>
    <p>
      A perfect score is considered to be 21 points. Upon reaching this score,
      there are no more additional actions proposed to the players.
    </p>
    <h1>Stand</h1>
    <p>
      If you are satisfied with your total score, you can choose the “Stand”
      option. In such a case, the dealer will not handle an additional card to
      you, and the turn will go to the next player (or to the dealer if there
      are no players after you).
    </p>
    <p>
      If you decide to stop, inform the dealer of your decision by pressing the
      “Stand” button in the game interface:
    </p>
    <img className="BJ-buttons" src={rules2} />
    <h1>Hit</h1>
    <p>
      This option means adding an extra card with the possibility to ask for one
      more card later on (or to stop if you are satisfied with the total score
      gained). Thus, you can get a few extra cards until you decide to stop.
      However, if the total score for the hand exceeds 21, this hand will be
      considered beaten with the excess of points (Bust) and the bet will be
      burnt down.
    </p>
    <p>
      If you decide to take an additional card, inform the dealer of your
      decision by pressing the “Hit” button in the game interface:
    </p>
    <img className="BJ-buttons" src={rules3} />
    <h1>Double</h1>
    <p>
      If, after receiving two original cards, you are sure that one additional
      card will be enough for you, you can choose the Double option. This option
      involves doubling the bet on this hand and adding only one additional
      card, after which the turn will automatically pass to the next player.
      However, if the sum of points on this hand exceeds 21, the hand will be
      considered lost with a bust (Bust), and the bet will be burned.
    </p>
    <p>
      If you decide to double your bet, inform the dealer of your decision by
      pressing the “Double” button in the game interface:
    </p>
    <img className="BJ-buttons" src={rules4} />
    <p>
      Please note that this action is only available if you have enough funds
      for another bet on your account.
    </p>
    <h1>Split</h1>
    <p>
      If your two initial cards have the equal nominal value, you can choose the
      Split Hand option. In such a case, your bet on the hand will be doubled,
      and the original pair of cards will be split into two separate hands, each
      with its own bet. Then the dealer will handle one more card for each of
      these hands. After that you will have an opportunity to decide on each of
      the hands in turn (starting with the right hand). You can choose to Hit or
      to Stand. However, if you split a pair of aces, you will only get one
      extra card per hand without the option to pick another card.
    </p>
    <p>
      If you decide to split your hand, inform the dealer of your decision by
      pressing the “Split” button in the game interface:
    </p>
    <img className="BJ-buttons" src={rules5} />
    <p>Please note:</p>
    <ol style={{ marginBottom: "5rem" }}>
      <li style={{ marginBottom: "3.2rem" }}>
        Please note: You can split each initial hand only once. It means that,
        if after splitting you have a pair of cards of an equal nominal value
        again, you won’t be able to split it once more.
      </li>
      <li style={{ marginBottom: "3.2rem" }}>
        The “Double” option is not available after splitting a hand.
      </li>
      <li style={{ marginBottom: "3.2rem" }}>
        Picture-cards and tens have the same nominal value. If your initial hand
        consists of any two picture-cards or a picture-card and a ten, you can
        also split it.
      </li>
      <li style={{ marginBottom: "3.2rem" }}>
        After splitting a pair of aces, you will get only one extra card for
        each hand. Then the turn will go to the next player.
      </li>
      <li style={{ marginBottom: "3.2rem" }}>
        Blackjack is only possible if the total score for your initial hand
        equals 21. 21 points received after splitting, are not considered
        constituting Blackjack.
      </li>
      <li style={{ marginBottom: "3.2rem" }}>
        The “Split” option is only available if you have enough funds for
        another bet on your account.
      </li>
    </ol>
    <h1>Play of the Dealer</h1>
    <p>
      Once all the players have made decisions related to their hands, the turn
      goes to the dealer. In their turn, the dealer upturns the second card. If
      the total score for the dealer’s cards is less than 17, the dealer is
      required to draw an extra card until the score is equal to or exceeds 17.
      As soon as the dealer scores enough points, the drawing results are
      summarized.
    </p>
    <h1>Possible Outcomes</h1>
    <p>
      If, after adding an extra card, the total score for your hand exceeds 21,
      this is the excess of points (Bust). You lose your bet for the hand.
    </p>
    <p>
      You win if the final total score for your hand is higher than the final
      total score for the dealer's hand and does not exceed 21 points. Note that
      the Blackjack combination counts higher than 21 points received for 3 or
      more cards.
    </p>
    <p>
      You also win if the dealer has the excess of points (Bust) and the total
      score for your hand does not exceed 21.
    </p>
    <p>
      If your hand and the dealer's hand are equally high (the same score / both
      you and the dealer have Blackjack), the round ends in a draw (Push) and
      you get the bet refund.
    </p>
    <p>If the dealer's hand is higher than yours, your bet loses.</p>
    <h1>Payouts</h1>
    <ol style={{ marginBottom: "5rem" }}>
      <li>
        A Blackjack winning is paid out with a ratio of 3:2.
      </li>
      <li>
        A winning (without Blackjack) is paid out with a ratio of 1:1.
      </li>
      <li>
        An insured winning is paid out with a ratio of 2:1 (of the insurance
        amount).
      </li>
      <li>
        In case of a push you receive the bet refund.
      </li>
    </ol>
    <h1>History of Draws</h1>
    <p>
      The results of the previous draws in which you took part are available in
      the History tab. In it you'll find a list of the rounds you've bet on, as
      well as a detailed history of each round, including game results and any
      actions you've taken.
    </p>
    <p>
      The tab “History of Draws” opens by clicking the appropriate button in the
      game interface. To expand the detailed history of an individual draw,
      click on the draw of interest in the list:
    </p>
    <img className="BJ-buttons" src={rules6} />
    <h1>Card Shuffling</h1>
    <p>
      A combined deck consisting of 8 standard decks of 52 cards each is used
      for the game. The dealer places a cutting card in the middle of this
      combined deck. After, the entire deck is placed in the card shoe. Rounds
      are played one after another with one and the same deck without shuffling,
      until the dealer draws a cutting card from the shoe. The appearance of
      this cutting card means that the combined deck will be replaced with a new
      one at the end of this particular draw.
    </p>
    <h1>Connection Issues</h1>
    <p>
      In case of disconnection from the game due to connection problems on the
      user side, the bets placed are considered to be valid and are drawn
      without any participation of the player. For such cases, the game has an
      automatic drawing system, which is activated if the player does not
      perform any actions at the phase of decision-making for the hands. The
      automatic decision-making system uses the following algorithm:
    </p>
    <ol style={{ marginBottom: "5rem" }}>
      <li>
        If the total score for the hand is less than or equal to 11, the system
        decides to hit or add one more card.
      </li>
      <li>
        If the total score for the hand exceeds 11, the system decides to stand.
      </li>
    </ol>
    <p>
      The results of the round can be viewed in the "History" section after
      reconnection.
    </p>
    <h1>Inactivity of the Player</h1>
    <p>
      If the player takes a seat, but does not place the main bets during more
      than three consecutive rounds, the player loses their seat at the table.
      If there are any empty seats at the table after the player loses their
      seat, the player has the opportunity to reoccupy them and continue the
      game. However, it is necessary to keep in mind that after three forced
      disconnections from the table the player may be subjected to a restriction
      and loose the opportunity to play at this table for some time (the
      duration of this restriction is set at the discretion of the game
      provider).
    </p>
    <h1>Game Issues</h1>
    <p>
      If there is a malfunction in the game, the round is temporarily suspended.
      The dealer notifies the players and technicians of this. If the support
      team can immediately correct the error, then the draw continues as usual.
      If it is impossible to eliminate the error, all bets for the round are
      canceled and returned to the players.
    </p>
    <h1>Good luck!</h1>
  </div>
);

class GamePageBJ extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    loadResources([
      ["image", logo],
      ["image", gameBanner],
      ["image", gameBannerMobile],
      ["font", gilroyBold, { name: "Gilroy", style: "normal", weight: 900 }],
      [
        "font",
        PTRootUIMedium,
        { name: "PT Root UI", style: "normal", weight: 500 },
      ],
    ]).then(() => {
      this.props.dispatch({
        type: "set-resources-loaded",
        payload: { loaded: true },
      });
    });
  }

  render() {
    return (
      <>
        <LoaderLogo isLoaded={this.props.loadedResources.loaded} />
        <GamePage
          banner={gameBanner}
          bannerMobile={gameBannerMobile}
          theme="game_bj"
          name="BLACK JACK"
          text="We are welcoming you to our new luxury studio with a classic Blackjack game and responsive interface design. Attractive game presenters and multi-seats are waiting for you!"
          desktopIcon={desktopIcon}
          tabletIcon={tabletIcon}
          mobileIcon={mobileIcon}
          deviceDesktop={deviceDesktop}
          deviceTablet={deviceTablet}
          deviceMobile={deviceMobile}
          slidesDesktop={[slideDesktop1, slideDesktop2, slideDesktop3]}
          slidesTablet={[slideTablet1, slideTablet2, slideTablet3]}
          slidesMobile={[slideMobile1, slideMobile2, slideMobile3]}
          features={[
            {
              icon: feature1,
              text: "Multi seat",
            },
            {
              icon: feature2,
              text: "Classic side bets",
            },
            {
              icon: feature3,
              text: "Luxury studio",
            },
          ]}
          rules={rules}
          partners={partners}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.language,
  loadedResources: state.loadedResources,
});

export default connect(mapStateToProps)(GamePageBJ);
